// @flow
import packageJson from '../../../package.json';
import config from '../../config.json';
let siteUrl = process.env.SITE_URL || 'localhost';

siteUrl = 'http://' + siteUrl + ':3000/graphql';

let guide_url =  process.env.S3_CDN ? process.env.S3_CDN : config.S3_CDN

if (!process.env.PROXY_ENABLE || process.env.PROXY_ENABLE == 'false') {
  siteUrl = process.env.API_URL ? process.env.API_URL : '/api';
}
export const appConfig = {
  appVersion: process.env.VERSION,
  apollo: {
    networkInterface: siteUrl,
  },
  application: `Web App`,
  API_URL: process.env.API_URL? process.env.API_URL : config.API_URL,
  CDN_URL: process.env.S3_CDN ? process.env.S3_CDN : config.S3_CDN,
  GEOCODING_KEY: process.env.GEOCODING_KEY ? process.env.GEOCODING_KEY : config.GEOCODING_KEY,
  mixPanel: {
    base: 'https://api.mixpanel.com',
  },
  MIXPANEL_API_URL: 'https://api.mixpanel.com',
  MIXPANEL_API_SECRET: process.env.MIXPANEL_API_SECRET ? process.env.MIXPANEL_API_SECRET : config.MIXPANEL_API_SECRET ,
  MIXPANEL_API_TOKEN: process.env.MIXPANEL_API_TOKEN ? process.env.MIXPANEL_API_TOKEN : config.MIXPANEL_API_TOKEN ,
  version: packageJson.version,
  WALKTHROUGH_URL: 'https://cdn-dev.fliptrx.com/walkthrough/default',
  USER_GUIDE: `${guide_url}/user_guide`,
  HUMANA_JS_URL: process.env.HUMANA_JS_URL ? process.env.HUMANA_JS_URL : config.HUMANA_JS_URL
};

// MixPanel
appConfig.mixPanel.track = `${appConfig.mixPanel.base}/track`;
appConfig.mixPanel.engage = `${appConfig.mixPanel.base}/engage`;