import fetchRequest from './fetchRequest';
import * as config from '../config';

const GEOCODING_KEY = config.appConfig.GEOCODING_KEY;
const BASEURL = 'https://maps.googleapis.com/maps/api/';

const MATRIX_API = 'distancematrix/json';
const GEOCODING_API = 'geocode/json';

const DISTANCE_MATRIX_URL =
    BASEURL +
    MATRIX_API +
    '?units=imperial&origins=%origins%&destinations=%destinations%&key=' +
    GEOCODING_KEY;

export function fetchDistanceMatrix({ origins, destinations }) {
    const finalUrl = DISTANCE_MATRIX_URL.replace('%origins%', origins.join('|')).replace(
        '%destinations%',
        destinations.join('|')
    );
    return fetchRequest(finalUrl);
}

const GEOCODING_URL = BASEURL + GEOCODING_API + '?address=%address%&key=' + GEOCODING_KEY;

export function fetchPostalCode({ latitude, longitude, address }) {
    const location = address ? address : latitude + ',' + longitude;
    const finalUrl = GEOCODING_URL.replace('%address%', encodeURIComponent(location));

    return fetchRequest(finalUrl);
}

export function getGeoCodeInformation(geoCodeData) {
    const zipCodeJson = JSON.parse(geoCodeData);
    const addressComponents = zipCodeJson.results[0].address_components;
    const geometry = zipCodeJson.results[0].geometry;
    let postalCode = '';
    let locality = '';
    const formatted_address = zipCodeJson.results[0].formatted_address;

    addressComponents.forEach((element) => {
        if (element.types[0] === 'postal_code') {
            postalCode = element.short_name;
        }
        if (element.types[0] === 'locality' || element.type === 'political') {
            locality = element.short_name;
        }
    });

    return {
        locality,
        postalCode,
        formatted_address,
        coords: {
            latitude: geometry.location.lat,
            longitude: geometry.location.lng,
        },
    };
}
