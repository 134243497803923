import gql from 'graphql-tag';
import prescription from '../templates/prescription';

export default gql`mutation (
        $admin_flipt_person_id: String
        $alternative_drug_rewards: String
        $bin: String
        $brand_generic: String
        $coords: [Float]
        $custom_qty: String
        $custom_quantity: Boolean
        $days_of_supply: String
        $daysofsupply: String
        $ddid: String
        $ddn_form: String
        $ddn_name: String
        $ddn_strength: String
        $dosage: String
        $dosage_image: String
        $dosage_strength: String
        $dpa: String
        $drug: String
        $drug_name: String
        $drug_type: String
        $equivalent_brand: String
        $flipt_person_id: String
        $form: String
        $gpi: String
        $gppc: String
        $group_id: String
        $isAlt: Boolean
        $lm_form: String
        $lm_name: String
        $lm_ndc: String
        $lm_strength: String
        $location: String
        $locationSelected: String
        $member_id: String
        $package_desc: String
        $package_qty: String
        $package_quantity: String
        $package_size: String
        $pcn: String
        $prescriber_npi: String
		    $prescriber_first_name: String
	    	$prescriber_last_name: String
		    $prescriber_phone: String
        $pkg_desc_cd: String
        $pkg_uom: String
        $prescription_id: String!
        $quantity: String
        $quantity_type: String
        $reward_share: String
        $rx_flipt_person_id: String
        $rxcui: String
        $search_location: String
        $searchedDrugValue: Float
        $searched_days_of_supply: String
        $searched_ddid: String
        $searched_dosage: String
        $searched_dosage_strength: String
        $searched_drug_cost: String
        $searched_drug_name: String
        $searched_employee_opc: String
        $searched_employer_cost: String
        $searched_gpi: String
        $searched_gppc: String
        $searched_package_quantity: String
        $searched_package_qty: String
        $searched_package_size: String
        $shared_with_flipt_person_id: String
        $specialty_flag: String
        $strengths: String
        $zipCode: String
    ) {
      updatePrescription(
        admin_flipt_person_id: $admin_flipt_person_id
        alternative_drug_rewards: $alternative_drug_rewards
        bin: $bin
        brand_generic: $brand_generic
        coords: $coords
        custom_qty: $custom_qty
        custom_quantity: $custom_quantity
        days_of_supply: $days_of_supply
        daysofsupply: $daysofsupply
        ddid: $ddid
        ddn_form: $ddn_form
        ddn_name: $ddn_name
        ddn_strength: $ddn_strength
        dosage: $dosage
        dosage_image: $dosage_image
        dosage_strength: $dosage_strength
        dpa: $dpa
        drug: $drug,
        drug_name: $drug_name
        drug_type: $drug_type
        equivalent_brand: $equivalent_brand
        flipt_person_id: $flipt_person_id
        form: $form,
        gpi: $gpi,
        gppc: $gppc
        group_id: $group_id
        isAlt: $isAlt
        lm_form: $lm_form
        lm_name: $lm_name
        lm_ndc: $lm_ndc,
        lm_strength: $lm_strength
        location: $location
        locationSelected: $locationSelected
        member_id: $member_id
        package_desc: $package_desc
        package_qty: $package_qty
        package_quantity: $package_quantity
        package_size: $package_size
        pcn: $pcn
        prescriber_npi: $prescriber_npi
		    prescriber_first_name: $prescriber_first_name
	    	prescriber_last_name: $prescriber_last_name
		    prescriber_phone: $prescriber_phone
        pkg_desc_cd: $pkg_desc_cd
        pkg_uom: $pkg_uom
        prescription_id: $prescription_id
        quantity: $quantity
        quantity_type: $quantity_type
        reward_share: $reward_share
        rx_flipt_person_id: $rx_flipt_person_id
        rxcui: $rxcui
        search_location: $search_location
        searchedDrugValue: $searchedDrugValue
        searched_days_of_supply: $searched_days_of_supply
        searched_ddid: $searched_ddid
        searched_dosage: $searched_dosage
        searched_dosage_strength: $searched_dosage_strength
        searched_drug_cost: $searched_drug_cost
        searched_drug_name: $searched_drug_name
        searched_employee_opc: $searched_employee_opc
        searched_employer_cost: $searched_employer_cost
        searched_gpi: $searched_gpi
        searched_gppc: $searched_gppc
        searched_package_quantity: $searched_package_quantity
        searched_package_qty: $searched_package_qty
        searched_package_size: $searched_package_size
        shared_with_flipt_person_id: $shared_with_flipt_person_id
        specialty_flag: $specialty_flag
        strengths: $strengths
        zipCode: $zipCode
      ) {
        ${prescription}
      }
    }
`;
