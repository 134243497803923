export const SET_DIGITAL_ID_MAIL_ORDER_PHARMACY_DETAILS = 'SET_DIGITAL_ID_MAIL_ORDER_PHARMACY_DETAILS';

export const emptyState = {
	digitalIdMailOrderPharmacies: [],
};

export const initialState = {
	...emptyState,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_DIGITAL_ID_MAIL_ORDER_PHARMACY_DETAILS:
			return {
				...state,
				digitalIdMailOrderPharmacies: action.digitalIdMailOrderPharmacies,
			};
		default:
			return state;
	}
}
export function setDigitalIdMailOrderPharmacies(digitalIdMailOrderPharmacies) {
	return {
		type: SET_DIGITAL_ID_MAIL_ORDER_PHARMACY_DETAILS,
		digitalIdMailOrderPharmacies,
	};
}