// #region imports
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import compose from 'recompose/compose';
import * as viewsActions from '../../redux/modules/views';
import * as userAuthActions from '../../redux/modules/userAuth';
import ActivateDependent from './ActivateDependent';
import withEnterAnimation from '../../hoc/withEnterAnimation';
import { setUserLogout } from '../../redux/modules/userAuth';
import activateDependentMutationAPI from '../../gql/mutations/activateDependent.gql';
// #endregion

// #region Redux
const mapStateToProps = (state) => {
    return {
        // views props:
        currentView: state.views.currentView,
        // user Auth props:
        userIsAuthenticated: state.userAuth.isAuthenticated,
        mutationLoading: state.userAuth.mutationLoading,
        // errors:
        error: state.userAuth.error,
        userInfo: state.userAuth.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setUserLogout,
            // views actions:
            ...viewsActions,

            // userAuth actions:
            ...userAuthActions,
        },
        dispatch
    );
};
// #endregion

const activateDependentMutationOptions = {
    props: ({ ownProps, mutate }) => ({
        async activateDependentMutation(variables) {
            ownProps.setLoadingStateForUserLogin();
            try {
                const {
                    data: { activateDependent },
                } = await mutate(variables);
                ownProps.unsetLoadingStateForUserLogin();
                return Promise.resolve(activateDependent);
            } catch (error) {
                ownProps.unsetLoadingStateForUserLogin();
                return Promise.reject(error);
            }
        },
    }),
};
// #endregion

export default compose(
    withEnterAnimation(),
    connect(mapStateToProps, mapDispatchToProps),
    graphql(activateDependentMutationAPI, activateDependentMutationOptions)
)(ActivateDependent);
