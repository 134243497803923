$(document).ready(function () {
	//navigation div Scroll	
	$(".left-bar .left-inner-bar,.cart-popup-listing").niceScroll();

	$('.tab-content .title').on('click',function () {
		$(this).siblings('.active').removeClass('active').end()
			.toggleClass('active').next('.panel').slideToggle('100')
			.siblings('.panel:visible')
			.slideUp('normal');
		return false;
	});

	if ($(window).width() > 767) {
		$(".modal-data.ui.modal.instruction-modal.saved-prescription .content").niceScroll();
	}
	$('.ui.button').click(function () {
		$('.ui.modal')
			.modal('show')
			;
	});
	$('.ui.modal .close').click(function () {
		$('.ui.modal')
			.modal('hide')
			;
	});

	/* menu */
	$('.top.menu .item').tab();

	/* dropdown-menu */
	$('.ui.dropdown').dropdown('show');

	//eqHeight
	function eqHeight() {
		$(".drug-details-block").css("height", "auto");
		var HeightArray = 0; var i = 0; var max = 0;
		$(".drug-details-block").each(function () {
			HeightArray = $(this).height();
			max = Math.max(max, HeightArray);
		});
		$(".drug-details-block").each(function () {
			$(this).height(max);
		});
	};

	$(window).on('load', function () {
		eqHeight();
	});


});
