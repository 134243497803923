// @flow

// #region imports
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
// #region import createHistory from hashHistory or BrowserHistory:
import { createBrowserHistory } from 'history';
// import createHistory            from 'history/createBrowserHistory';
// #endregion
import reducer from '../modules/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
// #endregion

import Analytics from '../../utilities/analytics';

const analytics = new Analytics();

const analyticsMiddleware = () => (next) => (action) => {
    if (action.type === 'ANALYTICS/IDENTIFY') {
        analytics.identify(action.distinctId);
    }
    if (action.type === 'ANALYTICS/TRACK') {
        analytics.track(action.event, action.properties);
    }
    next(action);
};

export const history = createBrowserHistory();

// createStore : enhancer
const enhancer = composeWithDevTools(
    applyMiddleware(thunkMiddleware, routerMiddleware(history), analyticsMiddleware)
);

// export default =  "redux store"
export default function configureStore(initialState) {
    return createStore(reducer(history), initialState, enhancer);
}
