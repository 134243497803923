import moment from 'moment';
const dateFormat = 'DD/MM/YYYY HH:mm';

const ENTER_HOME_VIEW = 'ENTER_HOME_VIEW';
const LEAVE_HOME_VIEW = 'LEAVE_HOME_VIEW';
const ENTER_LOGIN_VIEW = 'ENTER_LOGIN_VIEW';
const LEAVE_LOGIN_VIEW = 'LEAVE_LOGIN_VIEW';
const ENTER_FORGOT_VIEW = 'ENTER_FORGOT_VIEW';
const LEAVE_FORGOT_VIEW = 'LEAVE_FORGOT_VIEW';
const ENTER_RESET_PASSWORD_VIEW = 'ENTER_RESET_PASSWORD_VIEW';
const LEAVE_RESET_PASSWORD_VIEW = 'LEAVE_RESET_PASSWORD_VIEW';
const ENTER_REGISTER_VIEW = 'ENTER_REGISTER_VIEW';
const LEAVE_REGISTER_VIEW = 'LEAVE_REGISTER_VIEW';
const ENTER_ACTIVATE_DEPENDENT_VIEW = 'ENTER_ACTIVATE_DEPENDENT_VIEW';
const LEAVE_ACTIVATE_DEPENDENT_VIEW = 'LEAVE_ACTIVATE_DEPENDENT_VIEW';
const ENTER_SIGN_UP_VIEW = 'ENTER_SIGN_UP_VIEW';
const LEAVE_SIGN_UP_VIEW = 'LEAVE_SIGN_UP_VIEW';
const ENTER_TERMS_AND_CONDITIONS_VIEW = 'ENTER_TERMS_AND_CONDITIONS_VIEW';
const LEAVE_TERMS_AND_CONDITIONS_VIEW = 'LEAVE_TERMS_AND_CONDITIONS_VIEW';
const ENTER_CREATE_PASSWORD_VIEW = 'ENTER_CREATE_PASSWORD_VIEW';
const LEAVE_CREATE_PASSWORD_VIEW = 'LEAVE_CREATE_PASSWORD_VIEW';
const ENTER_UPDATE_PERSONAL_INFO_VIEW = 'ENTER_UPDATE_PERSONAL_INFO_VIEW';
const LEAVE_UPDATE_PERSONAL_INFO_VIEW = 'LEAVE_UPDATE_PERSONAL_INFO_VIEW';
const ENTER_USER_HOME_VIEW = 'ENTER_USER_HOME_VIEW';
const LEAVE_USER_HOME_VIEW = 'LEAVE_USER_HOME_VIEW';
const ENTER_USER_DEFAULT_HOME_VIEW = 'ENTER_USER_DEFAULT_HOME_VIEW';
const LEAVE_USER_DEFAULT_HOME_VIEW = 'LEAVE_USER_DEFAULT_HOME_VIEW';
const ENTER_USER_ACCOUNT_VIEW = 'ENTER_USER_ACCOUNT_VIEW';
const LEAVE_USER_ACCOUNT_VIEW = 'LEAVE_USER_ACCOUNT_VIEW';
const ENTER_ADMIN_ACCOUNT_VIEW = 'ENTER_ADMIN_ACCOUNT_VIEW';
const LEAVE_ADMIN_ACCOUNT_VIEW = 'LEAVE_ADMIN_ACCOUNT_VIEW';
const ENTER_USER_SETTINGS_VIEW = 'ENTER_USER_SETTINGS_VIEW';
const LEAVE_USER_SETTINGS_VIEW = 'LEAVE_USER_SETTINGS_VIEW';
const ENTER_ADD_LOCATION_VIEW = 'ENTER_ADD_LOCATION_VIEW';
const LEAVE_ADD_LOCATION_VIEW = 'LEAVE_ADD_LOCATION_VIEW';
const ENTER_USER_PRIVACY_POLICY_VIEW = 'ENTER_USER_PRIVACY_POLICY_VIEW';
const LEAVE_USER_PRIVACY_POLICY_VIEW = 'LEAVE_USER_PRIVACY_POLICY_VIEW';
const ENTER_USER_CONTACT_VIEW = 'ENTER_USER_CONTACT_VIEW';
const LEAVE_USER_CONTACT_VIEW = 'LEAVE_USER_CONTACT_VIEW';

const ENTER_INSURANCE_CARDS_VIEW = 'ENTER_INSURANCE_CARDS_VIEW';
const LEAVE_INSURANCE_CARDS_VIEW = 'LEAVE_INSURANCE_CARDS_VIEW';
const ENTER_RX_CARDS_VIEW = 'ENTER_RX_CARDS_VIEW';
const LEAVE_RX_CARDS_VIEW = 'LEAVE_RX_CARDS_VIEW';
const ENTER_PRESCRIPTIONS_VIEW = 'ENTER_PRESCRIPTIONS_VIEW';
const LEAVE_PRESCRIPTIONS_VIEW = 'LEAVE_PRESCRIPTIONS_VIEW';
const ENTER_REWARDS_VIEW = 'ENTER_REWARDS_VIEW';
const LEAVE_REWARDS_VIEW = 'LEAVE_REWARDS_VIEW';
const ENTER_HELP_VIEW = 'ENTER_HELP_VIEW';
const LEAVE_HELP_VIEW = 'LEAVE_HELP_VIEW';

const ENTER_PROTECTED_VIEW = 'ENTER_PROTECTED_VIEW';
const LEAVE_PROTECTED_VIEW = 'LEAVE_PROTECTED_VIEW';

const initialState = {
    currentView: 'not set',
    enterTime: null,
    leaveTime: null,
};

export default function (state = initialState, action) {
    const currentTime = moment().format(dateFormat);

    switch (action.type) {
        // non protected views:
        case ENTER_HOME_VIEW:
        case ENTER_LOGIN_VIEW:
        case ENTER_FORGOT_VIEW:
        case ENTER_RESET_PASSWORD_VIEW:
        case ENTER_ACTIVATE_DEPENDENT_VIEW:
        case ENTER_SIGN_UP_VIEW:
        case ENTER_TERMS_AND_CONDITIONS_VIEW:
        case ENTER_CREATE_PASSWORD_VIEW:
        case ENTER_UPDATE_PERSONAL_INFO_VIEW:
        case ENTER_USER_HOME_VIEW:
        case ENTER_USER_DEFAULT_HOME_VIEW:
        case ENTER_USER_ACCOUNT_VIEW:
        case ENTER_ADMIN_ACCOUNT_VIEW:
        case ENTER_USER_SETTINGS_VIEW:
        case ENTER_ADD_LOCATION_VIEW:
        case ENTER_USER_PRIVACY_POLICY_VIEW:
        case ENTER_USER_CONTACT_VIEW:
        case ENTER_INSURANCE_CARDS_VIEW:
        case ENTER_RX_CARDS_VIEW:
        case ENTER_PRESCRIPTIONS_VIEW:
        case ENTER_REWARDS_VIEW:
        case ENTER_HELP_VIEW:
        case ENTER_REGISTER_VIEW:
            // can't enter if you are already inside
            if (state.currentView !== action.currentView) {
                return {
                    ...state,
                    currentView: action.currentView,
                    enterTime: currentTime,
                };
            }
            return state;
        case LEAVE_HOME_VIEW:
        case LEAVE_LOGIN_VIEW:
        case LEAVE_FORGOT_VIEW:
        case LEAVE_RESET_PASSWORD_VIEW:
        case LEAVE_ACTIVATE_DEPENDENT_VIEW:
        case LEAVE_SIGN_UP_VIEW:
        case LEAVE_TERMS_AND_CONDITIONS_VIEW:
        case LEAVE_CREATE_PASSWORD_VIEW:
        case LEAVE_UPDATE_PERSONAL_INFO_VIEW:
        case LEAVE_USER_HOME_VIEW:
        case LEAVE_USER_DEFAULT_HOME_VIEW:
        case LEAVE_USER_ACCOUNT_VIEW:
        case LEAVE_ADMIN_ACCOUNT_VIEW:
        case LEAVE_USER_SETTINGS_VIEW:
        case LEAVE_ADD_LOCATION_VIEW:
        case LEAVE_USER_PRIVACY_POLICY_VIEW:
        case LEAVE_USER_CONTACT_VIEW:
        case LEAVE_INSURANCE_CARDS_VIEW:
        case LEAVE_RX_CARDS_VIEW:
        case LEAVE_PRESCRIPTIONS_VIEW:
        case LEAVE_REWARDS_VIEW:
        case LEAVE_HELP_VIEW:
        case LEAVE_REGISTER_VIEW:
            // can't leave if you aren't already inside
            if (state.currentView === action.currentView) {
                return {
                    ...state,
                    currentView: action.currentView,
                    leaveTime: currentTime,
                };
            }
            return state;
        // protected views:
        case ENTER_PROTECTED_VIEW:
            if (state.currentView !== action.currentView) {
                return {
                    ...state,
                    currentView: action.currentView,
                    enterTime: currentTime,
                };
            }
            return state;
        case LEAVE_PROTECTED_VIEW:
            if (state.currentView === action.currentView) {
                return {
                    ...state,
                    currentView: action.currentView,
                    leaveTime: currentTime,
                };
            }
            return state;

        default:
            return state;
    }
}

// Home
export function enterHome() {
    return {
        type: ENTER_HOME_VIEW,
        currentView: 'home',
    };
}
export function leaveHome() {
    return {
        type: LEAVE_HOME_VIEW,
        currentView: 'home',
    };
}

// Login
export function enterLogin() {
    return {
        type: ENTER_LOGIN_VIEW,
        currentView: 'login',
    };
}
export function leaveLogin() {
    return {
        type: LEAVE_LOGIN_VIEW,
        currentView: 'login',
    };
}

// Forgot Password
export function enterForgotPassword() {
    return {
        type: ENTER_FORGOT_VIEW,
        currentView: 'forgotPassword',
    };
}
export function leaveForgotPassword() {
    return {
        type: LEAVE_FORGOT_VIEW,
        currentView: 'forgotPassword',
    };
}

// Reset Password
export function enterResetPassword() {
    return {
        type: ENTER_RESET_PASSWORD_VIEW,
        currentView: 'resetPassword',
    };
}
export function leaveResetPassword() {
    return {
        type: LEAVE_RESET_PASSWORD_VIEW,
        currentView: 'resetPassword',
    };
}

// Register
export function enterRegister() {
    return {
        type: ENTER_REGISTER_VIEW,
        currentView: 'register',
    };
}
export function leaveRegister() {
    return {
        type: LEAVE_REGISTER_VIEW,
        currentView: 'register',
    };
}

// Activate Dependent
export function enterActivateDependent() {
    return {
        type: ENTER_ACTIVATE_DEPENDENT_VIEW,
        currentView: 'activateDependent',
    };
}
export function leaveActivateDependent() {
    return {
        type: LEAVE_ACTIVATE_DEPENDENT_VIEW,
        currentView: 'activateDependent',
    };
}

// Sign Up
export function enterSignUp() {
    return {
        type: ENTER_SIGN_UP_VIEW,
        currentView: 'signUp',
    };
}
export function leaveSignUp() {
    return {
        type: LEAVE_SIGN_UP_VIEW,
        currentView: 'signUp',
    };
}

// Terms And Conditions
export function enterTermsAndConditions() {
    return {
        type: ENTER_TERMS_AND_CONDITIONS_VIEW,
        currentView: 'termsAndConditions',
    };
}

export function leaveTermsAndConditions() {
    return {
        type: LEAVE_TERMS_AND_CONDITIONS_VIEW,
        currentView: 'termsAndConditions',
    };
}

// Create Password
export function enterCreatePassword() {
    return {
        type: ENTER_CREATE_PASSWORD_VIEW,
        currentView: 'createPassword',
    };
}
export function leaveCreatePassword() {
    return {
        type: LEAVE_CREATE_PASSWORD_VIEW,
        currentView: 'createPassword',
    };
}

// Update Personal Info
export function enterUpdatePersonalInfo() {
    return {
        type: ENTER_UPDATE_PERSONAL_INFO_VIEW,
        currentView: 'updatePersonalInfo',
    };
}
export function leaveUpdatePersonalInfo() {
    return {
        type: LEAVE_UPDATE_PERSONAL_INFO_VIEW,
        currentView: 'updatePersonalInfo',
    };
}

// User Home
export function enterUserHome() {
    return {
        type: ENTER_USER_HOME_VIEW,
        currentView: 'userHome',
    };
}
export function leaveUserHome() {
    return {
        type: LEAVE_USER_HOME_VIEW,
        currentView: 'userHome',
    };
}

// User Default Home
export function enterUserDefaultHome() {
    return {
        type: ENTER_USER_DEFAULT_HOME_VIEW,
        currentView: 'userDefaultHome',
    };
}
export function leaveUserDefaultHome() {
    return {
        type: LEAVE_USER_DEFAULT_HOME_VIEW,
        currentView: 'userDefaultHome',
    };
}

// Account
export function enterUserAccount() {
    return {
        type: ENTER_USER_ACCOUNT_VIEW,
        currentView: 'userAccount',
    };
}
export function leaveUserAccount() {
    return {
        type: LEAVE_USER_ACCOUNT_VIEW,
        currentView: 'userAccount',
    };
}

// Admin
export function enterAdminAccount() {
    return {
        type: ENTER_ADMIN_ACCOUNT_VIEW,
        currentView: 'adminAccount',
    };
}
export function leaveAdminAccount() {
    return {
        type: LEAVE_ADMIN_ACCOUNT_VIEW,
        currentView: 'adminAccount',
    };
}

// Settings
export function enterUserSettings() {
    return {
        type: ENTER_USER_SETTINGS_VIEW,
        currentView: 'userSettings',
    };
}
export function leaveUserSettings() {
    return {
        type: LEAVE_USER_SETTINGS_VIEW,
        currentView: 'userSettings',
    };
}

// Settings -> Add Location
export function enterAddLocation() {
    return {
        type: ENTER_ADD_LOCATION_VIEW,
        currentView: 'userAddLocation',
    };
}
export function leaveAddLocation() {
    return {
        type: LEAVE_ADD_LOCATION_VIEW,
        currentView: 'userAddLocation',
    };
}

// Privacy Policy
export function enterUserPrivacyPolicy() {
    return {
        type: ENTER_USER_PRIVACY_POLICY_VIEW,
        currentView: 'userPrivacyPolicy',
    };
}
export function leaveUserPrivacyPolicy() {
    return {
        type: LEAVE_USER_PRIVACY_POLICY_VIEW,
        currentView: 'userPrivacyPolicy',
    };
}

// Contact
export function enterUserContact() {
    return {
        type: ENTER_USER_CONTACT_VIEW,
        currentView: 'userContact',
    };
}
export function leaveUserContact() {
    return {
        type: LEAVE_USER_CONTACT_VIEW,
        currentView: 'userContact',
    };
}

// Insurance Card
export function enterInsuranceCard() {
    return {
        type: ENTER_INSURANCE_CARDS_VIEW,
        currentView: 'insuranceCard',
    };
}

export function leaveInsuranceCard() {
    return {
        type: LEAVE_INSURANCE_CARDS_VIEW,
        currentView: 'insuranceCard',
    };
}

// Rx Card
export function enterRxCard() {
    return {
        type: ENTER_RX_CARDS_VIEW,
        currentView: 'rxCard',
    };
}
export function leaveRxCard() {
    return {
        type: LEAVE_RX_CARDS_VIEW,
        currentView: 'rxCard',
    };
}

// Prescription
export function enterPrescription() {
    return {
        type: ENTER_PRESCRIPTIONS_VIEW,
        currentView: 'prescription',
    };
}
export function leavePrescription() {
    return {
        type: LEAVE_PRESCRIPTIONS_VIEW,
        currentView: 'prescription',
    };
}

// Rewards
export function enterRewards() {
    return {
        type: ENTER_REWARDS_VIEW,
        currentView: 'rewards',
    };
}
export function leaveRewards() {
    return {
        type: LEAVE_REWARDS_VIEW,
        currentView: 'rewards',
    };
}

// Help
export function enterHelp() {
    return {
        type: ENTER_HELP_VIEW,
        currentView: 'help',
    };
}
export function leaveHelp() {
    return {
        type: LEAVE_HELP_VIEW,
        currentView: 'help',
    };
}

// Protected
export function enterProtected() {
    return {
        type: ENTER_PROTECTED_VIEW,
        currentView: 'protected',
    };
}
export function leaveProtected() {
    return {
        type: LEAVE_PROTECTED_VIEW,
        currentView: 'protected',
    };
}
// #endregion
