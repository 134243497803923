import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../../../style/colors';


const FliptButtonText = styled.p`
  flex: 1
text-align: center;
  align-items: center;

  flex-wrap: wrap;
  text-wrap: wrap;

  color:  ${(props) => {
    if (props.blue) {
      return colors.white;
    } else if (props.link) {
      return colors.fliptBlue;
    }
    return props.color;
  }};
  
  // border-radius: 50px;
`;
const FliptFeedbackText = ({ color, blue, link, children }) => {
  const { t } = useTranslation();
  return (
    <FliptButtonText color={color} blue={blue} link={link}>
      {children && typeof children === 'string' && t(children)}
    </FliptButtonText>
  );
};

export default FliptFeedbackText;
