import client from '../../../services/apollo';
import saveUserFeedback from '../../../gql/mutations/saveUserFeedback.gql';
import * as commonFunctions from '../../../utilities/commonFunctions';
import {
  CLAIMS_PROCESSOR,
  FILLED,
  MOBILE_APP,
  NUMBER_OF_FILLED_BEFORE_FEEDBACK,
  NUMBER_OF_PENDING_BEFORE_FEEDBACK,
  WEB_APP,
} from '../../../constants/common';
import { appConfig } from '../../../config';

export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';
export const TOGGLE_USER_FEEDBACK = 'TOGGLE_USER_FEEDBACK';
export const emptyState = {
  showErrorModal: false,
  userFeedback: {
    display: false,
    action: 'Get Help',
  },
};

export const initialState = {
  ...emptyState,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: true,
      };

    case HIDE_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: false,
      };
    case TOGGLE_USER_FEEDBACK:
      return {
        ...state,
        userFeedback: {
          display: !state.userFeedback.display,
          action: action.payload || state.userFeedback.action,
        },
      };
    default:
      return state;
  }
}

export function showErrorModal() {
  return {
    type: SHOW_ERROR_MODAL,
  };
}

export function hideErrorModal() {
  return {
    type: HIDE_ERROR_MODAL,
  };
}

export function toggleUserFeedback() {
  return {
    type: TOGGLE_USER_FEEDBACK,
  };
}

export function submitUserFeedback(info) {
  return async (dispatch, getState) => {
    commonFunctions.showPageLoader();
    return new Promise((resolve, reject) => {
      const state = getState();
      const flipt_person_id = state?.userAuth?.userInfo?.employee?.flipt_person_id;
      client
        .mutate({
          mutation: saveUserFeedback,
          variables: {
            ...info,
            flipt_person_id,
            app_version: appConfig.appVersion,
            platform: appConfig.application,
          },
        })
        .then((obj) => {})
        .finally(() => {
          dispatch(toggleUserFeedback());
          commonFunctions.hidePageLoader();
        });
    });
  };
}
export function checkIntentCount() {
  // memoize this
  return async (dispatch, getState) => {
    const { prescriptions } = getState();
    const { filledPrescriptions, pendingPrescriptions } = prescriptions;
    const isFilledCountHighEnough = filledPrescriptions.length >= NUMBER_OF_FILLED_BEFORE_FEEDBACK;
    if (isFilledCountHighEnough) {
      const filteredList = filledPrescriptions?.filter((rx) => {
        return (
          ([WEB_APP, MOBILE_APP].includes(rx.application) && rx.rx_status === FILLED) ||
          (rx.application === CLAIMS_PROCESSOR &&
            rx.rx_status === FILLED &&
            !_.isEmpty(rx.rx_status_change_reason))
        );
      });
      if (
        filteredList.length === NUMBER_OF_FILLED_BEFORE_FEEDBACK &&
        pendingPrescriptions.length === NUMBER_OF_PENDING_BEFORE_FEEDBACK
      ) {
        dispatch(toggleUserFeedback());
      }
    }
  };
}
