import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import colors from '../../../../style/colors';
import { FeedbackStateEnum } from './Feedback.state';
import RoundedHeader from './RoundedHeader';

import { Form, ModalContent } from 'semantic-ui-react';

const FliptButtonText = styled.p`
  flex: 1
  align-items: center;
  flex-wrap: wrap;
  text-wrap: wrap;
  font-weight: ${(props) => {
    return props.bold ? 'bold' : 'normal';
  }};
`;
const FliptFeedbackText = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  return (
    <FliptButtonText {...props}>
      {children && typeof children === 'string' && t(children)}
    </FliptButtonText>
  );
};
const FeedbackBody = styled(ModalContent)`
  display: flex !important;
  background-color: ${(props) => props?.theme?.bodyBackgroundColor || colors.white};
`;
const FeedbackBodyText = styled(FliptFeedbackText)`
  flex-wrap: wrap;
  color: ${(props) => props?.theme?.baseTextColor || colors.black};
`;

const FliptFeedbackStep3 = ({ submitUserFeedback, nextStep, close }) => {
  const [reviewText, setReviewText] = useState('');
  const [requestAssistance, setRequestAssistance] = useState(false);
  const { t } = useTranslation();
  const _submit = () => {
    nextStep(FeedbackStateEnum.FINISHED);

    submitUserFeedback({
      requestAssistance: requestAssistance,
      action: 'no',
      rating: '0',
      comment: reviewText,
      writeAReview: false,
    });
  };
  return (
    <>
      <RoundedHeader onPress={close} headerText="Tell us about your experience" />
      <FeedbackBody>
        <Form onSubmit={_submit}>
          <FeedbackBodyText>What do you think the Flipt app should improve on?</FeedbackBodyText>
          <Form.Input
            type="text"
            onChange={(e) => {
              setReviewText(e.target.value);
            }}
          />
          <Form.Checkbox
            style={{ color: colors.fliptBlue }}
            checked={requestAssistance}
            onClick={() => {
              setRequestAssistance(!requestAssistance);
            }}
            label={t(
              'Check this box if you need assistance with the app or your prescription benefits and would like a call from the Concierge. We will contact you within 24 hours.'
            )}
          />

          <Form.Button primary>{t('Submit')}</Form.Button>
        </Form>
      </FeedbackBody>
    </>
  );
};

export default FliptFeedbackStep3;
