import client from '../../services/apollo';
import updateBasketMutation from '../../gql/mutations/updateBasket.gql';
import deletePrescriptionMutation from '../../gql/mutations/deletePrescription.gql';
import getNotificationsQuery from '../../gql/queries/getNotifications.gql';
import { updateUserData, UPDATE_USER_DATA } from './userAuth';

import { getActiveBasket } from '../../components/defaultHome/util';

export const USER_BASKET = 'user/USER_BASKET';
export const SET_USER_EMPTY_STATE = 'user/SET_USER_EMPTY_STATE';
export const SET_IS_IMPERSONATE_USER = 'user/SET_IS_IMPERSONATE_USER';
export const SET_CURRENT_ADMIN_TOKEN = 'user/SET_CURRENT_ADMIN_TOKEN';
export const SET_CURRENT_ADMIN_INFO = 'user/SET_CURRENT_ADMIN_INFO';
export const SET_NOTIFICATIONS = 'user/SET_NOTIFICATIONS';
export const SET_USER_MESSAGES = 'user/SET_USER_MESSAGES';
export const SET_SELECTED_USER = 'user/SET_SELECTED_USER';
export const SET_DOWNTIME_MODAL_DISPLAYED = 'user/SET_DOWNTIME_MODAL_DISPLAYED';

export const emptyState = {
    basket: [],
    basketCount: 0,
    currentAdminInfo: null,
    currentAdminToken: null,
    downtimeModalDisplayed: false,
    isImpersonateUser: false,
    notifications: [],
    selectedUser: null,
    userMessages: [],
};
export const initialState = {
    ...emptyState,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_BASKET:
            return {
                ...state,
                basket: action.basket ? action.basket : [],
                basketCount: action.basketCount,
            };
        case SET_USER_EMPTY_STATE:
            return {
                ...emptyState,
            };
        case SET_IS_IMPERSONATE_USER:
            return {
                ...state,
                isImpersonateUser: action.isImpersonateUser,
            };
        case SET_CURRENT_ADMIN_TOKEN:
            return {
                ...state,
                currentAdminToken: action.adminTocken,
            };
        case SET_CURRENT_ADMIN_INFO:
            return {
                ...state,
                currentAdminInfo: action.adminInfo,
            };
        case SET_USER_MESSAGES:
            return {
                ...state,
                userMessages: action.userMessages,
            };
        case SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.selectedUser,
            };
        case SET_DOWNTIME_MODAL_DISPLAYED:
            return {
                ...state,
                downtimeModalDisplayed: action.displayed,
            };
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications,
            };
        default:
            return state;
    }
}

export function setDowntimeModalDisplayed(displayed = true) {
    return {
        type: SET_DOWNTIME_MODAL_DISPLAYED,
        displayed,
    };
}

export function setIsImpersonateUser(isImpersonateUser) {
    return {
        type: SET_IS_IMPERSONATE_USER,
        isImpersonateUser,
    };
}

export function setCurrentAdminToken(adminTocken) {
    return {
        type: SET_CURRENT_ADMIN_TOKEN,
        adminTocken,
    };
}

export function setCurrentAdminInfo(adminInfo) {
    return {
        type: SET_CURRENT_ADMIN_INFO,
        adminInfo,
    };
}

export function setUserEmptyState() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_USER_EMPTY_STATE,
        });
    };
}

export function setUserMessages(userMessages) {
    return {
        type: SET_USER_MESSAGES,
        userMessages,
    };
}

export function setSelectedUser(selectedUser) {
    return {
        type: SET_SELECTED_USER,
        selectedUser,
    };
}

export function setNotifications(notifications) {
    return {
        type: SET_NOTIFICATIONS,
        notifications,
    };
}

export function setUserNotifications() {
    return async (dispatch) => {
        let notificationsResponse = await getNotifications();
        let notifications = notificationsResponse.response;
        dispatch(setNotifications(notifications));
    };
}

export async function getNotifications() {
    try {
        let response = await client.query({
            query: getNotificationsQuery,
            fetchPolicy: 'network-only',
        });
        return {
            response: response.data.getNotifications,
            success: 'true',
        };
    } catch (e) {
        console.log(e);
        return {
            response: [],
            success: 'fail',
            errorMessage: e,
        };
    }
}

export function getUserBasket() {
    return async (dispatch, getState) => {
        try {
            const { userAuth: { userInfo }} = getState();
            const user = userInfo?.dependent || userInfo?.employee;
            const newStatus = user?.prescriptions?.filter(prescription => prescription.rx_status === 'New') || [];
            let basket = newStatus.map(prescription => prescription.prescription_id);
            let basketCount = basket?.length || 0;
            dispatch({ type: USER_BASKET, basket, basketCount });
        } catch (error) {
            console.log('failed to get basket', error);
        }
    };
}

// CALL THE UPDATE BASKET MUTATION WITH THE PRESCRIPTION_ID TO UPDATE, AND ON SUCCESS UPDATES THE STATES IN REDUX
export function setUserBasket(prescription_id, action = 'add', prescription) {
    return async (dispatch, getState) => {
        try {
            const { userAuth: { userInfo } } = getState();
            const user = userInfo.dependent || userInfo.employee;
            let newBasketPrescriptions = [];
            let basket = [];
            let basketCount = 0
            if (action === 'remove') {
                newBasketPrescriptions = user?.prescriptions?.filter(prescription => prescription.rx_status === 'New' && prescription.prescription_id !== prescription_id) || [];
                basket = newBasketPrescriptions.map(prescription => prescription.prescription_id);
                basketCount = basket?.length || 0;
                (userInfo.dependent || userInfo.employee).prescriptions = user?.prescriptions?.filter(prescription => prescription.prescription_id !== prescription_id) || [];
                updateUserData(userInfo);
            } else if (action === 'add') {
                let prescriptionFoundInList = false
                newBasketPrescriptions = user?.prescriptions?.filter(prescription => {
                    if (!prescriptionFoundInList) {
                        prescriptionFoundInList = prescription.prescription_id === prescription_id;
                    }
                    return prescription.rx_status === 'New';
                }) || [];
                if (!prescriptionFoundInList) {
                    /* handle scenarios where prescription list does not contain new rx */
                    newBasketPrescriptions.push(prescription);
                }
                basket = newBasketPrescriptions.map(prescription => prescription.prescription_id);
                basketCount = basket?.length || 0;
            }
            dispatch({
                type: USER_BASKET,
                basket: basket,
                basketCount: basketCount,
            });
            return basket
        } catch (e) {
            console.log(e);
            return e;
        }
    };
}
