import _ from 'lodash';
import moment from 'moment';
import client from '../../../services/apollo';
import drFlowGetPricesQuery from '../../../gql/queries/drFlowGetPrices.gql';
import getAlternativeDrugsQuery from '../../../gql/queries/getAlternativeDrugs.gql';
import getDaysOfSupplyQuery from '../../../gql/queries/getDaysOfSupply.gql';
import getMedispanInformation from '../../../gql/queries/getMedispanInformation.gql';
import savePrescriptionMutation from '../../../gql/mutations/savePrescription.js';
import updatePrescriptionMutation from '../../../gql/mutations/updatePrescription.js';

import { stripChars } from '../../../utilities/commonFunctions';
import { createEventProps, trackEvent } from '../../../utilities/mixPanel';
import { getAdminFliptPersonIdLS } from '../../../utilities/commonFunctions';
import { daysOfSupplyData } from './prescriptionUtils/utils';

export function setMedispanInformation(gpiArray) {
    try {
        return client.query({
            query: getMedispanInformation,
            variables: { gpis: gpiArray },
            fetchPolicy: 'no-cache',
        });
        //return response.data.getMedispanInformation;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export function compilePatientsData(userInfo) {
    const { flipt_person_id, relationship, dependents } = userInfo;

    const filteredDependents = (dependents || []).filter((x) =>
        x.eligibility
            ? x.eligibility.some(
                  (p) => isCoverageActive(p) && moment(p.coverage_termination_date) > moment()
              )
            : false
    );

    const patientsList = [userInfo].concat(Object.values(filteredDependents)).map((user) => {
        return {
            first_name: user.first_name,
            last_name: user.last_name,
            value: user.flipt_person_id,
            private_account:
                user.flipt_person_id === flipt_person_id ? false : user.private_account,
            avatar: user.avatar,
        };
    });
    return patientsList;
}

function isCoverageActive(eligibility) {
    const { coverage_effective_date, coverage_termination_date } = eligibility;
    const cef = moment(coverage_effective_date);
    const ctd = moment(coverage_termination_date);

    return coverage_effective_date && coverage_termination_date
        ? moment().isBetween(cef, ctd, 'days', '[]')
        : false;
}

export function compileLocationsData(userInfo) {
    const { locations: userLocations, home_address_1, home_address_2, city, state, zip } = userInfo;
    
    const hasHome = userLocations?.some(location => location.name === 'Home');
    if (hasHome) {
        return userLocations;
    }
    const homeLocation = {
        name: 'Home',
        street_address: home_address_1,
        street_address_2: home_address_2 || '',
        city,
        state,
        zip_code: zip,
    };
    let locations = [homeLocation];
    if (userInfo.locations && Array.isArray(userInfo.locations) && userInfo.locations.length > 0) {
        locations = locations.concat(userInfo.locations);
    }
    return locations;
}

export async function getAlternativeDrugs(gpi, ddid) {
    try {
        const response = await client.query({
            query: getAlternativeDrugsQuery,
            variables: {
                gpi: String(gpi),
                ddid: String(ddid),
            },
            fetchPolicy: 'network-only',
        });
        return response;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function getDaysOfSupply(gpi, brandGeneric, quantity) {
    try {
        const response = await client.query({
            query: getDaysOfSupplyQuery,
            variables: {
                gpi: gpi,
                brand_generic: brandGeneric,
                quantity: quantity,
            },
        });
        return response;
    } catch (e) {
        return `Error`;
    }
}

export async function drFlowGetPrices(variablesForDrFlowGetPrices) {
    // FIRST DRUG MUST BE INITIAL DRUG SEARCHED
    try {
        const response = await client.query({
            query: drFlowGetPricesQuery,
            variables: variablesForDrFlowGetPrices,
            fetchPolicy: 'network-only',
        });
        return response;
    } catch (e) {
        return e.message;
    }
}

export async function compileDataForAlternatives(altDrugs, drugDetails, mo_dayssupply) {
    let alternatives = altDrugs.data.alternativeDrugs;
    let selectedGpi = drugDetails.dosage.gpi;
    let brandGeneric = drugDetails.name.brand_generic;
    let isCustomQuantity = drugDetails.isCustomQuantity;
    let { package_quantity, package_size } = drugDetails.quantity;
    let packageSize = isCustomQuantity
        ? drugDetails.customQuantity.match(/[-+]?[0-9]*\.?[0-9]+/)[0]
        : package_size;
    const daysOfSupplyObject = daysOfSupplyHelper(drugDetails);
    let daysOfSupply = daysOfSupplyData(drugDetails, daysOfSupplyObject.quantity);
    let packageQty = isCustomQuantity
        ? drugDetails.customQuantity
        : drugDetails.quantity.package_qty;
    let drugDosageObj = isCustomQuantity
        ? !drugDetails.form.dosages.find(
              (dataObj) => dataObj.strengths === drugDetails.quantity.dosage_strength
          )
            ? drugDetails.form.dosages[0]
            : drugDetails.form.dosages.find(
                  (dataObj) => dataObj.strengths === drugDetails.quantity.dosage_strength
              )
        : drugDetails.form.dosages.find((dataObj) =>
              dataObj.quantity.find(
                  (obj) => obj.dosage_strength === drugDetails.quantity.dosage_strength
              )
          );
    let altDrugsArray = [
        {
            brand_generic: drugDetails.name.brand_generic,
            custom_quantity: isCustomQuantity,
            days_of_supply: daysOfSupply?.match(/[-+]?[0-9]*\.?[0-9]+/)[0],
            ddid: drugDosageObj.ddid,
            dosage: drugDetails.dosage.dosage_strength,
            dosage_image: drugDetails.dosage.dosage_image,
            dosage_strength: drugDetails.quantity.dosage_strength,
            drug_class: drugDosageObj.drug_class,
            drug_covered: drugDosageObj.drug_covered,
            drug_name: drugDetails.name.drug_name,
            drug_notcovered_can_be_dispensed: drugDosageObj.drug_notcovered_can_be_dispensed,
            equivalent_drug_name: drugDetails.name.equivalent,
            form: drugDetails.form.form,
            gpi: drugDetails.dosage.gpi,
            gppc: drugDetails.quantity.gppc,
            mo_dayssupply,
            ndc: '',
            package_qty: packageQty,
            package_quantity: package_quantity,
            package_size: packageSize,
            pda: drugDosageObj.pda,
            specialty_flag: drugDosageObj.specialty_flag,
            term: drugDetails.name.drug_name,
        },
    ];

    alternatives.map((alternative) => {
        let altPackageSize = isCustomQuantity
            ? (Number(packageQty.match(/[-+]?[0-9]*\.?[0-9]+/)[0]) *
                  Number(drugDetails.quantity.package_quantity)) /
              (alternative.equivalent_dosage / alternative.alt_equivalent_dosage)
            : alternative.form === 'INHALER'
            ? getAlternativePackageSize(alternative)
            : drugDetails.quantity.package_size;

        const altSelectedQuantity = alternative.quantity.find(
            (qty) => qty.package_size === altPackageSize
        );
        const gppc = altSelectedQuantity ? altSelectedQuantity.gppc : '';
        let altObj = {
            brand_generic: alternative.brand_generic,
            custom_quantity: isCustomQuantity,
            days_of_supply: daysOfSupply?.match(/[-+]?[0-9]*\.?[0-9]+/)[0],
            ddid: alternative.ddid,
            dosage: alternative.dosage,
            dosage_image: alternative.dosage,
            dosage_strength: alternative.strengths,
            drug_class: alternative.drug_class,
            drug_name: alternative.drug_name,
            equivalent_drug_name: alternative.equivalent_drug_name,
            form: alternative.dosage,
            gpi: alternative.gpi,
            gppc,
            mo_dayssupply,
            ndc: '',
            package_qty: packageQty,
            package_quantity: drugDetails.quantity.package_quantity,
            package_size: altPackageSize,
            pda: alternative.pda,
            specialty_flag: alternative.specialty_flag,
            term: alternative.drug_name,
        };

        altDrugsArray.push(altObj);
    });

    return altDrugsArray;
}

export async function configureAlternativeDrug(
    selectedPrescriptionDetails,
    selectedPrescription,
    drugConfiguration,
    locations
) {
    let selectedPrescriptionDetailsObj = selectedPrescriptionDetails.find(
        (rx) => rx.drug_name === selectedPrescription.drug_name
    )
        ? selectedPrescriptionDetails.find((rx) => rx.drug_name === selectedPrescription.drug_name)
        : selectedPrescriptionDetails[0];
    // let gpis =
    //     _.uniq(
    //         _.flatten(
    //             _.flatten(
    //                 selectedPrescriptionDetails.map((x) =>
    //                     x.drug_forms.map((y) => y.drugs.map((z) => z.gpi))
    //                 )
    //             )
    //         )
    //     ) || [];
    //let medispanInfo = await setMedispanInformation(gpis);
    //let medispanInfoFiltered = medispanInfo.filter((medispanData) => medispanData);
    let returnedState = {};
    let newDrugConfiguration = {};
    let drugNames = compileDrugNameObj(
        selectedPrescriptionDetails.filter(
            (drugObj) => drugObj.drug_name === selectedPrescription.drug_name
        )
    );
    let newDosageData = getDosageData(
        selectedPrescriptionDetailsObj.drug_forms.find(
            (form) => form.drug_form === selectedPrescription.form
        ).drugs
    );
    let newQuantityData = getQuantityData(
        selectedPrescriptionDetailsObj.drug_forms.find(
            (form) => form.drug_form === selectedPrescription.form
        ).drugs,
        newDosageData[0].dosage_strength
    );
    let drugDosage = _.orderBy(newDosageData, 'dosage_rank');
    let drugQuantity = _.orderBy(
        newQuantityData,
        function (quantity) {
            return quantity.package_quantity * quantity.package_size;
        },
        ['asc']
    );
    let isCustomDosage =
        drugNames.find((drugName) => drugName.drug_name === selectedPrescription.drug_name)
            .dosage_strength !== selectedPrescription.dosage_strength;
    newDrugConfiguration.name = drugNames.find(
        (drugName) => drugName.drug_name === selectedPrescription.drug_name
    );
    newDrugConfiguration.form = selectedPrescriptionDetailsObj.drug_forms.find(
        (form) => form.drug_form === selectedPrescription.form
    );
    newDrugConfiguration.dosage = quantityHelper(
        drugConfiguration,
        newQuantityData,
        selectedPrescription,
        isCustomDosage
    );
    newDrugConfiguration.quantity = quantityHelper(
        drugConfiguration,
        newQuantityData,
        selectedPrescription,
        isCustomDosage
    );
    newDrugConfiguration.isCustomQuantity = drugConfiguration.isCustomQuantity
        ? drugConfiguration.isCustomQuantity
        : !drugQuantity.find(
              (qty) =>
                  qty.package_qty.split(' ')[0] === selectedPrescription.package_qty.split(' ')[0]
          )
        ? true
        : false;
    newDrugConfiguration.customQuantity = drugConfiguration.customQuantity
        ? drugConfiguration.customQuantity
        : !drugQuantity.find(
              (qty) =>
                  qty.package_qty.split(' ')[0] === selectedPrescription.package_qty.split(' ')[0]
          )
        ? `${selectedPrescription.package_qty.split(' ')[0]} ${drugQuantity[0].quantity_type}`
        : null;
    newDrugConfiguration.location = drugConfiguration.location;
    newDrugConfiguration.patient = drugConfiguration.patient;
    //newDrugConfiguration.medispanInfo = medispanInfoFiltered.find(
    //    (medispanData) => medispanData.gpi === selectedPrescription.gpi
    //);
    returnedState.drugConfiguration = newDrugConfiguration;
    returnedState.alternativeDrugs = null;
    returnedState.defaultDrug = selectedPrescriptionDetails.find(
        (drug) => drug.drug_name === selectedPrescription.drug_name
    );
    returnedState.drugForm = returnedState.defaultDrug.drug_forms.filter(
        (form) => form.drug_form === newDrugConfiguration.form.drug_form
    );
    returnedState.drugDosage = _.orderBy(
        drugDosage,
        function (dosage) {
            return dosage.dosage_rank * 1;
        },
        ['asc']
    );
    returnedState.drugQuantity = drugQuantity;
    returnedState.drugNames = compileDrugNameObj(selectedPrescriptionDetails);
    returnedState.locations = locations;
    //returnedState.medispanInfo = medispanInfoFiltered;
    return returnedState;
}

function quantityHelper(drugConfiguration, newQuantityData, selectedPrescription, isCustomDosage) {
    if (isCustomDosage) {
        let newQuantity = _.cloneDeep(newQuantityData[0]);
        newQuantity.dosage_strength = selectedPrescription.dosage_strength;
        newQuantity.gpi = selectedPrescription.gpi;
        newQuantity.gppc = selectedPrescription.gppc;
        newQuantity.package_desc = selectedPrescription.package_desc;
        newQuantity.package_qty = selectedPrescription.package_qty;
        newQuantity.package_quantity = selectedPrescription.package_quantity;
        newQuantity.package_size = selectedPrescription.package_size;
        drugConfiguration.customQuantity = selectedPrescription.package_qty;
        return newQuantity;
    }

    if (drugConfiguration.isCustomQuantity) {
        return newQuantityData[0];
    } else {
        if (
            newQuantityData.find(
                (qty) =>
                    qty.package_qty.split(' ')[0] === selectedPrescription.package_qty.split(' ')[0]
            )
        ) {
            return newQuantityData.find(
                (qty) =>
                    qty.package_qty.split(' ')[0] === selectedPrescription.package_qty.split(' ')[0]
            );
        } else {
            let packageSize = selectedPrescription.package_qty.split(' ')[0];
            let packageType = newQuantityData[0].quantity_type;
            newQuantityData[0].package_size = packageSize;
            newQuantityData[0].package_qty = `${packageSize} ${packageType}`;
            return newQuantityData[0];
        }
    }
}

export async function compilePrescriptionToSave(drugConfigurationToSave, originalDrugSearched) {
    let isCustomQuantity = drugConfigurationToSave.isCustomQuantity;
    let { custom_qty, package_quantity, package_size } = drugConfigurationToSave.quantity;
    let packageSize =
        isCustomQuantity && custom_qty === 'package_size'
            ? drugConfigurationToSave.customQuantity.match(/[-+]?[0-9]*\.?[0-9]+/)[0]
            : package_size;
    let quantity =
        isCustomQuantity && custom_qty === 'package_quantity'
            ? `${
                  drugConfigurationToSave.customQuantity.match(/[-+]?[0-9]*\.?[0-9]+/)[0] *
                  packageSize
              }`
            : `${package_quantity * packageSize}`;
    let daysOfSupply = daysOfSupplyData(drugConfigurationToSave, quantity);

    const selectedDosageStrength = drugConfigurationToSave.quantity.dosage_strength;
    const drugConfigMatchingSelectedDosage = drugConfigurationToSave.form.dosages.find(
        (dataObj) => dataObj.strengths === selectedDosageStrength
    );
    let sortedDose = drugConfigurationToSave.form.dosages.find((dataObj) =>
        dataObj.quantity.find((obj) => obj.dosage_strength === selectedDosageStrength)
    );
    let drugDosageObj = isCustomQuantity
        ? drugConfigMatchingSelectedDosage
        : sortedDose || drugConfigMatchingSelectedDosage;
    if (!drugDosageObj) {
        drugDosageObj = findConfiguredDrugDosage(drugConfigurationToSave, selectedDosageStrength);
        if (!drugDosageObj) {
            drugDosageObj = drugConfigurationToSave.form.dosages[0];
        }
    }

    let originalSearchedValues =
        originalDrugSearched &&
        originalDrugSearched.drug_name !== drugConfigurationToSave.name.drug_name
            ? {
                  searchedDrugValue: originalDrugSearched.drug_employer_cost,
                  searched_days_of_supply: originalDrugSearched.days_of_supply,
                  searched_ddid: originalDrugSearched.ddid,
                  searched_dosage: originalDrugSearched.form,
                  searched_dosage_strength: originalDrugSearched.dosage_strength,
                  searched_drug_cost:
                      originalDrugSearched.drug_price === 0 || originalDrugSearched.drug_price
                          ? originalDrugSearched.drug_price.toString()
                          : null,
                  searched_drug_name: originalDrugSearched.drug_name,
                  searched_employee_opc:
                      originalDrugSearched.drug_price === 0 || originalDrugSearched.drug_price
                          ? originalDrugSearched.drug_price.toString()
                          : null,
                  searched_employer_cost:
                      originalDrugSearched.drug_employer_cost === 0 ||
                      originalDrugSearched.drug_employer_cost
                          ? originalDrugSearched.drug_employer_cost.toString()
                          : null,
                  searched_gpi: originalDrugSearched.gpi,
                  searched_gppc: originalDrugSearched.gppc,
                  searched_package_quantity: originalDrugSearched.package_quantity,
                  searched_package_qty: originalDrugSearched.package_qty,
                  searched_package_size: originalDrugSearched.package_size,
              }
            : {};
    let compiledDrugData = {
        admin_flipt_person_id: getAdminFliptPersonIdLS() || null,
        alternative_drug_rewards: '',
        application: 'Web App',
        brand_generic: drugConfigurationToSave.name.brand_generic,
        custom_qty: drugConfigurationToSave.quantity.custom_qty,
        custom_quantity: isCustomQuantity ? quantity : null,
        daysofsupply: drugConfigurationToSave.daysOfSupply
            ? drugConfigurationToSave.daysOfSupply
            : daysOfSupply?.match(/[-+]?[0-9]*\.?[0-9]+/)[0],
        days_of_supply:
            drugConfigurationToSave.daysOfSupply ||
            (originalDrugSearched && !isCustomQuantity
                ? originalDrugSearched.days_of_supply
                : daysOfSupply),
        ddid: drugDosageObj.ddid,
        ddn_form: drugDosageObj.ddn_form,
        ddn_name: drugDosageObj.ddn_name,
        ddn_strength: drugDosageObj.ddn_strength,
        dosage: drugConfigurationToSave.form.form,
        dosage_image: drugDosageObj.dosage_image,
        dosage_strength: drugConfigurationToSave.quantity.dosage_strength,
        dpa: drugDosageObj.pda,
        drug: drugConfigurationToSave.name.drug_name,
        drug_class: drugDosageObj.drug_class,
        drug_covered: drugDosageObj.drug_covered,
        drug_name: drugConfigurationToSave.name.drug_name,
        drug_notcovered_can_be_dispensed: drugDosageObj.drug_notcovered_can_be_dispensed,
        drug_type: drugDosageObj.drug_type,
        equivalent_brand: drugConfigurationToSave.name.equivalent,
        flipt_person_id: drugConfigurationToSave.patient.value,
        form: drugConfigurationToSave.form.form,
        gpi: drugConfigurationToSave.dosage.gpi,
        gppc: drugConfigurationToSave.quantity.gppc,
        lm_form: drugDosageObj.lm_form,
        lm_name: drugDosageObj.lm_name,
        // lm_ndc:
        //     drugDosageObj.lm_ndc.length && drugDosageObj.lm_ndc.length > 0
        //         ? drugDosageObj.lm_ndc[0]
        //         : typeof drugDosageObj.lm_ndc === 'object'
        //         ? ''
        //         : drugDosageObj.lm_ndc,
        lm_strength: drugDosageObj.lm_strength,
        locationSelected: drugConfigurationToSave.location.name,
        maintenance_drug_flag: drugDosageObj.maintenance_drug_flag,
        otc_indicator: drugDosageObj.otc_indicator,
        package_desc: drugConfigurationToSave.quantity.package_desc,
        package_qty:
            isCustomQuantity && !drugConfigurationToSave.isCustomDosageQuantity
                ? drugConfigurationToSave.customQuantity
                : drugConfigurationToSave.quantity.package_qty,
        package_quantity: package_quantity || '1.0',
        package_size: packageSize,
        pkg_desc_cd: drugConfigurationToSave.quantity.pkg_desc_cd,
        pkg_uom: drugConfigurationToSave.quantity.pkg_uom,
        quantity: drugConfigurationToSave.quantity.package_quantity,
        quantity_type: drugConfigurationToSave.quantity.quantity_type,
        rx_flipt_person_id:
            drugConfigurationToSave.patient.rx_flipt_person_id ||
            drugConfigurationToSave.patient.value,
        rbp: drugConfigurationToSave.rbp,
        rbp_penalty: drugConfigurationToSave.rbp_penalty,
        rbp_reward: drugConfigurationToSave.rbp_reward,
        search_location: drugConfigurationToSave.location.name,
        specialty_flag: drugDosageObj.specialty_flag,
        strengths: drugDosageObj.strengths,
        zipCode: drugConfigurationToSave.location.zip_code,
    };

    drugConfigurationToSave.isAlt ? (compiledDrugData.isAlt = true) : null;

    originalDrugSearched && originalDrugSearched.prescription_id
        ? (compiledDrugData.prescription_id = originalDrugSearched.prescription_id)
        : null;

    return { ...compiledDrugData, ...originalSearchedValues };
}

export async function savePrescriptionAndAddToCart(
    drugConfigurationToSave,
    originalDrugSearched = null,
    currentEditPrescription = null,
    isAlt,
    userInfo = null
) {
    let prescriptionToSave = await compilePrescriptionToSave(
        drugConfigurationToSave,
        originalDrugSearched
    );
    // flipt_person_id  is assoicate  with the logged in user so we need to set flipt_person_id as a logged in user id
    if (userInfo) {
        let user_id = userInfo?.employee
            ? userInfo?.employee?.flipt_person_id
            : userInfo?.dependent?.flipt_person_id;
        prescriptionToSave = {
            ...prescriptionToSave,
            flipt_person_id: user_id,
        };
    }
    // WHILE EDITING DRUG ADD RX ID AND REMOVE ISALT AND CUSTOM QUANTITY BOOLEANS IF THEY ARE FALSE
    currentEditPrescription
        ? (prescriptionToSave.prescription_id = currentEditPrescription.prescription_id)
        : null;
    currentEditPrescription && currentEditPrescription.prescription_id
        ? delete prescriptionToSave.isAlt
        : null;
    !prescriptionToSave.custom_quantity ? delete prescriptionToSave.custom_quantity : null;
    isAlt ? (prescriptionToSave.isAlt = isAlt) : null;

    try {
        const response = await client.mutate({
            mutation: prescriptionToSave.prescription_id
                ? updatePrescriptionMutation
                : savePrescriptionMutation,
            variables: prescriptionToSave,
        });

        let data = {
            event: isAlt ? 'Alternative Drug Selected' : 'Drug Configured',
            eventProps: createEventProps(prescriptionToSave),
            flipt_person_id: prescriptionToSave.flipt_person_id,
        };

        trackEvent(data);
        return response;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export async function compileDrugConfiguration(
    drugDetails,
    drugNameSelected,
    isAlt,
    originallySearchedDrug,
    selectedUser,
    userInfo
) {
    const patientData = compilePatientsData(userInfo);
    const locations = compileLocationsData(userInfo);

    //need to remove all console and limitationData static data
    const { rxPlanMaster, medicare_eligible } = userInfo;
    const { limitation } = rxPlanMaster;
    let checkLimitation = limitation?.filter(
        (item, index) =>
            item?.action?.toLowerCase() === 'reject' &&
            item?.app_medicare_message_indicator === 'Y' &&
            item?.medicare_eligible === medicare_eligible
    );
    checkLimitation?.sort((a, b) => {
        return a.rank - b.rank;
    });
    const { medicare_eligible: medicare_eligible_limitation } =
        checkLimitation.length > 0 ? checkLimitation[0] : {};
    if (!originallySearchedDrug) {
        const drugNames = compileDrugNameObj(drugDetails);
        const genericDrug = drugNames.find((drug) => drug.brand_generic === 'G');

        let defaultDrug = genericDrug
            ? drugDetails.find((drug) => drug.drug_name === genericDrug.drug_name)
            : drugDetails.find((drug) => drug.drug_name === drugNameSelected);

        const mediCareBrandGeneric = checkMediCareBrandGeneric(drugDetails);
        defaultDrug = mediCareBrandGeneric?.drug_forms ? mediCareBrandGeneric : defaultDrug;
        const drugForm = defaultDrug.drug_forms;
        const gpis =
            _.uniq(
                _.flatten(
                    _.flatten(
                        drugDetails.map((x) => x.drug_forms.map((y) => y.drugs.map((z) => z.gpi)))
                    )
                )
            ) || [];
        let defautMediCare = {
            drugs: [],
        };
        const mediCareDrugForm =
            medicare_eligible === medicare_eligible_limitation
                ? checkMediCareDrugForm(defaultDrug)
                : defautMediCare;
        const mediCareDrugs =
            mediCareDrugForm.drugs.length != 0 ? mediCareDrugForm.drugs : drugForm[0].drugs;

        //const medispanInfo = await setMedispanInformation(gpis);
        //var medispanInfoFiltered = medispanInfo.filter((medispanData) => medispanData);

        const newDosageData = getDosageData(mediCareDrugs);

        let display_drug_Name = newDosageData.filter(
            (item, index) =>
                !(
                    item?.medicare_type_codes?.includes('1') ||
                    item?.medicare_type_codes?.includes('2')
                ) || item?.is_grandfathered_drugcoverage_drug === 'Y'
        );
        let dosage_strength_Medicare =
            display_drug_Name.length > 0
                ? display_drug_Name[0]?.dosage_strength
                : newDosageData[0]?.dosage_strength;

        const newQuantityData = getQuantityData(mediCareDrugs, dosage_strength_Medicare);
        const drugDosage = _.orderBy(
            newDosageData,
            function (dosage) {
                return dosage.dosage_rank * 1;
            },
            ['asc']
        );
        const drugQuantity = _.orderBy(
            newQuantityData,
            function (quantity) {
                return quantity.package_quantity * quantity.package_size;
            },
            ['asc']
        );
        const drugConfiguration = {
            customQuantity: null,
            dosage: display_drug_Name.length > 0 ? display_drug_Name[0] : newDosageData[0],
            form: mediCareDrugForm.drugs.length != 0 ? mediCareDrugForm : drugForm[0],
            isAlt,
            isCustomQuantity: false,
            location: locations[0],
            name: drugNames.find((drug) => drug.drug_name === defaultDrug.drug_name),
            patient: selectedUser,
            quantity: newQuantityData[0],
        };

        return {
            defaultDrug,
            drugConfiguration,
            drugDosage,
            drugForm,
            drugNames,
            drugQuantity,
            locations,
            patients: patientData,
        };
    } else {
        const newDrugConfiguration = {
            isAlt: drugDetails[0].drug_name !== originallySearchedDrug.drug_name,
            isCustomQuantity: originallySearchedDrug.custom_quantity
                ? originallySearchedDrug.custom_quantity
                : null,
            location:
                locations.find((location) =>
                    originallySearchedDrug.locationSelected.includes(location.name)
                ) || locations[0],
            patient: patientData.find(
                (patient) => patient.value === originallySearchedDrug.rx_flipt_person_id
            ),
        };

        return await configureAlternativeDrug(
            drugDetails,
            originallySearchedDrug,
            newDrugConfiguration,
            locations
        );
    }
}

function compileDrugNameObj(drugDetails) {
    let drugNames = drugDetails.map((drug) => {
        let equArray = _.flattenDeep(
            drug.forms.map((form) =>
                form.drugs.map((drug) =>
                    drug.equivalent && drug.equivalent.length > 0 ? drug.equivalent : null
                )
            )
        );
        let drugNameObj = {
            drug_name: drug.drug_name,
            brand_generic: drug.forms.find((formObj) =>
                formObj.drugs.find((drugObj) => drugObj.brand_generic)
            ).drugs[0].brand_generic,
            equivalent: equArray.find((equObj) => equObj)
                ? equArray.find((equObj) => equObj).equ_drug_name
                : '',
        };
        return drugNameObj;
    });

    return drugNames;
}

export function getDosageData(formData) {
    const sd = formData
        .map((d) =>
            d.quantity.map((q) => ({
                ...q,
                gpi: d.gpi,
                medicare_type_codes: d.medicare_type_codes,
                medicare_eligible: d.medicare_eligible,
                brand_generic: d.brand_generic,
                is_grandfathered_drugcoverage_drug:d.is_grandfathered_drugcoverage_drug
            }))
        )
        .reduce((acc, c) => {
            const dosageRank = c.sort((a, b) => a.dosage_rank * 1 > b.dosage_rank * 1);

            const dosageArray = dosageRank.reduce((dsArray, ds) => {
                const { dosage_strength } = ds;
                const notInArray =
                    dsArray.findIndex((s) => s.dosage_strength === dosage_strength) === -1;

                if (notInArray) {
                    dsArray.push(ds);
                }

                return dsArray;
            }, []);

            acc.push(...dosageArray);
            return acc;
        }, [])
        .sort((a, b) => a.dosage_rank * 1 > b.dosage_rank * 1);

    return sd.sort((a, b) => stripChars(a.dosage_strength) > stripChars(b.dosage_strength));
}

export function getQuantityData(formData, dosageStrength) {
    const quantityResults = formData.reduce((acc, curr) => {
        if (!curr.quantity) {
            return acc;
        }

        const qArray = curr.quantity.filter((q) => q.dosage_strength === dosageStrength);

        if (qArray.length) {
            acc.push(qArray);
        }

        return acc;
    }, []);

    return quantityResults.length
        ? quantityResults[0].sort((a, b) => a.display_rank - b.display_rank)
        : formData;
}

export function daysOfSupplyHelper(drugConfiguration) {
    let brand_generic = drugConfiguration.name.brand_generic;
    let gpi = drugConfiguration.dosage.gpi;
    let quantity;

    if (!drugConfiguration.isCustomQuantity) {
        quantity = (
            drugConfiguration.quantity.package_quantity * drugConfiguration.quantity.package_size
        ).toString();
    } else {
        let { custom_qty, package_quantity, package_size } = drugConfiguration.quantity;
        let customValue = drugConfiguration.customQuantity.match(/[-+]?[0-9]*\.?[0-9]+/)[0];
        quantity =
            (custom_qty && custom_qty.includes('package_quantity')
                ? package_size
                : package_quantity) * customValue;
    }

    return {
        brand_generic,
        gpi,
        quantity,
    };
}

export function quantityToDisplayHelper(quantityObj) {
    return quantityObj.package_qty.split(quantityObj.quantity_type)[0].trim();
}

export function customQuantityHelper(
    drugConfiguration,
    isCustomQuantity,
    isCustomDosageQuantity,
    customQuantity,
    form,
    drugQuantity,
    valueForDosage
) {
    let newDrugConfiguration;

    if (
        (isCustomQuantity && !isCustomDosageQuantity) ||
        (!isCustomQuantity && !isCustomDosageQuantity)
    ) {
        const newQuantity = getNewQuantity({
            customQuantity,
            drugConfiguration,
            drugQuantity,
            form,
        });
        newDrugConfiguration = {
            customQuantity: `${customQuantity} ${form}`,
            dosage: drugConfiguration.dosage,
            form: drugConfiguration.form,
            isAlt: drugConfiguration.isAlt,
            isCustomQuantity: true,
            location: drugConfiguration.location,
            medispanInfo: drugConfiguration.medispanInfo,
            name: drugConfiguration.name,
            patient: drugConfiguration.patient,
            quantity: newQuantity,
        };
    } else if (isCustomQuantity && isCustomDosageQuantity) {
        let { dosage } = _.cloneDeep(drugConfiguration);
        let newStrength =
            `${customQuantity} ${dosage.pkg_uom}` +
            (dosage.dosage_strength.split(` ${dosage.pkg_uom}`).length < 1
                ? ''
                : dosage.dosage_strength.split(` ${dosage.pkg_uom}`)[1]);
        dosage.dosage_strength = newStrength;
        dosage.package_qty = `1 ${form}`;
        dosage.package_quantity = '1.0';
        dosage.package_size = `${customQuantity}.0`;

        newDrugConfiguration = {
            customQuantity: isCustomQuantity ? `${customQuantity} ${valueForDosage}` : null,
            dosage: dosage,
            form: drugConfiguration.form,
            isAlt: drugConfiguration.isAlt,
            isCustomQuantity: isCustomQuantity,
            isCustomDosageQuantity,
            location: drugConfiguration.location,
            medispanInfo: drugConfiguration.medispanInfo,
            name: drugConfiguration.name,
            patient: drugConfiguration.patient,
            quantity: isCustomQuantity
                ? dosage
                : drugQuantity.find(
                      (drugObj) => drugObj.package_qty.split(' ')[0] === customQuantity
                  )
                ? drugQuantity.find(
                      (drugObj) => drugObj.package_qty.split(' ')[0] === customQuantity
                  )
                : drugConfiguration.quantity,
        };
    }

    return newDrugConfiguration;
}

function getNewQuantity({ customQuantity, drugConfiguration, drugQuantity, form }) {
    const cQuantity = Number(customQuantity);
    const selectedQuantity = drugConfiguration.quantity;
    const setPackageQuantity = selectedQuantity.package_desc.toLowerCase() === form.toLowerCase();
    const packageQTYBasedOnCustomQuantity = drugQuantity.find(
        (drugObj) => drugObj.package_qty.split(' ')[0] === cQuantity
    );

    if (packageQTYBasedOnCustomQuantity) {
        return packageQTYBasedOnCustomQuantity;
    } else {
        return setPackageQuantity
            ? {
                  ...drugConfiguration.quantity,
                  package_quantity:
                      cQuantity >= Number(selectedQuantity.package_quantity) ? cQuantity : 1,
              }
            : {
                  ...drugConfiguration.quantity,
                  package_size: cQuantity,
                  package_quantity: 1, //FD-5724: package_quantity was not getting set to 1 incase of customQuantity
              };
    }
}

export function getActualQuantity(drug) {
    const { package_qty, package_size, package_quantity } = drug;
    if (package_qty && typeof 'string') {
        return package_qty.split(' ')[0];
    } else {
        return parseInt(package_quantity) * parseInt(package_size);
    }
}

export function getAlternativePackageSize(alternativeDrug) {
    /*
    1. Expects drug object from Couchbase
    2. Do not use for custom quantity
    3. Picks the default package_size value based on selected strength
  */
    const { quantity: quantityList, strengths: selectedStrength } = alternativeDrug;
    let quantityObject = quantityList.find(
        (quantity) => quantity.dosage_strength === selectedStrength
    );
    if (!quantityObject) {
        // Less strict match on selected strength
        quantityObject = quantityList.find((quantity) =>
            quantity.dosage_strength.includes(selectedStrength)
        );
    }
    if (!quantityObject) {
        // Last resort, select the package_size of the first quantity object
        const [firstQuantityObject] = quantityList;
        quantityObject = firstQuantityObject || {};
    }
    const packageSize = quantityObject.package_size;
    return packageSize;
}

export function findConfiguredDrugDosage(drugConfiguration, dosageStrength) {
    try {
        let dosage = null;
        /* Original Approach, Strict Match */
        dosage = drugConfiguration.form.drugs.find(
            (dosageObj) => dosageObj.strengths === dosageStrength
        );

        if (!dosage) {
            /* Alternative approach, Light match using includes */
            dosage = drugConfiguration.form.drugs.find((dosageObj) =>
                dosageStrength.includes(dosageObj.strengths)
            );
        }
        return dosage;
    } catch (err) {
        console.log('Failed to get drug dosage from configuration');
        return null;
    }
}

export const checkMediCareDosage = (drug_details, gpi) => {
    const singleDrug = drug_details.filter((item, index) => item.gpi == gpi);
    if (
        singleDrug.length > 0 &&
        (singleDrug[0]?.medicare_type_codes?.includes('1') ||
            singleDrug[0]?.medicare_type_codes?.includes('2')) &&
        singleDrug[0]?.is_grandfathered_drugcoverage_drug !== 'Y'
    ) {
        return true;
    } else {
        return false;
    }
};

export const checkMediCareDrugConfig = (drug_details) => {
    const singleDrug = drug_details.filter(
        (item, index) =>
            !(
                item?.medicare_type_codes?.includes('1') || item?.medicare_type_codes?.includes('2')
            ) || item?.is_grandfathered_drugcoverage_drug === 'Y'
    );

    if (singleDrug.length == 0) {
        return true;
    } else {
        return false;
    }
};

const checkMediCareDrugForm = (drugForm) => {
    let obj = {
        form: '',
        drugs: [],
    };
    for (var drug of drugForm.drug_forms) {
        const singleDrug = drug.drugs.filter(
            (item, index) =>
                !(
                    item?.medicare_type_codes?.includes('1') ||
                    item?.medicare_type_codes?.includes('2')
                ) || item?.is_grandfathered_drugcoverage_drug === 'Y'
        );
        if (singleDrug.length > 0) {
            obj = {
                form: drug.drug_form,
                drugs: drug.drugs,
            };
            break;
        }
    }
    return obj;
};

const checkMediCareBrandGeneric = (drug_details) => {
    let flag = false;
    let singleDrug = [];
    for (var singleDrugdeatil of drug_details) {
        for (var form of singleDrugdeatil.drug_forms) {
            singleDrug = form.drugs.filter(
                (item, index) =>
                    !(
                        item?.medicare_type_codes?.includes('1') ||
                        item?.medicare_type_codes?.includes('2')
                    )
            );
            if (singleDrug.length > 0) {
                flag = true;
                break;
            }
        }
        if (flag) {
            return singleDrugdeatil;
        }
    }
    return singleDrug;
};
