// @flow

import type { Storage, TokenKey, UserInfoKey, STORES_TYPES } from './type';
import moment from 'moment';
import btoa from 'btoa';

const TOKEN_KEY = 'token';
const ADMIN_TOKEN = 'admin';
const ADMIN_ID = 'admin_id';
const ADMIN_HISTORY = 'admin_history';
const USER_INFO = 'userInfo';

const APP_PERSIST_STORES_TYPES: Array<STORES_TYPES> = ['localStorage', 'sessionStorage'];

const parse = JSON.parse;
const stringify = JSON.stringify;

/*
  auth object
  -> store "TOKEN_KEY"
  - default storage is "localStorage"
  - default token key is 'token'
 */
export const auth = {
    // /////////////////////////////////////////////////////////////
    // TOKEN
    // /////////////////////////////////////////////////////////////

    /**
     * get token from localstorage
     *
     * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
     * @param {any} [tokenKey=TOKEN_KEY]  optionnal parameter to specify a token key
     * @returns {string} token value
     */
    getToken(
        fromStorage: Storage = APP_PERSIST_STORES_TYPES[0],
        tokenKey: TokenKey = TOKEN_KEY
    ): ?string {
        // localStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
            return (localStorage && localStorage.getItem(tokenKey)) || null;
        }
        // sessionStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
            return (sessionStorage && sessionStorage.getItem(tokenKey)) || null;
        }
        // default:
        return null;
    },

    /**
     * set the token value into localstorage (managed by localforage)
     *
     * @param {string} [value=''] token value
     * @param {'localStorage' | 'sessionStorage'} [toStorage='localStorage'] specify storage
     * @param {any} [tokenKey='token'] token key
     * @returns {boolean} success/failure flag
     */
    setToken(
        value: string = '',
        isAdmin: boolean = false,
        toStorage: Storage = APP_PERSIST_STORES_TYPES[0],
        tokenKey: TokenKey = TOKEN_KEY,
    ): ?string {
        if (!value || value.length <= 0) {
            return;
        }

        const signedToken = btoa(value + ':')
        // localStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
            if (localStorage) {
                localStorage.setItem(tokenKey, signedToken);
            }
        }
        // sessionStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[1]) {
            if (sessionStorage) {
                sessionStorage.setItem(tokenKey, signedToken);
            }
        }

        if(isAdmin) {
            localStorage.setItem(ADMIN_TOKEN, signedToken);
        }
    },

    /**
     * check
     * - if token key contains a valid token value (defined and not an empty value)
     * - if the token expiration date is passed
     *
     *
     * Note: 'isAuthenticated' just checks 'tokenKey' on store (localStorage by default or sessionStorage)
     *
     * You may think: 'ok I just put an empty token key and I have access to protected routes?''
     *    -> answer is:  YES^^
     * BUT
     * -> : your backend will not recognize a wrong token so private data or safe and you protected view could be a bit ugly without any data.
     *
     * => ON CONCLUSION: this aim of 'isAuthenticated'
     *    -> is to help for a better "user experience"  (= better than displaying a view with no data since server did not accept the user).
     *    -> it is not a security purpose (security comes from backend, since frontend is easily hackable => user has access to all your frontend)
     *
     * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
     * @param {any} [tokenKey=TOKEN_KEY] token key
     * @returns {bool} is authenticed response
     */
    isAuthenticated(
        fromStorage: Storage = APP_PERSIST_STORES_TYPES[0],
        tokenKey: TokenKey = TOKEN_KEY
    ): boolean {
        // localStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
            if (localStorage && localStorage.getItem(tokenKey)) {
                return true;
            } else {
                return false;
            }
        }
        // sessionStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
            if (sessionStorage && sessionStorage.getItem(tokenKey)) {
                return true;
            } else {
                return false;
            }
        }
        // default:
        return false;
    },

    /**
     * delete token
     *
     * @param {any} [tokenKey='token'] token key
     * @returns {bool} success/failure flag
     */
    clearToken(
        storage: Storage = APP_PERSIST_STORES_TYPES[0],
        tokenKey: TokenKey = TOKEN_KEY
    ): boolean {
        // localStorage:
        if (localStorage && localStorage[tokenKey]) {
            localStorage.removeItem(tokenKey);
            return true;
        }
        // sessionStorage:
        if (sessionStorage && sessionStorage[tokenKey]) {
            sessionStorage.removeItem(tokenKey);
            return true;
        }

        return false;
    },

    /**
     * return expiration date from token
     *
     * @param {string} encodedToken - base 64 token received from server and stored in local storage
     * @returns {date | null} returns expiration date or null id expired props not found in decoded token
     */
    getTokenExpirationDate(encodedToken: any): Date {
        if (!encodedToken) {
            return new Date(0); // is expired
        }

        const token = encodedToken;
        if (!token.exp) {
            return new Date(0); // is expired
        }
        const expirationDate = new Date(token.exp * 1000);
        return expirationDate;
    },

    /**
     * tell is token is expired (compared to now)
     *
     * @param {string} encodedToken - base 64 token received from server and stored in local storage
     * @returns {bool} returns true if expired else false
     */
    isExpiredToken(encodedToken: any): boolean {
        const expirationDate = this.getTokenExpirationDate(encodedToken);
        const rightNow = moment();
        const isExpiredToken = moment(rightNow).isAfter(moment(expirationDate));

        return isExpiredToken;
    },

    // /////////////////////////////////////////////////////////////
    // USER_INFO
    // /////////////////////////////////////////////////////////////
    /**
     * get user info from localstorage
     *
     * @param {'localStorage' | 'sessionStorage'} [fromStorage='localStorage'] specify storage
     * @param {any} [userInfoKey='userInfo']  optionnal parameter to specify a token key
     * @returns {string} token value
     */
    getUserInfo(
        fromStorage: Storage = APP_PERSIST_STORES_TYPES[0],
        userInfoKey: UserInfoKey = USER_INFO
    ): ?string {
        // localStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
            return (localStorage && parse(localStorage.getItem(userInfoKey))) || null;
        }
        // sessionStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
            return (sessionStorage && parse(sessionStorage.getItem(userInfoKey))) || null;
        }
        // default:
        return null;
    },

    getAuthInfo(
        fromStorage: Storage = APP_PERSIST_STORES_TYPES[0],
        userInfoKey: UserInfoKey = USER_INFO
    ): ?string {
        // localStorage:
        // I need flipt_person_id, token and id
        // const userInfo = auth.getUserInfo();
        // let user = userInfo && userInfo.employee ? userInfo.employee : userInfo.dependent;

        if (fromStorage === APP_PERSIST_STORES_TYPES[0]) {
            return (localStorage && parse(localStorage.getItem(userInfoKey))) || null;
        }
        // sessionStorage:
        if (fromStorage === APP_PERSIST_STORES_TYPES[1]) {
            return (sessionStorage && parse(sessionStorage.getItem(userInfoKey))) || null;
        }
        // default:
        return null;
    },

    getAdminToken() {
        return localStorage.getItem(ADMIN_TOKEN);
    },

    getAdminID() {
        return JSON.parse(localStorage.getItem(ADMIN_ID)) || {};
    },

    isAdmin() {
        return localStorage.getItem(ADMIN_TOKEN) ? true : false;
    },

    appendAdminHistory(varibale) {
        let adminHistory = JSON.parse(localStorage.getItem(ADMIN_HISTORY)) || [];
        const entry = adminHistory.findIndex((item) => {
            if (varibale.email) {
                return item.email === varibale.email;
            }
            if (varibale.flipt_person_id) {
                return item.flipt_person_id === varibale.flipt_person_id
            }
            return false;
        });
        if (entry > -1) return;
        adminHistory.push(varibale)
        if (adminHistory.length > 3) {
            adminHistory.shift();
        }
        localStorage.setItem(ADMIN_HISTORY, JSON.stringify(adminHistory));
    },

    getAdminHistory() {
        return JSON.parse(localStorage.getItem(ADMIN_HISTORY)) || [];
    },

    /**
     * set the userInfo value into localstorage
     *
     * @param {object} [value=''] token value
     * @param {'localStorage' | 'sessionStorage'} [toStorage='localStorage'] specify storage
     * @param {any} [userInfoKey='userInfo'] token key
     * @returns {boolean} success/failure flag
     */
    setUserInfo(
        value: string = '',
        isAdmin: boolean = false,
        toStorage: Storage = APP_PERSIST_STORES_TYPES[0],
        userInfoKey: UserInfoKey = USER_INFO
    ): any {
        const finalValue = {
            access_token: value.access_token,
            id: value.id,
            user_id: value.user_id,
            dependent: value.dependent
                ? { flipt_person_id: value.dependent.flipt_person_id }
                : null,
            employee: value.employee ? { flipt_person_id: value.employee.flipt_person_id } : null,
            admin: value.admin_flipt_person_id
                ? {
                      admin_flipt_person_id: value.admin_flipt_person_id,
                      access_token: value.access_token,
                  }
                : null,
        };
        if (!value || value.length <= 0) {
            return;
        }
        // localStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[0]) {
            if (localStorage) {
                localStorage.setItem(userInfoKey, stringify(finalValue));
            }
        }
        // sessionStorage:
        if (toStorage === APP_PERSIST_STORES_TYPES[1]) {
            if (sessionStorage) {
                sessionStorage.setItem(userInfoKey, stringify(finalValue));
            }
        }

        if (isAdmin) {
            const admin = (value?.employee || value?.dependent || {})
            const flipt_person_id = admin.flipt_person_id || '';
            const email = value.username || '';
            const domain = admin.domain?.domain
            const store = { flipt_person_id, email, domain };
            localStorage.setItem(ADMIN_ID, JSON.stringify(store));
        }
    },

    /**
     * delete userInfo
     *
     * @param {string} [userInfoKey='userInfo'] token key
     * @returns {bool} success/failure flag
     */
    clearUserInfo(userInfoKey: UserInfoKey = USER_INFO): any {
        // localStorage:
        if (localStorage && localStorage[userInfoKey]) {
            localStorage.removeItem(userInfoKey);
        }
        // sessionStorage:
        if (sessionStorage && sessionStorage[userInfoKey]) {
            sessionStorage.removeItem(userInfoKey);
        }
    },

    // /////////////////////////////////////////////////////////////
    // COMMON
    // /////////////////////////////////////////////////////////////

    /**
     * forget me method: clear all
     * @returns {bool} success/failure flag
     */
    clearAllAppStorage(): any {
        if (localStorage) {
            const rememberData = localStorage.getItem('remembered')
                ? localStorage.getItem('remembered')
                : null;
            const walkthrough_viewed = localStorage.getItem('walkthrough_viewed')
                ? localStorage.getItem('walkthrough_viewed')
                : null;
            localStorage.clear();
            localStorage.setItem('remembered', rememberData);
            localStorage.setItem('walkthrough_viewed', walkthrough_viewed);
        }

        if (sessionStorage) {
            sessionStorage.clear();
        }
    },
};

export default auth;
