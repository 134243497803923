import React from 'react';

const Avatar = ({ user, small }) => {
    let firstName = '';
    let lastName = '';
    let avatar_id= '';
    let photo = null;
    if (user) {
        const { first_name, last_name, avatar, flipt_person_id} = user;
        firstName = first_name;
        lastName = last_name;
        avatar_id = flipt_person_id || avatar;

        if (avatar) {
            const avatarBlob = localStorage.getItem(avatar_id);
            if (avatarBlob) {
                photo = avatarBlob;
            }
        }
    }

    return (
        <span className={small ? 'blue-circle-wrap-small' : 'blue-circle-wrap'} data-cy="avatar">
            {!photo && (
                <i className="blue-circle" style={{ width: '100%', height: '100%' }}>
                    {firstName.charAt(0)}
                    {lastName.charAt(0)}
                </i>
            )}
            {!!photo && (
                <img
                    className="blue-circle"
                    style={small ? { width: '30px', height: '30px', padding: 0 } : { padding: 0 }}
                    src={photo}
                />
            )}
        </span>
    );
};

export default Avatar;
