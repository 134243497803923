import { Buffer } from 'buffer';
import * as config from '../config';
import { auth } from '../services/auth';
// const MIXPANEL_API_URL = 'http://api.mixpanel.com';
// const MIXPANEL_API_URL = 'http://example.com';
// const MIXPANEL_API_TOKEN = 'cc4e88b8eaca3f3e7ceca8f47a43b2e0';

export default class MixpanelAnalytics {
    constructor() {
        this.ready = false;
        this.queue = [];
        this.token = config.appConfig.MIXPANEL_API_TOKEN;
        this.userId = null;
        this.platform = 'web app';
        this.identify(this.userId);
        this.ready = true;
        this._flush();
    }

    track(name, props) {
        this.queue.push({
            name,
            props,
        });
        this._flush();
    }

    identify(userId) {
        this.userId = userId;
    }

    reset() {
        this.identify(this.clientId);
    }

    people_set(props) {
        this._people('set', props);
    }

    people_set_once(props) {
        this._people('set_once', props);
    }

    people_unset(props) {
        this._people('unset', props);
    }

    people_increment(props) {
        this._people('add', props);
    }

    people_append(props) {
        this._people('append', props);
    }

    people_union(props) {
        this._people('union', props);
    }

    people_delete_user() {
        this._people('delete', '');
    }

    _flush() {
        if (this.ready) {
            while (this.queue.length) {
                const event = this.queue.pop();
                this._pushEvent(event).then(() => (event.sent = true));
            }
        }
    }

    _people(operation, props) {
        if (this.userId) {
            const data = {
                $token: this.token,
                $distinct_id: this.userId,
            };
            data[`$${operation}`] = props;

            this._pushProfile(data);
        }
    }

    _pushEvent(event) {
        let data = {
            event: event.name,
            properties: event.props,
        };
        const userInfo = auth.getAuthInfo();
        let user = userInfo && userInfo.employee ? userInfo.employee : userInfo.dependent;
        data = this._getBaseAnalyticsData(event.name, {
            $distinct_id: user.flipt_person_id,
            $token: config.appConfig.MIXPANEL_API_TOKEN,
        });
        data.properties = {
            ...data.properties,
            ...event.props,
        };
        data = new Buffer(JSON.stringify(data)).toString('base64');
        return fetch(`${config.appConfig.MIXPANEL_API_URL}/track/?data=${data}`);
    }

    _pushProfile(data) {
        data = new Buffer(JSON.stringify(data)).toString('base64');
        return fetch(`${config.appConfig.MIXPANEL_API_URL}/engage/?data=${data}`);
    }

    _getBaseAnalyticsData(eventName, { $distinct_id, $token }) {
        var OSName = 'Unknown OS';
        if (window.navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
        if (window.navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
        if (window.navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
        if (window.navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';

        const info = [window.navigator.appVersion, window.navigator.platform || null];

        return {
            event: eventName,
            properties: {
                app_version: config.appConfig.version,
                device_info: [...info],
                distinct_id: $distinct_id,
                platform: {
                    os: OSName,
                    height: window.innerHeight,
                    width: window.innerWidth,
                },
                token: $token,
                ts: Date.now(),
            },
        };
    }
}
