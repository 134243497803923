import React, { Suspense, useEffect } from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import apolloClient from './app/services/apollo';

import configureStore, { history } from './app/redux/store/configureStore';
import App from './app/components/App';

import smoothScrollPolyfill from 'smoothscroll-polyfill';

import i18n from '@fliptrx/translations';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import { appConfig } from './app/config';

smoothScrollPolyfill.polyfill();
window.__forceSmoothScrollPolyfill__ = true;

const store = configureStore();
const BootstrapedElement = document.getElementById('root');

/* 
  The translation module is loading async, and we are behind
  in something that is causing it not to work with suspense.
  This will get fixed in the future.
*/
const WaitForTranslations = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    i18n(appConfig.API_URL).then(ready => setIsReady(ready));
  }, [i18n])
  
  return (
    <>
      {isReady ? children : null}
    </>
  )

}
// Make sure the base app is wrapped in translations
const WrappedApp = withTranslation()(App);
render(
  <WaitForTranslations>
    <Suspense fallback={'laoding'}>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ConnectedRouter history={history}>
          <WrappedApp />
        </ConnectedRouter>
      </ApolloProvider>
    </Provider>
    </Suspense>
  </WaitForTranslations>,
  BootstrapedElement
)
