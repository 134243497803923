import React from 'react';

export const REGEXP = new RegExp(
    [
        '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
        '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])',
        '|(([a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð:\\-0-9]+\\.)',
        '+[a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð:]{2,}))$',
    ].join('')
);

export const PASSWORD_RESET = "PASSWORD_RESET";
export const isInfoShowOnInit = false;
export const WEB_APP = 'Web App';
export const MOBILE_APP = 'Mobile App';
export const CLAIMS_PROCESSOR = 'claims_processor';
export const PA = 'PA';
export const FILLED = 'Filled';
export const NUMBER_OF_FILLED_BEFORE_FEEDBACK = 3;
export const NUMBER_OF_PENDING_BEFORE_FEEDBACK = 1; // Should be one less than wanted. Action happens before DB updates.
