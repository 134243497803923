import React from 'react';
import { Button, Modal, Checkbox, Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

import CloseIcon from '../../style/images/Icons/closeIcon';
import * as commonFunctions from '../../utilities/commonFunctions';

class ActivateDependent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentDependentName: '',
            currentDependentPersonCode: '',
            currentDependentRelationship: '',
            currentDependentToActivate: '',
            email: '',
            emailErrorMessage: '',
            emailFormFieldClasses: ['form-group'],
            errors: [],
            fromPage: '',
            messageBody: '',
            modalDefaultView: 'add-dependent',
            modalIsOpen: props.isOpen,
            showConfirmNewPasswordError: false,
            showEmailError: false,
            showFormErrorMessage: false,
            showSuccessMessage: false,
            totalDependentsToActivate: 0,
            userDetailOpen: false,
            userNicename: '',
        };
    }

    componentDidMount() {
        const { enterActivateDependent } = this.props;
        enterActivateDependent();

        // IF THE COMPONENT IS RENDERED FROM ACCOUNT PAGE TO ACTIVATE THE DEPENDENT WHEN "ACTIVATE DEPENDENT" LINK IS CLICKED, THEN SKIP CHECKING FOR VALID DEPENDENT DATA RETRIEVED FROM LOCAL STORAGE
        if ('fromPage' in this.props && this.props.fromPage == 'accountPage') {
            const currentDependent = this.props.currentDependent;

            this.setState({
                fromPage: this.props.fromPage,
                modalDefaultView: 'activate-dependent',
                currentDependentRelationship: currentDependent.relationship,
                currentDependentName:
                    currentDependent.first_name + ' ' + currentDependent.last_name,
                currentDependentPersonCode: currentDependent.person_code,
            });
        } else {
            //If no dependent data found, prevent the user access to this screen and redirect to home
            // if (this.checkDependentDataForActivation() == false) {
            this.setState({
                modalIsOpen: false,
                userDetailOpen: true,
            });
            // }
        }
    }

    componentWillUnmount() {
        const { leaveActivateDependent } = this.props;
        leaveActivateDependent();
    }

    onUserDetailClose = () => {
        const { setUserLogout } = this.props;
        setUserLogout();
        this.props.history.replace('/login');
    };

    getDependentDataFromLocalStorage = () => {
        const { userInfo } = this.props;
        //check if user has any dependent
        if (
            'employee' in userInfo &&
            userInfo.employee !== null &&
            'dependents' in userInfo.employee &&
            userInfo.employee.dependents !== null
        ) {
            return userInfo.employee.dependents;
        } else {
            return null;
        }
    };

    checkDependentDataForActivation = () => {
        let totalDependentsToActivate = 0;
        let dependents = this.getDependentDataFromLocalStorage();

        let ret = false;

        if (dependents != null) {
            dependents.forEach(function (dependent) {
                if (dependent.email == null || commonFunctions.isEmpty(dependent.email)) {
                    totalDependentsToActivate++;
                }
            });
        }

        if (totalDependentsToActivate > 0) {
            ret = true;

            const { userInfo } = this.props;
            let userNicename = userInfo.employee.first_name + ' ' + userInfo.employee.last_name;
            let currentDependentRelationship = userInfo.employee.dependents[0].relationship;
            let currentDependentName =
                userInfo.employee.dependents[0].first_name +
                ' ' +
                userInfo.employee.dependents[0].last_name;
            let currentDependentPersonCode = userInfo.employee.dependents[0].person_code;

            this.setState({
                userNicename: userNicename,
                totalDependentsToActivate: totalDependentsToActivate,
                currentDependentToActivate: 0,
                currentDependentRelationship: currentDependentRelationship,
                currentDependentName: currentDependentName,
                currentDependentPersonCode: currentDependentPersonCode,
            });
        }
        return ret;
    };

    handleSkipLinkClick = (changeModalDefaultViewTo) => {
        if (this.state.fromPage == 'accountPage') {
            this.setState({
                modalIsOpen: false,
            });
            const { activationFinished } = this.props;
            activationFinished('refetchData');
        } else {
            let {
                totalDependentsToActivate,
                currentDependentToActivate,
                currentDependentRelationship,
                currentDependentName,
                currentDependentPersonCode,
            } = this.state;
            const { userInfo } = this.props;

            //Check if there are any more dependents to activate, if not then redirect to home
            if (currentDependentToActivate < totalDependentsToActivate - 1) {
                //increment current dependent index
                currentDependentToActivate++;
                currentDependentRelationship =
                    userInfo.employee.dependents[currentDependentToActivate].relationship;
                currentDependentName =
                    userInfo.employee.dependents[currentDependentToActivate].first_name +
                    ' ' +
                    userInfo.employee.dependents[currentDependentToActivate].last_name;
                currentDependentPersonCode =
                    userInfo.employee.dependents[currentDependentToActivate].person_code;

                this.setState({
                    modalDefaultView: changeModalDefaultViewTo,
                    currentDependentToActivate: currentDependentToActivate,
                    currentDependentRelationship: currentDependentRelationship,
                    currentDependentName: currentDependentName,
                    currentDependentPersonCode: currentDependentPersonCode,
                });
            } else {
                this.setState({
                    userDetailOpen: true,
                });
                // this.props.history.replace('/home');
            }
        }
    };

    handleChange = (event) => {
        event.preventDefault();
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    validateEmail = () => {
        let { email } = this.state;
        const { t } = this.props;
        const dependentData = this.getDependentDataFromLocalStorage();

        let hasError = false,
            errorMessage;

        if (!email || !email.replace(/\s+/g,"").length || commonFunctions.isEmpty(email)) {
            hasError = true;
            errorMessage = t('formErrors.emailEmpty');
        } else if (!commonFunctions.validateEmail(email)) {
            hasError = true;
            errorMessage = t('Please enter a valid email address');
        } /* else if ((dependentData != null) && (dependentData.email != email)) {
      hasError = true;
      errorMessage = t('formErrors.dependentEmailInvalid');
    } */

        let { emailFormFieldClasses } = this.state;

        //If the field has error
        if (hasError) {
            emailFormFieldClasses = commonFunctions.addNewElementToArray(
                emailFormFieldClasses,
                'error'
            );
            emailFormFieldClasses = commonFunctions.removeElementFromArray(
                emailFormFieldClasses,
                'success'
            );
        } else {
            emailFormFieldClasses = commonFunctions.addNewElementToArray(
                emailFormFieldClasses,
                'success'
            );
            emailFormFieldClasses = commonFunctions.removeElementFromArray(
                emailFormFieldClasses,
                'error'
            );
        }

        this.setState({
            showEmailError: hasError === true ? true : false,
            emailErrorMessage: errorMessage,
            emailFormFieldClasses: emailFormFieldClasses,
        });
        return hasError === true ? false : true;
    };

    handleFormSubmit = async (event) => {
        const { activateDependentMutation, t } = this.props;
        const { email, currentDependentPersonCode } = this.state;

        if (!this.validateEmail()) return;

        const variables = {
            email: email,
            person_code: currentDependentPersonCode,
        };

        Promise.all([activateDependentMutation({ variables })])
            .then((response) => {
                //Change the modal default view
                this.setState({
                    modalDefaultView: 'activate-success',
                    showFormErrorMessage: false,
                    messageBody: '',
                });
                //reset email form field classes and error messages if any
                this.resetActivateDependentModalView();
            })
            .catch((err) => {
                let errorMsg = commonFunctions.parseGraphQLErrorMessage(err, t);

                // IF THE DEPENDENT ACCOUNT WITH THE SPECIFIED EMAIL ALREADY EXISTS
                if (errorMsg.toLowerCase().indexOf('external_key conflict') !== -1) {
                    errorMsg = t('activateDependent.dependentEmailAlreadyRegistered');
                }

                this.setState({
                    showFormErrorMessage: true,
                    messageBody: errorMsg,
                });
            });
    };

    handleOnModalClose = (redirectToPath) => {
        const { setUserLogout } = this.props;
        if (this.state.fromPage == 'accountPage') {
            this.setState({
                modalIsOpen: false,
            });
            const { activationFinished } = this.props;
            activationFinished();
        } else {
            this.setState({
                userDetailOpen: true,
            });
            //redirect user to login
            // this.props.history.replace(redirectToPath);
        }
    };

    handleClickAddADependentButton = () => {
        this.setState({
            modalDefaultView: 'activate-dependent',
        });
    };

    render() {
        const {
            email,
            emailFormFieldClasses,
            showEmailError,
            emailErrorMessage,
            showFormErrorMessage,
            messageBody,
            showSuccessMessage,
            userDetailOpen,
        } = this.state;
        const {
            userNicename,
            totalDependentsToActivate,
            modalDefaultView,
            currentDependentToActivate,
            currentDependentRelationship,
            currentDependentName,
            modalIsOpen,
        } = this.state;
        const { mutationLoading, error, t, i18n } = this.props;

        if (userDetailOpen) {
            return (
                <Modal
                    open={userDetailOpen}
                    onClose={this.onUserDetailClose}
                    className="instruction-modal saved-prescription modal-data"
                    closeIcon={
                        <i className="close">
                            <CloseIcon />
                        </i>
                    }
                >
                    <Modal.Header>
                        <h3></h3>
                    </Modal.Header>
                    <Modal.Content>
                        <div>
                            <p>{t('email_activation_notification')}</p>
                            {/* <p>{t('activateDependent.thankYou')}</p> */}
                        </div>
                        <center>
                            <button
                                type="button"
                                className="btn ui button"
                                title={t('CONFIRM')}
                                onClick={() => this.onUserDetailClose()}
                            >
                                {t('okButton')}
                            </button>
                        </center>
                    </Modal.Content>
                </Modal>
            );
        }

        return (
            <Modal
                open={modalIsOpen}
                onClose={() => this.handleOnModalClose('/home')}
                className={
                    modalDefaultView == 'activate-dependent' ||
                    modalDefaultView == 'activate-success'
                        ? 'modal-data'
                        : 'skip-modal modal-data'
                }
                closeIcon={
                    <i className="close">
                        <CloseIcon />
                    </i>
                }
            >
                <Modal.Header>
                    {(() => {
                        switch (modalDefaultView) {
                            case 'add-dependent':
                                return (
                                    <h3>
                                        {t('activateDependent.userGreeting',{userNicename:userNicename}) }
                                    </h3>
                                );
                            case 'activate-dependent':
                                return <h3>{t('activateDependent.mainHeading')}</h3>;
                            case 'activate-success':
                                return <h3>{t('activateDependent.mainHeading')}</h3>;
                        }
                    })()}
                </Modal.Header>

                {(() => {
                    switch (modalDefaultView) {
                        case 'add-dependent':
                            return (
                                <Modal.Content>
                                    <p>
                                        {t('activateDependent.addDependentContent',{dependentRelationship:currentDependentRelationship})
                                            }
                                    </p>
                                    <div className="clearfix form-wrap">
                                        <Button
                                            className="btn ui button"
                                            onClick={this.handleClickAddADependentButton}
                                            title={t('activateDependent.addDependentButtonLabel')}
                                            id="addDependentBtn"
                                        >
                                            {t('activateDependent.addDependentButtonLabel')}
                                        </Button>
                                    </div>
                                </Modal.Content>
                            );

                        case 'activate-dependent':
                            return (
                                <Modal.Content>
                                    <p>
                                        {t('activateDependent.subHeading',{dependentName:currentDependentName})}
                                    </p>

                                    <Form
                                        onSubmit={this.handleFormSubmit}
                                        id="activateDependentForm"
                                    >
                                        <Form.Field
                                            className={emailFormFieldClasses.join(' ')}
                                            id="emailFormField"
                                        >
                                            <input
                                                type="text"
                                                id="email"
                                                name="email"
                                                placeholder={t('Email Address')}
                                                value={email}
                                                onChange={this.handleChange}
                                                onBlur={this.validateEmail}
                                                className="form-control"
                                            />

                                            <i className="check">
                                                <svg
                                                    width="14px"
                                                    height="11px"
                                                    viewBox="0 0 14 11"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                >
                                                    <g
                                                        id="Page-1"
                                                        stroke="none"
                                                        strokeWidth={1}
                                                        fill="none"
                                                        fillRule="evenodd"
                                                    >
                                                        <g
                                                            id="check"
                                                            transform="translate(0.000000, -1.000000)"
                                                            fill="#A6E50F"
                                                        >
                                                            <path
                                                                d="M12.845,3.878 L12.845,3.878 L11.178,5.57 L11.178,5.57 L6.178,10.647 L6.178,10.647 C5.877,10.953 5.46,11.142 5,11.142 C4.54,11.142 4.123,10.953 3.821,10.647 L3.821,10.647 L0.488,7.262 L0.488,7.262 C0.186,6.956 0,6.533 0,6.066 C0,5.131 0.746,4.373 1.666,4.373 C2.127,4.373 2.543,4.562 2.845,4.869 L2.845,4.869 L5,7.057 L8.821,3.176 L10.488,1.484 L10.488,1.484 C10.789,1.178 11.206,0.989 11.666,0.989 C12.587,0.989 13.333,1.747 13.333,2.681 C13.333,3.148 13.147,3.571 12.845,3.878 L12.845,3.878 Z"
                                                                id="Shape"
                                                            />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </i>

                                            {showEmailError && <em>{emailErrorMessage}</em>}
                                        </Form.Field>

                                        {showFormErrorMessage && (
                                            <div className="form-group error">
                                                <em>{messageBody}</em>
                                            </div>
                                        )}

                                        {showSuccessMessage && (
                                            <em style={{ color: 'green' }}>{messageBody}</em>
                                        )}

                                        <Form.Field className="clearfix form-wrap">
                                            <Button
                                                type="submit"
                                                title={t('submit')}
                                                disabled={mutationLoading}
                                                loading={mutationLoading}
                                                className="btn"
                                            >
                                                {t('submit')}
                                            </Button>
                                        </Form.Field>
                                    </Form>
                                </Modal.Content>
                            );
                        case 'activate-success':
                            return (
                                <Modal.Content>
                                    <h4>
                                        {t('activateDependent.depAcctActivated', {dependentName:currentDependentName})}
                                    </h4>
                                    <p>{t('activateDependent.depAcctActivatedContent')}</p>
                                    <div className="clearfix form-wrap">
                                        <Button
                                            className="btn ui button"
                                            title={t('okButton')}
                                            onClick={() =>
                                                this.handleSkipLinkClick('add-dependent')
                                            }
                                        >
                                            {t('okButton')}
                                        </Button>
                                    </div>
                                </Modal.Content>
                            );
                    }
                })()}

                <Modal.Actions>
                    {modalDefaultView == 'add-dependent' ? (
                        <a
                            href="javascript:void(0)"
                            onClick={() => this.handleOnModalClose('/home')}
                            className="skip-link"
                            title={t('skipLinkLabel')}
                        >
                            {t('skipLinkLabel')}
                        </a>
                    ) : (
                        ''
                    )}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withTranslation('translations')(ActivateDependent);
