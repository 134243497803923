import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { FeedbackStateEnum } from './Feedback.state';
import RoundedHeader from './RoundedHeader';
import { FeedbackBody, FeedbackBodyText } from './styles';

const FliptFeedbackStep1 = ({ nextStep, close }) => {
  return (
    <>
      <RoundedHeader headerText="Tell us about your experience" onPress={close} />
      <FeedbackBody>
        <FeedbackBodyText>
          Do you find that the Flipt app is useful towards managing your prescription benefits?
        </FeedbackBodyText>
        <Modal.Actions>
          <Button onClick={() => nextStep(FeedbackStateEnum.YES)}> Yes </Button>
          <Button onClick={() => nextStep(FeedbackStateEnum.NO)}> No </Button>
        </Modal.Actions>
      </FeedbackBody>
    </>
  );
};

export default FliptFeedbackStep1;
