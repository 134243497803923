// CDN Images
import DOWN_ARROW_ICON from './arrow-down.svg';
import FILTER_ICON from './filter.svg';
import MAXIMIZE_ICON from './maximize.svg';
import MINIMIZE_ICON from './minimize.svg';
import CARD_LOGO from './card.png';
import RETAIL_ICON from './retail1.png';
import MAIL_ORDER_ICON from './mailorder1.png';
import PENDING_ICON from './pending1.png';
import PHONE_GRAY_ICON from './phone.svg';
import DELETE_ICON from './delete.svg';
import WARNING_ICON from './alert-triangle.svg';
import DUAL_ARROW_ICON from './dual-side-arrow.svg';

export {
    CARD_LOGO,
    DOWN_ARROW_ICON,
    DELETE_ICON,
    DUAL_ARROW_ICON,
    FILTER_ICON,
    MAIL_ORDER_ICON,
    MINIMIZE_ICON,
    MAXIMIZE_ICON,
    PENDING_ICON,
    PHONE_GRAY_ICON,
    RETAIL_ICON,
    WARNING_ICON,
};

const IMAGE_PATH = 'https://cdn-dev.fliptrx.com/images';
export const FORM_BOTTLE = `${IMAGE_PATH}/BOTTLE WITH SCREW TOP.png`;
export const FORM_CAPSULE = `${IMAGE_PATH}/CAPSULES LOOSE FORM.png`;
export const FORM_CREAM = `${IMAGE_PATH}/TUBE.png`;
export const FORM_OINTMENT = `${IMAGE_PATH}/TUBE.png`;
export const FORM_DEFAULT = `${IMAGE_PATH}/DEFAULT.png`;
export const FORM_FOAM = `${IMAGE_PATH}/PUMP BOTTLE.png`;
export const FORM_INHALER = `${IMAGE_PATH}/INHALER.png`;
export const FORM_INJECTOR_PEN = `${IMAGE_PATH}/INJECTOR PEN.png`;
export const FORM_POWDER = `${IMAGE_PATH}/PACKET LOOSE FORM.png`;
export const FORM_SHAMPOO = `${IMAGE_PATH}/PUMP BOTTLE.png`;
export const FORM_SYRINGE = `${IMAGE_PATH}/SYRINGE.png`;
export const FORM_TABLET = `${IMAGE_PATH}/TABLETS LOOSE FORM.png`;
export const FORM_TUBE = `${IMAGE_PATH}/TUBE.png`;
export const FORM_VIAL = `${IMAGE_PATH}/VIAL.png`;
