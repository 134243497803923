import domain from './domain';
import prescription from './prescription';
import rxPlanMaster from './rxPlanMaster';

export default `
  avatar
  cc_admin
  claimed
  city
  work_location
  communication_email
  communication_option_email
  communication_option_phone
  credit_cards {
    create_date
    cc_token
    exp_date
    nickname
  }
  do_not_prompt_credit_card
  date_of_birth
  deductible_date
  deductible_remaining
  family_deductible_remaining
  family_out_of_pocket_remaining
  individual_deductible_remaining
  individual_out_of_pocket_remaining
  out_of_pocket_remaining
  relationship
  medicare_eligible
  eligibility {
    active
    benefit_plan_name
    cobra_effective_date
    cobra_termination_date
    coverage_effective_date
    coverage_termination_date
    coverage_tier_name
    display_plan_name
    plan_year
  }
  dependents {
    avatar
    claimed
    date_of_birth
    deductible_remaining
    dependent_ssn
    email
    family_deductible_remaining
    family_out_of_pocket_remaining
    first_name
    flipt_person_id
    group
    humana_allergies
    humana_health_conditions
    UID
    individual_deductible_remaining
    individual_out_of_pocket_remaining
    last_name
    member_id
    tpa_member_id
    out_of_pocket_remaining
    person_code
    private_account
    family_access
    relationship
    user_id
    eligibility {
      plan_year
      benefit_plan_name
      coverage_effective_date
      coverage_termination_date
      coverage_tier_name
      cobra_effective_date
      cobra_termination_date
      display_plan_name
    }
  }
  ${domain}
  first_name
  flipt_person_id
  has_logged
  home_address_1
  home_address_2
  UID
  language
  last_name
  locations {
    city
    formatted_address
    geolocation {
      lat
      lng
    }
    mail_order_shipto_default_address
    name
    number
    place_id
    state
    street_address
    street_address_2
    zip_code
  }
  member_id
  tpa_member_id
  payment_option
  person_code
  personal_email
  personal_phone
  personal_phones {
    phone_number
    verified
    date_added
    date_verified
    preferred
  }
  preferred_pharmacies
  prescription_basket
  reward_option
  rxPlanMaster {
    ${rxPlanMaster}
  }
  state
  zip
  rewards_earned_lifetime
  rewards_earned_current_year
  rewards_redeemed_lifetime
  user_id
  private_account
  family_access
  humana_health_conditions
  humana_allergies
  group
  firstData {
    payeeRecordId
    nickName
    emailAddress
    firstName
    lastName
  }
  prescriptions {
    ${prescription}
  }
`;
