import client from '../../services/apollo';
import moment from 'moment';
import { auth } from '../../services/auth';
import * as userActions from '../modules/user';
import * as prescriptionsActions from '../modules/Prescription/prescriptions';
import * as commonFunctions from '../../utilities/commonFunctions';
import { registerUser, trackEvent } from '../../utilities/mixPanel';
 
import deleteLocationQuery from '../../gql/mutations/deleteLocation.js';
import updateLocationQuery from '../../gql/mutations/updateLocation.js';
import config from '../../../config.json';
import {appConfig} from "../../config";
import cc from '@fliptrx/cc'

const dateFormat = 'DD/MM/YYYY HH:mm';

const CHECK_IS_USER_IS_AUTHENTICATED = 'CHECK_IS_USER_IS_AUTHENTICATED';

const RECEIVED_USER_LOGGED_IN = 'RECEIVED_USER_LOGGED_IN';
const ERROR_USER_LOGGED_IN = 'ERROR_USER_LOGGED_IN';
const SEEN_ELIGIBILITY_MODAL = 'SEEN_ELIGIBILITY_MODAL';
const SET_LOADING_LOGGED_IN = 'SET_LOADING_LOGGED_IN';
const UNSET_LOADING_LOGGED_IN = 'UNSET_LOADING_LOGGED_IN';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
const SET_USER_LOGOUT = 'SET_USER_LOGOUT';
const RESET_LOG_ERRORS = 'RESET_LOG_ERRORS';

const emptyUser = {
    id: '',
    username: '',
    lastLogin: '',
    createdAt: '',
    modifiedAt: '',
    userInfo: '',
};

const initialState = {
    isAuthenticated: false,
    lastActionTime: null,
    mutationLoading: false,
    errors: null,
    ...emptyUser,
    isUserEligible: false,
    hasSeenEligiblityModal: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RECEIVED_USER_LOGGED_IN:
            return {
                ...state,
                lastActionTime: action.time,
                isAuthenticated: action.isAuthenticated,
                id: action.user.id,
                userInfo: action.user,
                username: action.user.username,
                lastLogin: action.user.lastLogin,
                createdAt: action.user.createdAt,
                modifiedAt: action.user.modifiedAt,
                lastRefreshTime: action.time,
                error: null,
                isUserEligible: action.isUserEligible,
                hasSeenEligiblityModal: action.hasSeenEligiblityModal,
            };
        case ERROR_USER_LOGGED_IN:
            return {
                ...state,
                lastActionTime: action.time,
                isAuthenticated: action.isAuthenticated,
                error: { ...action.error },
                id: initialState.id,
                username: initialState.username,
                lastLogin: initialState.lastLogin,
                createdAt: initialState.createdAt,
                modifiedAt: initialState.modifiedAt,
            };

        case SET_LOADING_LOGGED_IN:
        case UNSET_LOADING_LOGGED_IN:
            return {
                ...state,
                lastActionTime: action.time,
                mutationLoading: action.loading,
            };

        case CHECK_IS_USER_IS_AUTHENTICATED:
            return {
                ...state,
                lastActionTime: action.time,
                isAuthenticated: action.isAuthenticated,
                // user infos from storage if authenticated:
                id: action.user.id,
                userInfo: action.user,
                username: action.user.username,
                lastLogin: action.user.lastLogin,
                createdAt: action.user.createdAt,
                modifiedAt: action.user.modifiedAt,
            };

        case UPDATE_USER_DATA:
            return {
                ...state,
                userInfo: action.userInfo,
            };
        case SET_USER_LOGOUT:
            return {
                ...state,
                lastActionTime: action.time,
                isAuthenticated: action.isAuthenticated,
                id: action.user.id,
                username: action.user.username,
                lastLogin: action.user.lastLogin,
                createdAt: action.user.createdAt,
                modifiedAt: action.user.modifiedAt,
                userInfo: action.userInfo,
            };

        case SEEN_ELIGIBILITY_MODAL:
            return {
                ...state,
                hasSeenEligiblityModal: action.hasSeenEligiblityModal,
            };

        case RESET_LOG_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
}
// #endregion

// #region  action creators

// //////////////////
// login sucess:
// //////////////////
export function receivedUserLoggedIn(
    userToken = null,
    user = emptyUser,
    userInfo,
    time = moment().format(dateFormat)
) {
    const isAuthenticated = userToken ? true : false;
    const { eligibility, flipt_person_id } = user.employee ? user.employee : user.dependent;
    const nextEligibleDate =
        eligibility && eligibility.length > 0
            ? commonFunctions.getCoverageNextActive(eligibility)
            : null;
    const isUserEligible =
        eligibility && eligibility.length > 0
            ? moment(nextEligibleDate.coverage_effective_date) < moment() &&
              moment(nextEligibleDate.coverage_termination_date) > moment()
            : null;
    user.displayRewards = user.employee
        ? user.employee.domain.display_rewards
        : user.dependent.domain.display_rewards;
    const userData = user.employee || user.dependent;
    const isAdmin = userData.cc_admin && userData.cc_admin?.toLowerCase() === 'yes';
    if (!isAdmin) auth.clearAllAppStorage(); // clear previous token
    auth.setToken(userToken, isAdmin);
    auth.setUserInfo(user, isAdmin);
    localStorage.setItem('visited', JSON.stringify([]));
    cc.init({apiUrl:appConfig.API_URL ,authToken:auth.getToken()});
    getUserAvatar({ user });

    let data = {
        event: 'Authenticated',
        flipt_person_id,
    };
    registerUser({ user });
    trackEvent(data);
    return (dispatch) => {
        dispatch({
            type: RECEIVED_USER_LOGGED_IN,
            time,
            isAuthenticated,
            user,
            userInfo,
            isUserEligible: isUserEligible,
            hasSeenEligiblityModal: isUserEligible,
        });
    };
}

// //////////////////
// login error:
// //////////////////
export function errorUserLoggedIn(error = null, time = moment().format(dateFormat)) {
    auth.clearAllAppStorage(); // clear previous token

    return {
        type: ERROR_USER_LOGGED_IN,
        time,
        error,
        isAuthenticated: false,
    };
}

export function updateUserData(userInfo) {
    if (!userInfo){
        return {
            type: UPDATE_USER_DATA,
            userInfo: emptyUser,
        };
    }
    userInfo.displayRewards = userInfo.employee
        ? userInfo.employee.domain.display_rewards
        : userInfo.dependent.domain.display_rewards;
    return {
        type: UPDATE_USER_DATA,
        userInfo,
    };
}

export function seenEligiblityModal(hasSeenEligiblityModal) {
    localStorage.setItem('hasSeenEligibilityModal', hasSeenEligiblityModal);
    return {
        type: SEEN_ELIGIBILITY_MODAL,
        hasSeenEligiblityModal,
    };
}
// /////////////////////////////
// set loading state for login
// /////////////////////////////
export function setLoadingStateForUserLogin(time = moment().format(dateFormat)) {
    return {
        type: SET_LOADING_LOGGED_IN,
        time,
        loading: true,
    };
}
// /////////////////////////////
// unset loading state for login
// /////////////////////////////
export function unsetLoadingStateForUserLogin(time = moment().format(dateFormat)) {
    return {
        type: UNSET_LOADING_LOGGED_IN,
        time,
        loading: false,
    };
}
// //////////////////
// user logout:
// //////////////////
export function setUserLogout(time = moment().format(dateFormat)) {
    return (dispatch, getState) => {
        auth.clearAllAppStorage();
        client.resetStore();
        dispatch({
            type: SET_USER_LOGOUT,
            time,
            isAuthenticated: false,
            user: emptyUser,
            userInfo: emptyUser,
        });
        dispatch({ type: userActions.SET_USER_EMPTY_STATE });
        dispatch({ type: prescriptionsActions.SET_PRESCRIPTIONS_EMPTY_STATE });
    };
}
// //////////////////////////////
// check user auth (check token)
// //////////////////////////////
export function checkIfUserIsAuthenticated(time = moment().format(dateFormat)) {
    return async (dispatch, getState) => {
        //const user = auth.etUserInfo() ? auth.etUserInfo() : emptyUser;
        //const userInfo = auth.etUserInfo() ? auth.etUserInfo() : emptyUser;
        // commonFunctions.showPageLoader();
        try {
            const user = await commonFunctions.getUserInfo();
            const isAuthenticated = auth.isAuthenticated() && checkUserHasId(user) ? true : false;

            if (isAuthenticated) {
                getUserAvatar({ user });
                dispatch({
                    type: CHECK_IS_USER_IS_AUTHENTICATED,
                    time,
                    isAuthenticated: isAuthenticated,
                    user,
                    userInfo: user,
                });
            } else {
                dispatch({
                    type: CHECK_IS_USER_IS_AUTHENTICATED,
                    time,
                    isAuthenticated: false,
                    user: emptyUser,
                    userInfo: emptyUser,
                });
            }


        } catch (err) {
            console.log({ err });
            // let errorMsg = commonFunctions.parseGraphQLErrorMessage(err, t);
            dispatch({
                type: CHECK_IS_USER_IS_AUTHENTICATED,
                time,
                isAuthenticated: false,
                user: emptyUser,
                userInfo: emptyUser,
            });
        }
    };
}

function checkUserHasId(user) {
    return user && user.id && user.id.length > 0;
}

// ////////////////////////////////
// reset login and register error:
// ////////////////////////////////
export function resetLogError() {
    return {
        type: RESET_LOG_ERRORS,
    };
}

export function updateUserLocations(variables) {
    return async (dispatch) => {
        let updatedUserWithLocations = await updateLocations(variables);
        let { response, success } = updatedUserWithLocations;
        if (success) dispatch(updateUserData(response));
        return updatedUserWithLocations;
    };
}

async function updateLocations({ variables }) {
    try {
        let response = await client.mutate({
            variables,
            mutation: updateLocationQuery,
        });

        return {
            response: response.data.updateLocation,
            success: true,
        };
    } catch (e) {
        console.log(e);
        return {
            response: [],
            success: false,
            errorMessage: e,
        };
    }
}

export function deleteUserLocations(variables) {
    return async (dispatch) => {
        let updatedUserWithLocations = await deleteLocation(variables);
        let { response, success } = updatedUserWithLocations;
        if (success) dispatch(updateUserData(response));
        return updatedUserWithLocations;
    };
}

export async function deleteLocation({ variables }) {
    try {
        let response = await client.mutate({
            variables,
            mutation: deleteLocationQuery,
        });

        return {
            response: response.data.deleteLocation,
            success: true,
        };
    } catch (e) {
        console.log(e);
        return {
            response: [],
            success: false,
            errorMessage: e,
        };
    }
}

export const getUserAvatar = async ({ user }) => {
    // If authenticated check for family avatars availability in app and download from truevault vault if some is not present
    const authToken = auth.getToken();
    const familyAvatars = [];
    const userProps = user.employee ? user.employee : user.dependent;
    const userPropsFamilyArray = [userProps].concat(userProps.dependents);

    userPropsFamilyArray.forEach((dep) => {
        dep.avatar ? familyAvatars.push(dep.flipt_person_id) : null;
    });

    if (familyAvatars.length > 0) {
        const fetchFamilyAvatarsPromise = familyAvatars.map(async (avatar) => {
            
            return fetch(
                `${appConfig.API_URL}/routes/avatar?avatar=${avatar}`,
                {
                    headers: {
                        Authorization: `Basic ${authToken}`,
                    },
                }
            )
                .then((response) => {
                    if(response.status === 200){
                        return response.blob();
                    }
                    else{
                        return null;
                    }
                })
                .then((blob) => {
                    if(blob){
                        localStorage.setItem(avatar, URL.createObjectURL(blob));
                    }
                })
                .catch((error) => {
                    console.log('fetching avatar error', error);
                    dispatch({
                        type: CHECK_IS_USER_IS_AUTHENTICATED,
                        time,
                        isAuthenticated: isAuthenticated,
                        user,
                        userInfo: user,
                    });
                });
        });
        await Promise.all(fetchFamilyAvatarsPromise);
    }
};
