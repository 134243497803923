import React from 'react';
import client from '../../services/apollo';
import { Link } from 'react-router-dom';
import * as userActions from '../../redux/modules/user';
import * as viewsActions from '../../redux/modules/views';
import * as prescriptionsActions from '../../redux/modules/Prescription/prescriptions';
import PageLoader from '../../components/pageLoader/PageLoader';
import Avatar from '../../components/Avatar';
import ActivateDependent from '../../components/activateDependent';
import MainRoutes from '../../routes/MainRoutes';
import LeftNav from './leftNav';
import { connect } from 'react-redux';
import { setUserLogout } from '../../redux/modules/userAuth';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { trackEvent } from '../../utilities/mixPanel';
import * as commonFunctions from '../../utilities/commonFunctions';
import * as newAnalyticsActions from '../../redux/modules/Analytics/newAnalytics';
import * as analyticsActions from '../../redux/modules/Analytics/analytics';
import getNotifications from '../../gql/queries/getNotifications.gql';
import auth from '../../services/auth';
import { AdminLink } from './styles';

{
    /* HAMBURGER MENU AFTER LOGIN */
}
const toggleMenu = () => {
    if (document.body.classList.contains('collapsed-menu')) {
        document.body.classList.remove('collapsed-menu');
        if (window.innerWidth >= 768) {
            document.getElementById('currentUserName').style.display = 'block';
        }
    } else {
        document.body.classList.add('collapsed-menu');
        if (window.innerWidth >= 768) {
            document.getElementById('currentUserName').style.display = 'none';
        }
    }
};

{
    /* ACCOUNT POPUP IMAGES */
}
const userSVG = require('../../style/images/user.svg');
const userHSVG = require('../../style/images/user-h.svg');
const settingSVG = require('../../style/images/setting.svg');
const settingHSVG = require('../../style/images/setting-h.svg');
const policySVG = require('../../style/images/policy.svg');
const policyHSVG = require('../../style/images/policy-h.svg');
const phoneSVG = require('../../style/images/phone.svg');
const phoneHSVG = require('../../style/images/phone-h.svg');

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.state = {
            accountLinks: [
                {
                    label: t('accountPopup.myAccountLink'),
                    link: '/account',
                    image: userSVG,
                    himage: userHSVG,
                },
                {
                    label: t('Settings'),
                    link: '/settings',
                    image: settingSVG,
                    himage: settingHSVG,
                },
                {
                    label: commonFunctions.initCap(t('CONTACT US')),
                    link: '/contact',
                    image: policySVG,
                    himage: policyHSVG,
                },
                {
                    label: t('Privacy Policy'),
                    link: '/privacy-policy',
                    image: phoneSVG,
                    himage: phoneHSVG,
                },
            ],
            allowAccountMenuToShow: true,
            isClickCall: false,
            breadCrumbLabel: {
                'insurance-card': t('leftMenuLinks.insuranceCardBreadCrumb'),
                prescriptions: t('userHome.prescriptionTab'),
                rewards: t('userHome.rewardsTab'),
                account: t('accountPopup.myAccountLink'),
            },
            getallNotification: [],
        };
    }

    // ACCOUNT POPUP LINKS RENDERING
    renderAccountPopupLinks() {
        return this.state.accountLinks.map((aLinkBtn, index) => {
            return (
                <li className="list-content" key={index}>
                    <Link to={aLinkBtn.link} title={aLinkBtn.label}>
                        <i>
                            <img src={aLinkBtn.image} alt={aLinkBtn.label} className="normal" />
                            <img src={aLinkBtn.himage} alt={aLinkBtn.label} className="hover" />
                        </i>
                        {aLinkBtn.label}
                    </Link>
                </li>
            );
        });
    }

    componentDidMount() {
        if (this.props.userIsAuthenticated) {
            const { getUserBasket, getUserRxHistory } = this.props;
            //getUserRxHistory();
            //getUserBasket();

            client
                .query({
                    query: getNotifications,
                    fetchPolicy: 'network-only',
                })
                .then((response) => {
                    this.setState({ getallNotification: response.data.getNotifications });
                })
                .catch(() => {
                    this.props.setUserLogout();
                });
        }
    }

    // CART POPUP CLICK
    cartIconClicked() {
        const {
            history,
            setCartScreenToDisplay,
            setConfigurationWorkflow,
            setGoToCart,
            setGoToConfigurationFlow,
        } = this.props;
        if (!this.props.isFromCart) {
            if (this.props.basket.length === 0) {
                if (document.getElementById('headerCartPopup')) {
                    if (document.getElementById('headerCartPopup').style.display == 'block') {
                        document.getElementById('headerCartPopup').style.display = 'none';
                    } else {
                        document.getElementById('headerCartPopup').style.display = 'block';
                        if (document.getElementById('account-popup-data')) {
                            if (
                                document.getElementById('account-popup-data').style.display ==
                                'block'
                            ) {
                                document.getElementById('account-popup-data').style.display =
                                    'none';
                            }
                        }
                    }
                }
            } else {
                history.push('/home');
                setConfigurationWorkflow('traditional');
                setGoToConfigurationFlow(false);
                setGoToCart(true);
                setCartScreenToDisplay('cartScreen');
            }
        }
    }

    // Set Call Us Number
    setNumber = () => {
        this.setState({ isClickCall: !this.state.isClickCall });
    };

    // ACCOUNT POPUP CLICK
    accountIconClicked() {
        if (document.getElementById('account-popup-data')) {
            if (
                document.getElementById('account-popup-data') &&
                document.getElementById('account-popup-data').style.display == 'block'
            ) {
                document.getElementById('account-popup-data').style.display = 'none';
            } else {
                document.getElementById('account-popup-data').style.display = 'block';
                if (
                    document.getElementById('headerCartPopup') &&
                    document.getElementById('headerCartPopup').style.display == 'block'
                ) {
                    document.getElementById('headerCartPopup').style.display = 'none';
                }
            }
        }
    }

    // CLICK ON CONTENT AREA TO MAKE CART POPUP OR ACCOUNT POPUP CLOSE
    handleClick = (e) => {
        if (window.innerWidth < 768) {
            if (document.body.classList.contains('collapsed-menu')) {
                document.body.classList.remove('collapsed-menu');
            }
        }
        if (document.getElementById('headerCartPopup')) {
            document.getElementById('headerCartPopup').style.display = 'none';
        }

        if (document.getElementById('account-popup-data')) {
            document.getElementById('account-popup-data').style.display = 'none';
        }
    };

    setAccountLinkInfo = (info) => {
        const {
            cartPrescriptions,
            setCurrentAccountTab,
            setIsFromCart,
            setIsFromRefill,
            setIsFromSearch,
            setIsFromSearchPharmacy,
            setIsShowAllUserRxHistory,
            userInfo,
        } = this.props;
        const { flipt_person_id } = userInfo.employee || userInfo.dependent;
        const { name } = info;

        setCurrentAccountTab(info);
        setIsFromSearchPharmacy(false);
        setIsFromRefill(false, []);
        setIsFromCart(false, cartPrescriptions);
        setIsFromSearch(false);
        setIsShowAllUserRxHistory(false);

        let data = {
            event: 'Page',
            eventProps: {
                routeName: name,
            },
            flipt_person_id,
        };
        trackEvent(data);
    };

    render() {
        const {
            userIsAuthenticated,
            userEmail,
            t,
            basket,
            currentView,
            isImpersonateUser,
            setUserLogout,
            userInfo,
        } = this.props;
        const actvieClssSet = (currView,currLabel) => {
            if (currLabel=== 'Claim Reimbursement'){
                return "clearfix";
            }else {
                return !currView.indexOf(currentView) ? 'clearfix active' : 'clearfix';
            }
        };
        const hamburgerImage = require('../../style/images/hamburger.svg');
        const logoImage = require('../../style/images/logo.png');
        const logoClass = userIsAuthenticated ? 'inner-logo' : 'logo';
        const unReadMessage = _.filter(this.state.getallNotification, function (notification) {
            if (notification.status == 'New') {
                return notification;
            }
        }).length;
        let firstName;
        let lastName;
        let contactPhone;

        if (userInfo && (userInfo !== '' || userInfo !== '' || userInfo !== undefined)) {
            if (
                (userInfo.employee &&
                    (userInfo.employee !== '' ||
                        userInfo.employee !== '' ||
                        userInfo.employee !== undefined)) ||
                (userInfo.dependent &&
                    (userInfo.dependent !== '' ||
                        userInfo.dependent !== '' ||
                        userInfo.dependent !== undefined))
            ) {
                firstName = userInfo.employee
                    ? userInfo.employee.first_name
                    : userInfo.dependent.first_name;
                lastName = userInfo.employee
                    ? userInfo.employee.last_name
                    : userInfo.dependent.last_name;
                contactPhone = userInfo.employee
                    ? userInfo.employee.domain.phone
                    : userInfo.dependent.domain.phone;
            }
        }

        // HEADER FLIPT LOGO AND HAMBURGER SECTION
        const leftHeaderTemplate = (
            <div className="ui left floated header">
                <a
                    href="javascript:void(0)"
                    title={t('navigation.hamburgerTitle')}
                    className="mobile-menu"
                    onClick={() => {
                        toggleMenu();
                    }}
                >
                    <img src={hamburgerImage} alt="menu" className="ui image mobile only grid" />
                </a>
                <a
                    href="javascript:void(0)"
                    title={t('navigation.siteTitle')}
                    className={logoClass}
                >
                    <img src={logoImage} alt={'inner-logo'} />
                </a>
            </div>
        );

        // AFTER LOGIN RIGHT HEADER SEACTION WITH ACCOUNT POPUP AND BASKET
        const afterLoginTemplate = (
            <ul className="clearfix">
                <li>
                    <a
                        href="javascript:void(0)"
                        onClick={this.setNumber}
                        title="call-us"
                        className="call-us"
                        style={{ padding: '8px 0px 0px 0px' }}
                    >
                        <i>
                            <img
                                src={require('../../style/images/phone-icon2.svg')}
                                alt="call-us"
                            />
                        </i>
                        &nbsp;
                    </a>
                    {!this.state.isClickCall ? (
                        <a
                            href="javascript:void(0)"
                            onClick={this.setNumber}
                            title="call-us"
                            className="call-us"
                            style={{ paddingLeft: '0px' }}
                        >
                            {t('Call Us')}
                        </a>
                    ) : (
                        <a
                            href="javascript:void(0)"
                            onClick={this.setNumber}
                            title="call-us"
                            className="call-us"
                            style={{ paddingLeft: '0px' }}
                        >
                            {contactPhone}
                        </a>
                    )}
                </li>
                <li className="shopping-cart">
                    <a
                        href="javascript:void(0)"
                        title={t('links.cart')}
                        onClick={() => {
                            this.cartIconClicked();
                        }}
                    >
                        <i>
                            <img src={require('../../style/images/shopping-bag.svg')} alt="cart" />
                            <em className="cart-count">{basket.length}</em>
                        </i>
                    </a>
                </li>
                {this.props.isAdmin && (
                    <li>
                        <AdminLink
                            title="Go to admin page"
                            to={"/admin"}
                        >
                            {t("Admin")}
                        </AdminLink>
                    </li>
                )}
                <li>
                    <a
                        href="javascript:void(0)"
                        title={userEmail}
                        className="profile-block"
                        onClick={() => {
                            this.accountIconClicked();
                        }}
                    >
                        <Avatar user={userInfo.employee || userInfo.dependent} />
                        &nbsp;
                        <span>
                            {firstName} {lastName}
                        </span>
                    </a>
                </li>
                {this.state.allowAccountMenuToShow && (
                    <div className={'dropdown-wrapper active'} id={'account-popup-data'}>
                        <div className="ui fluid vertical tabular menu">
                            <Link
                                className="item"
                                to={'/account'}
                                title={'My Account info'}
                                onClick={() =>
                                    this.setAccountLinkInfo({ index: 0, name: 'AccountInfo' })
                                }
                            >
                                <div className="name-block-wrapper">
                                    <Avatar user={userInfo.employee || userInfo.dependent} />
                                    <div className="name-block">
                                        <span>{t('My Account info')}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link
                                className="item"
                                to={'/account'}
                                title={'My Dependents'}
                                onClick={() =>
                                    this.setAccountLinkInfo({ index: 1, name: 'Dependents' })
                                }
                            >
                                <i>
                                    <img src={require('../../style/images/dependts.svg')} />
                                </i>
                                <div className="name-block">
                                    <span>{t('My Dependents')}</span>
                                </div>
                            </Link>
                            <Link
                                className="item"
                                to={'/account'}
                                title={'My Insurance Plan'}
                                onClick={() =>
                                    this.setAccountLinkInfo({ index: 2, name: 'InsurancePlan' })
                                }
                            >
                                <i>
                                    <img src={require('../../style/images/insurance.svg')} />
                                </i>
                                <div className="name-block">
                                    <span>{t('My Insurance Plan')}</span>
                                </div>
                            </Link>
                            <Link
                                className="item"
                                to={'/account'}
                                title={'Messages'}
                                onClick={() =>
                                    this.setAccountLinkInfo({ index: 4, name: 'Notifications' })
                                }
                            >
                                <i>
                                    <img src={require('../../style/images/email_icon.svg')} />
                                    {unReadMessage > 0 && (
                                        <span className="messageCount-dropdown">
                                            {unReadMessage}
                                        </span>
                                    )}
                                </i>
                                <div className="name-block">
                                    <span>{t('Messages')}</span>
                                </div>
                            </Link>
                            <Link
                                className="item"
                                to={'/account'}
                                title={'Get help'}
                                onClick={() => this.setAccountLinkInfo({ index: 5, name: 'Help' })}
                            >
                                <i>
                                    <img src={require('../../style/images/contact-help.svg')} />
                                </i>
                                <div className="name-block">
                                    <span>{t('Get help')}</span>
                                </div>
                            </Link>
                            <Link
                                className="item"
                                to={'/account'}
                                title={'Address'}
                                onClick={() =>
                                    this.setAccountLinkInfo({ index: 7, name: 'Addresses' })
                                }
                            >
                                <i>
                                    <img src={require('../../style/images/address.svg')} />
                                </i>
                                <div className="name-block">
                                    <span>{t('Addresses')}</span>
                                </div>
                            </Link>
                            <Link
                                className="item"
                                to={'/account'}
                                title={'Settings'}
                                onClick={() =>
                                    this.setAccountLinkInfo({ index: 8, name: 'Settings' })
                                }
                            >
                                <i>
                                    <img src={require('../../style/images/settings.svg')} />
                                </i>
                                <div className="name-block">
                                    <span>{t('Settings')}</span>
                                </div>
                            </Link>
                            <Link
                                className="item"
                                to={'/'}
                                title={'Logout'}
                                onClick={setUserLogout}
                            >
                                <span style={{ color: 'var(--home_menu_dropdown_text_logout)' }}>{t('Logout')}</span>
                            </Link>
                        </div>
                    </div>
                )}
            </ul>
        );
        var userHistoryPath = this.props.history.location.pathname.split('/')[1];
        return (
            <div id="appContainer">
                {userIsAuthenticated && (
                    <header className="ui clearing segment inner-header clearfix">
                        {leftHeaderTemplate}
                        <span className="breadcrumb">
                            {userHistoryPath === 'search' ? (
                                <div>
                                    <a href="javascript:void(0)" className="defaulthome">
                                        {t('leftMenuLinks.home')}
                                    </a>
                                </div>
                            ) : (
                                <div>
                                    <Link to={'/home'} className="home">
                                        <span>{t('leftMenuLinks.home')}</span>
                                    </Link>
                                    <span className="pageName">
                                        {t(this.state.breadCrumbLabel[userHistoryPath])}
                                    </span>
                                </div>
                            )}
                        </span>
                        <div className="ui right floated header clearfix">{afterLoginTemplate}</div>
                    </header>
                )}
                <PageLoader />
                {userIsAuthenticated ? (
                    <main>
                        <LeftNav
                            activClass={actvieClssSet}
                            signOut={setUserLogout}
                            isImpersonateUser={isImpersonateUser}
                            {...this.props}
                        />
                        <div className="dashboard-content" onClick={this.handleClick}>
                            {this.props.location.pathname == '/activate-dependent' ? (
                                <ActivateDependent isOpen={true} {...this.props} />
                            ) : (
                                <MainRoutes />
                            )}
                        </div>
                    </main>
                ) : (
                    <div className="wrapper">
                        <main>
                            <MainRoutes />
                        </main>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state: state,
        currentView: state.views.currentView,
        userMessages: state.user.userMessages,
        isAdmin: auth.isAdmin(),
        isImpersonateUser: state.user.isImpersonateUser,
        isFromCart: state.prescriptions.isFromCart,
        basket: state.user.basket,
        cartPrescriptions: state.prescriptions.cartPrescriptions,
        userMessages: state.user.userMessages,
        pendingPrescriptions: state.prescriptions.pendingPrescriptions,
        cartPrescriptions: state.prescriptions.cartPrescriptions,
        userInfo: state.userAuth.userInfo,
        userFullName: state.userAuth.userInfo.dependent
            ? state.userAuth.userInfo.dependent.first_name +
            ' ' +
            state.userAuth.userInfo.dependent.last_name
            : state.userAuth.userInfo.employee &&
            state.userAuth.userInfo.employee.first_name +
            ' ' +
            state.userAuth.userInfo.employee.last_name,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            setUserLogout,
            ...viewsActions,
            ...prescriptionsActions,
            ...userActions,
            ...newAnalyticsActions,
            ...analyticsActions,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translations')(NavigationBar));
