import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'semantic-ui-react';
import * as appActions from '../../../redux/modules/App/app.state';
import { FeedbackStateEnum } from './components/Feedback.state';
import FliptFeedbackStep1 from './components/FliptFeedback.step1';
import FliptFeedbackStep3 from './components/FliptFeedback.step3';
import FliptFeedbackStep4 from './components/FliptFeedback.step4';

const FliptFeedback = ({ actions, showFeedback }) => {
  const [step, setStep] = useState(FeedbackStateEnum.START);
  const nextStep = (step) => {
    setStep(step);
  };
  const close = () => {
    setStep(FeedbackStateEnum.START);
    if (showFeedback) {
      actions.toggleUserFeedback();
    }
  };

  // select if we should show
  return (
    <Modal size="small" open={showFeedback}>
      {step === FeedbackStateEnum.START ? (
        <FliptFeedbackStep1 nextStep={nextStep} close={close} />
      ) : null}
      {step === FeedbackStateEnum.YES ? (
        <FliptFeedbackStep4 nextStep={nextStep} close={close} />
      ) : null}
      {step === FeedbackStateEnum.NO ? (
        <FliptFeedbackStep3
          submitUserFeedback={actions.submitUserFeedback}
          nextStep={nextStep}
          close={close}
        />
      ) : null}
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  showFeedback: state?.app?.userFeedback?.display,
});
function mapDispatchToProps(dispatch) {
  const allActions = {
    ...appActions,
  };

  return {
    actions: bindActionCreators(allActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FliptFeedback);
