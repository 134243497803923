import React, { useEffect, useState, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavigationBar from '../../components/navigation/NavigationBar';
import { checkIfUserIsAuthenticated } from '../../redux/modules/userAuth';
//import i18n from '@fliptrx/translations';
import '../../style/color.css';
import '../../style/font-awesome.min.css';
import '../../style/reset.css';
import '../../style/font.css';
import '../../style/owl.carousel.min.css';
import '../../style/jquery.mCustomScrollbar.min.css';
import 'semantic-ui-css/semantic.css';
import '../../style/style.css';
import '../../style/media.css';
import '../../style/custom.css';

import 'jquery';
import 'semantic-ui-css/semantic.js';
import '../../js/jquery.nicescroll.min.js';
import '../../js/general';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import 'swiper/css/pagination';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary.component';
import ErrorBoundaryComponent from '../../components/ErrorBoundary/ErrorBoundary.component';
import ErrorModal from '../ErrorModal';
import { reloadOtherTabsOnAdminLogin, setStorageListener, removeStorageListener } from '../admin/util';
import FliptFeedback from '../../shared/components/FliptFeedback';

function App(props) {
  const [loaded, setLoaded] = useState(false);

  useEffect(async () => {
    setStorageListener(reloadOtherTabsOnAdminLogin);
    const {
      actions: { checkIfUserIsAuthenticated },
    } = props;
    await checkIfUserIsAuthenticated();
    setLoaded(true);
    return () => removeStorageListener(reloadOtherTabsOnAdminLogin);
  }, [])

    const { userIsAuthenticated, userEmail, history, tReady } = props;
    const cartItemCount = props.userBasketCount;
    if (!loaded) return <div />
    return (
      <ErrorBoundaryComponent history={history}>
        <Suspense fallback={<> {'loading'}</>}>
          <ErrorModal />
          <FliptFeedback />
        <NavigationBar
          userIsAuthenticated={userIsAuthenticated}
          cartItems={cartItemCount}
          history={history}
          userEmail={userEmail}
          {...props} />
          </Suspense>
      </ErrorBoundaryComponent>
    );
}

const mapStateToProps = (state) => {
  return {
    userIsAuthenticated: state.userAuth.isAuthenticated,
    userBasketCount: state.user.basketCount,
    userEmail: state.userAuth.username,
    pendingPrescriptions: state.prescriptions.pendingPrescriptions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        checkIfUserIsAuthenticated,
      },
      dispatch
    ),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
