import userDetails from './userDetails';

export default `
  access_token
  id
  user_id
  result
  username
  employee {
    work_email
    employee_ssn
    admin
    ${userDetails}
  }
  dependent {
    email
    parent_id
    dependent_ssn
    ${userDetails}
  }`;
