import gql from 'graphql-tag';
import userProps from '../templates/userProps';

export default gql`mutation(
  $payment_option: String
  $private_account: Boolean
  $reward_option: String
  $communication_option: String
  $personal_phones: [PhoneNumberInput]
  $personal_email: [String]
  $communication_option_phone: String
  $communication_option_email: String
  $preferred_pharmacies: [String]
  $language: String
  $flipt_person_id: String
  $humana_health_conditions: [String]
  $humana_allergies: [String]
  $family_access: Boolean
  $do_not_prompt_credit_card: Boolean
) {
  updateUser(
    payment_option: $payment_option
    private_account: $private_account
    reward_option: $reward_option
    communication_option: $communication_option
    personal_phones: $personal_phones
    personal_email: $personal_email
    communication_option_phone: $communication_option_phone
    communication_option_email: $communication_option_email
    preferred_pharmacies: $preferred_pharmacies
    language: $language
    flipt_person_id: $flipt_person_id
    humana_health_conditions: $humana_health_conditions 
    humana_allergies: $humana_allergies
    family_access: $family_access
    do_not_prompt_credit_card: $do_not_prompt_credit_card
  ) {
  ${userProps} 
  }
}
`;
