// @flow

// #region imports
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { from } from 'apollo-link';
import fetch from 'unfetch';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { appConfig } from '../../config';
import { auth } from '../auth';
// #endregion

// #region link, middleware
const { networkInterface: uri } = appConfig.apollo;
const httplink = createHttpLink({
    uri,
    fetch: fetch,
});

// #region get user token from localStorage
let token; // cached token to access localStorage just once
async function getUserToken() {
    if (token) {
        return token;
    }

    return new Promise((resolve, reject) => {
        const storedToken = auth.getToken();
        resolve(storedToken);
    });
}

async function getAdminToken() {
    return new Promise((resolve, reject) => {
        const storedToken = auth.getAdminToken();
        resolve(storedToken);
    });
}
// #endregion

const adminActions =[
    'adminLogin',
    'getDomains',
]

const isAdminAction = (operation) => {
    try {
        for (let action of adminActions) {
            if (operation?.query?.loc?.source?.body?.includes(action)) {
                return true;
            }
        }
        return false;
    } catch (err) {
        return false;
    }
}

const authMiddleware = setContext(async (operation, { headers }) => {
    const adminAction = isAdminAction(operation);
    const isAdmin = auth.isAdmin();
    const currentUsertoken = adminAction && isAdmin ? await getAdminToken() : await getUserToken();
    const authorization = currentUsertoken ? `Basic ${currentUsertoken}` : null;
    return {
        headers: {
            ...headers,
            authorization,
        },
    };
});
// #endregion

// #region cache
const cache = new InMemoryCache();
// #endregion

// #region afterware (lanage token expiration for exmaple)
const errorLink = onError(({ graphQLErrors, networkError }) => {
    console.log('error afterware, graphQLErrors: ', graphQLErrors);
    console.log('error afterware, networkError: ', networkError);

    if (networkError && networkError.statusCode === 401) {
        throw new Error('Unauthorized');
    }
    if (networkError && networkError.statusCode === 504) {
        throw new Error('Server Error');
    }
});
// #endregion

const link = from([authMiddleware, errorLink, httplink]);
// #region apollo client instanciation
const client = new ApolloClient({
    link,
    cache,
    queryDeduplication: true,
});
// #endregion

export default client;
