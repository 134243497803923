export const SET_SELECTED_PHARMACY = 'SET_SELECTED_PHARMACY';

export const emptyState = {
    selectedPharmacy: null,
};

export const initialState = {
    ...emptyState,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SELECTED_PHARMACY:
            return {
                ...state,
                selectedPharmacy: action.setSelectedPharmacy,
            };
        default:
            return state;
    }
}

export function setSelectedPharmacy(selectedPharmacy) {
    return {
        type: SET_SELECTED_PHARMACY,
        selectedPharmacy,
    };
}
