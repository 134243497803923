// @flow

import { combineReducers } from 'redux';
import { ConnectedRouter, connectRouter, routerReducer } from 'connected-react-router';
import app from './App/app.state';
import analytics from './Analytics/analytics';
import cart from './cart';
import newAnalytics from './Analytics/newAnalytics';
import prescriptions from './Prescription/prescriptions';
import user from './user';
import userAuth from './userAuth';
import views from './views';
import digitalId from './digitalId';

const appReducers = {
    app,
    analytics,
    cart,
    newAnalytics,
    prescriptions,
    user,
    userAuth,
    views,
    digitalId,
};

// combine reducers -> createStore reducer
const reducers = (history) => combineReducers({
    router: connectRouter(history), 
    ...appReducers,
   
});

export default reducers;
