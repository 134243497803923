export const ANALYTICS_IDENTIFY = 'ANALYTICS/IDENTIFY';
export const ANALYTICS_TRACK = 'ANALYTICS/TRACK';

export const initialState = {
    distinctId: null,
    event: null,
    properties: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ANALYTICS_IDENTIFY:
            return {
                ...state,
                distinctId: action.distinctId,
            };

        case ANALYTICS_TRACK: {
            return {
                ...state,
                event: action.event,
                properties: action.properties,
            };
        }
        default:
            return state;
    }
};

export function identify(flipt_person_id) {
    return (dispatch) => {
        dispatch({
            type: ANALYTICS_IDENTIFY,
            distinctId: flipt_person_id,
        });
    };
}

export function track(event, properties) {
    return (dispatch) => {
        dispatch({
            type: ANALYTICS_TRACK,
            event: event,
            properties: properties,
        });
    };
}
