export default `
  domain {
    address1
    address2
    allow_all_form
    alternate_calc_using_formulary
    app_wide_message_display
    app_wide_message
    billingaddress1
    billingaddress2
    billingcity
    billingstate
    billingzip
    brand_discount
    can_reward_exceed_copay
    city
    comfort_zone_pharmacies
    companylegalname
    contact_fax
    contact_phone1
    contact_phone2
    contactemail
    contactname
    country
    create_date
    created_by
    custom_walkthrough
    deductible_receipt_message
    device_activation_flag
    display_deductible_receipt_message
    display_dependent_activate_account
    display_family_deductible
    display_flipt_bin_pcn_flag
    display_logo
    display_rewards
    domain
    economy_zone_pharmacies
    email
    employer_penalty_factor
    enable_alternate_penalty
    enable_compulsory_phone_verification
    enable_drug_interaction
    enable_quantity_resctriction
    enable_smart_rewards
    enable_smart_search
    faq_link
    feedback_rewards
    feedback_rx
    flipt_bin
    flipt_pcn
    generic_discount
    how_to_video_link
    hr_email
    hra_tolerance
    kiosk_time_out
    max_reward_per_rx
    max_reward_per_year
    message
    min_reward_amt
    min_split_pharmacy_savings
    mo_dayssupply
    otc_drug_covered
    pa_expiry_limit
    pa_override_threshold
    parentcompany
    payment_options
    pbm_price_display
    pbm_provider
    phone
    quantity_restriction_buffer
    reward_message
    reward_options
    reward_redemption_message
    reward_share
    rewards_baseline_multiplier
    rewards_fixed_amount
    rewards_opc_multiplier
    rx_expiry_days
    rx_mail_expiry_days
    saver_zone_factor
    show_digital_card
    show_explain_flipt_pharmacy
    show_deductible_breakdown_web
    state
    subdivision
    track_mail_order_days
    update_date
    updated_by
    use_observed_pbm_price
    use_pbm_price_for_baseline
    zip
    zone_calculation
  }
`;
