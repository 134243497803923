import React from 'react';

const closeIcon = () => {
    return (
        <svg
            width="12px"
            height="12px"
            viewBox="0 0 12 12"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g
                id="Page-1"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
            >
                <g
                    id="Group"
                    transform="translate(-8.000000, -8.000000)"
                    stroke="#7E7E7E"
                    strokeWidth="1.5"
                >
                    <g id="Close">
                        <path d="M9,18.982684 L18.982684,9" id="Line" />
                        <path
                            d="M9,18.982684 L18.982684,9"
                            id="Line"
                            transform="translate(13.991342, 13.991342) scale(-1, 1) translate(-13.991342, -13.991342) "
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default closeIcon;
