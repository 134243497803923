import React from 'react';
import { withTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';

/*
 * COMMON PAGE LOADER FOR WHOLE APP
 */

class PageLoader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        return (
            <Dimmer active={false} id="pageLoader" style={{ opacity: 0.7, zIndex: 100000 }}>
                <Loader size="medium" style={{ opacity: 1 }}>
                    {t('pageLoaderText')}
                </Loader>
            </Dimmer>
        );
    }
}
export default withTranslation('translations')(PageLoader);
