import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import colors from '../../../../style/colors';
import RoundedHeader from './RoundedHeader';
import React from 'react';
import { Image } from 'semantic-ui-react';

const FliptButtonText = styled.p`
  flex: 1
  align-items: center;
  flex-wrap: wrap;
  text-wrap: wrap;
  font-weight: ${(props) => {
    return props.bold ? 'bold' : 'normal';
  }};
`;
const FliptFeedbackText = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  return (
    <FliptButtonText {...props}>
      {children && typeof children === 'string' && t(children)}
    </FliptButtonText>
  );
};
const FeedbackBody = styled.div`
  padding-bottom: 10%;
  margin-left: 5%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  background-color: ${(props) => props?.theme?.bodyBackgroundColor || colors.white};
`;
const FeedbackBodyText = styled(FliptFeedbackText)`
  flex-wrap: wrap;
  color: ${(props) => props?.theme?.baseTextColor || colors.black};
`;

const FliptFeedbackStep4 = ({ close }) => {
  return (
    <>
      <RoundedHeader onPress={close} headerText="Thank you for your feedback" />
      <FeedbackBody>
        <Image src={require('../../../../style/images/thumbsup.png')} size="tiny" rounded />

        <FeedbackBodyText>
          Thank you! We appreciate your time and will use your feedback to improve our member
          experience
        </FeedbackBodyText>
      </FeedbackBody>
    </>
  );
};
export default FliptFeedbackStep4;
