import styled from '@emotion/styled';
import colors from '../../../../style/colors';
import FliptFeedbackText from './FliptFeedbackText';
import { ModalContent } from 'semantic-ui-react';

export const FeedbackBody = styled(ModalContent)`
  background-color: ${(props) => props?.theme?.bodyBackgroundColor || colors.white};
`;

export const FeedbackBodyText = styled(FliptFeedbackText)`
  background-color: white;
  width: 75%;
  height: 100%;
  flex-wrap: wrap;
  color: ${(props) => props?.theme?.baseTextColor || colors.black};
`;
