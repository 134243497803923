import btoa from 'btoa';
import moment from 'moment';

import { appConfig } from '../config/';
import { newFetchRequest } from './fetchRequest';

export function registerUser({ user }) {
    let {
        date_of_birth,
        domain: { domain },
        flipt_person_id,
        language,
    } = user.employee ? user.employee : user.dependent;

    const analyticsPersonaData = {
        $token: appConfig.MIXPANEL_API_TOKEN,
        $distinct_id: flipt_person_id,
        $set: {
            app_version: `${appConfig.application} ${appConfig.version}`,
            date_of_birth: moment(date_of_birth).format('l'),
            domain,
            flipt_person_id,
            language,
            ts: Date.now(),
        },
    };
    const url = `${appConfig.mixPanel.engage}/?data=${_encodeData(analyticsPersonaData)}&ip=1`;

    _mixPanelGetCall(url);
}

export function trackEvent(data) {
    try {
        const { admin_flipt_person_id, event, eventProps, flipt_person_id, isAdmin } = data;

        if (!event) return;

        const analyticsData = _getBaseAnalyticsData(event, flipt_person_id);

        if (isAdmin) analyticsData.properties.admin_flipt_person_id = admin_flipt_person_id;

        if (eventProps) {
            analyticsData.properties = {
                ...analyticsData.properties,
                ...eventProps,
            };
        }

        const url = `${appConfig.mixPanel.track}/?data=${_encodeData(analyticsData)}&ip=1`;

        _mixPanelGetCall(url);
    } catch (err) {
        console.log('trackEventHandler Error', err);
    }
}

function _mixPanelGetCall(url) {
    newFetchRequest(url);
}

function _encodeData(data) {
    return btoa(JSON.stringify(data));
}

function _getBaseAnalyticsData(eventName, distinct_id) {
    const info = _deviceDetection();

    return {
        event: eventName,
        properties: {
            app_version: `${appConfig.application} ${appConfig.version}`,
            device_info: [...info],
            distinct_id,
            platform: {
                os: info.deviceName,
                height: window.outerHeight,
                width: window.outerWidth,
            },
            flipt_person_id: distinct_id,
            token: appConfig.MIXPANEL_API_TOKEN,
            ts: Date.now(),
        },
    };
}

export function createEventProps(propsDataPayload) {
    const propsData = {};
    const dataKeys = Object.keys(propsDataPayload);

    dataKeys.forEach((k) => {
        const val = k ? encodeURI(propsDataPayload[k]) : null;

        if (!propsData[k]) {
            propsData[k] = [];
        }

        propsData[k].push(val);
    });

    return propsData;
}

function _deviceDetection() {
    let header = [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        window.opera,
    ];
    let dataos = [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
    ];
    let databrowser = [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
    ];
    var agent = header.join(' ');
    var os = _matchItem(agent, dataos);
    var browser = _matchItem(agent, databrowser);

    return [
        os.name,
        os.version,
        browser.name,
        browser.version,
        navigator.userAgent,
        navigator.appVersion,
        navigator.platform,
        navigator.vendor,
    ];
}

function _matchItem(string, data) {
    let i = 0,
        j = 0,
        regex,
        regexv,
        match,
        matches,
        version;

    for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
            matches = string.match(regexv);
            version = '';
            if (matches) {
                if (matches[1]) {
                    matches = matches[1];
                }
            }
            if (matches) {
                matches = matches.split(/[._]+/);
                for (j = 0; j < matches.length; j += 1) {
                    if (j === 0) {
                        version += matches[j] + '.';
                    } else {
                        version += matches[j];
                    }
                }
            } else {
                version = '0';
            }
            return {
                name: data[i].name,
                version: parseFloat(version),
            };
        }
    }
    return { name: 'unknown', version: 0 };
}
