import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './ErrorBoundary.css';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: '', error: '' };
    }
    static getDerivedStateFromError(error) {
        console.error('Application Exception Caught: ', error);
       
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        console.error('Application Exception Caught: ', errorInfo, error);
        this.setState({ errorInfo });
        if(process.env.NODE_ENV === 'production') {
            this.props.history.replace('/login')
            window.location.reload(false);
         }
    }
    render() {
        const { hasError } = this.state;
        const { t } = this.props;
        const DEV = process.env.NODE_ENV !== 'production'
       
        return hasError && DEV ? (
            <div className="error__container">
                <div className="error__box">
                    <h2 className="error__text">{t('APP_CRASH_ERROR')}</h2>
                </div>
            </div>
        ) : (
            this.props.children
        );
    }
}

export default withTranslation('translations')(ErrorBoundary);
