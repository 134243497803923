import client from '../../../services/apollo';
import prescriptionsQuery from '../../../gql/queries/prescriptions.gql';
import getUserRxHistoryQuery from '../../../gql/queries/getUserHistory.gql';
import getDrugInteractions from '../../../gql/queries/rxInteractions.gql';
import { UPDATE_USER_DATA } from '../userAuth';

import { compilePrescriptionToSave } from '../../../components/prescription/configurePrescription/util';
import * as commonFunctions from '../../../utilities/commonFunctions';
import _ from 'lodash';

export const SET_PRESCRIPTIONS = 'Prescription/SET_PRESCRIPTIONS';
export const SET_CART_SCREEN_TO_DISPLAY = 'Prescription/SET_CART_SCREEN_TO_DISPLAY';
export const SET_DRUG_DETAILS = 'Prescription/SET_DRUG_DETAILS';
export const SET_DRUG_NAME_SELECTED = 'Prescription/SET_DRUG_NAME_SELECTED';
export const SET_SAVED_PRESCRIPTIONS = 'Prescription/SET_SAVED_PRESCRIPTIONS';
export const SET_WAITING_PRESCRIPTIONS = 'Prescription/SET_WAITING_PRESCRIPTIONS';
export const SET_PENDING_PRESCRIPTIONS = 'Prescription/SET_PENDING_PRESCRIPTIONS';
export const SET_FILLED_PRESCRIPTIONS = 'Prescription/SET_FILLED_PRESCRIPTIONS';
export const SET_CANCELLED_PRESCRIPTIONS = 'Prescription/SET_CANCELLED_PRESCRIPTIONS';
export const SET_RX_EXPIRY_DAYS_PRESCRIPTIONS = 'Prescription/SET_RX_EXPIRY_DAYS_PRESCRIPTIONS';
export const SET_PRESCRIPTIONS_EMPTY_STATE = 'Prescription/SET_PRESCRIPTIONS_EMPTY_STATE';
export const SET_GO_TO_CART = 'Prescription/SET_GO_TO_CART';
export const SET_GO_TO_CONFIGURATION_FLOW = 'Prescription/SET_GO_TO_CONFIGURATION_FLOW';
export const SET_HOME_PAGE_TAB = 'Prescription/SET_HOME_PAGE_TAB';
export const SET_ISALT = 'Prescription/SET_ISALT';
export const SET_ORIGINALLY_SEARCHED_DRUG = 'Prescription/SET_ORIGINALLY_SEARCHED_DRUG';
export const SET_CONFIGURATION_WORKFLOW = 'Prescription/SET_CONFIGURATION_WORKFLOW';
export const SET_ISFROM_SEARCHPHARMACY = 'Prescription/SET_ISFROM_SEARCHPHARMACY';
export const SET_ISFROM_CART = 'Prescription/SET_ISFROM_CART';
export const SET_ISFROM_SEARCH = 'Prescription/SET_ISFROM_SEARCH';
export const SET_ISFROM_IN_PHARMACY = 'Prescription/SET_ISFROM_IN_PHARMACY';
export const SET_ISFROM_REFILL = 'Prescription/SET_ISFROM_REFILL';
export const SET_ISFROM_PRESCRIPTION = 'Prescription/SET_ISFROM_PRESCRIPTION';
export const SET_ISSHOW_ALL_SAVED_PRESCRIPTIONS = 'Prescription/SET_ISSHOW_ALL_SAVED_PRESCRIPTIONS';
export const SET_ISSHOW_ALL_WAITING_PRESCRIPTIONS =
    'Prescription/SET_ISSHOW_ALL_WAITING_PRESCRIPTIONS';
export const SET_ISSHOW_ALL_PENDING_PRESCRIPTIONS =
    'Prescription/SET_ISSHOW_ALL_PENDING_PRESCRIPTIONS';
export const SET_ISSHOW_ALL_FILLED_PRESCRIPTIONS =
    'Prescription/SET_ISSHOW_ALL_FILLED_PRESCRIPTIONS';
export const SET_ISSHOW_ALL_CANCELLED_PRESCRIPTIONS =
    'Prescription/SET_ISSHOW_ALL_CANCELLED_PRESCRIPTIONS';
export const SET_ISSHOW_ALL_USER_RX_HISTORY = 'Prescription/SET_ISSHOW_ALL_USER_RX_HISTORY';
export const SET_PHARMACY_LIST = 'Prescription/SET_PHARMACY_LIST';
export const SET_TEMPORARY_PRESCRIPTIONS = 'Prescription/SET_TEMPORARY_PRESCRIPTIONS';
export const SET_USER_RX_HISTORY_PRESCRIPTIONS = 'Prescription/SET_USER_RX_HISTORY_PRESCRIPTIONS';
export const SET_CURRENT_EDIT_PRESCRIPTION = 'Prescription/SET_CURRENT_EDIT_PRESCRIPTION';
export const SET_CURRENT_ACCOUNT_TAB = 'Account/SET_CURRENT_ACCOUNT_TAB';
export const SET_REFILL_FROM_HOME = 'Account/SET_REFILL_FROM_HOME';
export const SET_RX_INTERACTIONS = 'Prescription/SET_RX_INTERACTIONS';
export const SCREEN_RENDERED_IN_CONFIGURATION_FLOW = 'SCREEN_RENDERED_IN_CONFIGURATION_FLOW';

export const emptyState = {
    allPrescriptions: [],
    cartEditClicked: false,
    cartPrescriptions: [],
    cartScreenToDisplay: 'cartScreen',
    configurationWorkflow: 'traditional',
    currentAccountTab: null,
    currentEditPrescription: null,
    displayErxPrescriptioId: 0,
    drugDetails: '',
    drugNameSelected: '',
    filledPrescriptions: [],
    goToCart: false,
    goToConfigurationFlow: false,
    homePageTab: 'pendingPrescriptionsTab',
    isAlt: false,
    isFromCart: false,
    isFromInPharmacy: false,
    isFromPrescription: false,
    isFromRefill: false,
    isFromSearch: false,
    isFromSearchPharmacy: false,
    isShowAllFilledPrescriptions: false,
    isShowAllPendingPrescriptions: false,
    isShowAllSavedPrescriptions: false,
    isShowAllUserRxHistory: false,
    isShowAllWaitingPrescriptions: false,
    originallySearchedDrug: null,
    pendingPrescriptions: [],
    pharmacyList: [],
    rxExpiryDays: 0,
    rxInteractions: [],
    screenRenderedInConfigurationFlow: 'drugConfigScreen',
    selectedHomeRefillPrescription: [],
    selectedRefillPrescription: [],
    selectedWaitingPrescription: [],
    temporaryPrescriptions: [],
    userRxHistory: [],
    userRxHistoryCount: 1,
};

export const initialState = {
    ...emptyState,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ISFROM_SEARCH:
            return {
                ...state,
                isFromSearch: action.isFromSearch,
            };
        case SET_ISALT:
            return {
                ...state,
                isAlt: action.isAlt,
            };
        case SET_CART_SCREEN_TO_DISPLAY:
            return {
                ...state,
                cartScreenToDisplay: action.cartScreenToDisplay,
            };
        case SCREEN_RENDERED_IN_CONFIGURATION_FLOW:
            return {
                ...state,
                screenRenderedInConfigurationFlow: action.screenRenderedInConfigurationFlow,
            };
        case SET_ORIGINALLY_SEARCHED_DRUG:
            return {
                ...state,
                originallySearchedDrug: action.originallySearchedDrug,
            };
        case SET_GO_TO_CART:
            return {
                ...state,
                goToCart: action.goToCart,
            };
        case SET_GO_TO_CONFIGURATION_FLOW:
            return {
                ...state,
                goToConfigurationFlow: action.goToConfigurationFlow,
            };
        case SET_CONFIGURATION_WORKFLOW:
            return {
                ...state,
                configurationWorkflow: action.configurationWorkflow,
            };
        case SET_DRUG_DETAILS:
            return {
                ...state,
                drugDetails: action.drugDetails.data.fullDrugDetails.drug_details,
            };
        case SET_DRUG_NAME_SELECTED:
            return {
                ...state,
                drugNameSelected: action.drugNameSelected,
            };
        case SET_ISFROM_IN_PHARMACY:
            return {
                ...state,
                isFromInPharmacy: action.isFromInPharmacy,
                selectedWaitingPrescription: action.selectedWaitingPrescription,
            };
        case SET_ISFROM_REFILL:
            return {
                ...state,
                isFromRefill: action.isFromRefill,
                selectedRefillPrescription: action.selectedRefillPrescription,
            };
        case SET_REFILL_FROM_HOME:
            return {
                ...state,
                selectedHomeRefillPrescription: action.selectedHomeRefillPrescription,
            };
        case SET_ISFROM_PRESCRIPTION:
            return {
                ...state,
                isFromPrescription: action.isFromPrescription,
                displayErxPrescriptioId: action.displayErxPrescriptioId,
            };
        case SET_CURRENT_EDIT_PRESCRIPTION:
            return {
                ...state,
                cartEditClicked: action.cartEditClicked,
                currentEditPrescription: action.currentEditPrescription,
            };
        case SET_PRESCRIPTIONS:
            return {
                ...state,
                allPrescriptions: action.allPrescriptions,
            };
        case SET_HOME_PAGE_TAB:
            return {
                ...state,
                homePageTab: action.homePageTab,
            };
        case SET_PENDING_PRESCRIPTIONS:
            return {
                ...state,
                pendingPrescriptions: action.pendingPrescriptions,
            };
        case SET_FILLED_PRESCRIPTIONS:
            return {
                ...state,
                filledPrescriptions: action.filledPrescriptions,
            };
        case SET_RX_EXPIRY_DAYS_PRESCRIPTIONS:
            return {
                ...state,
                rxExpiryDays: action.rxExpiryDays,
            };
        case SET_ISFROM_SEARCHPHARMACY:
            return {
                ...state,
                isFromSearchPharmacy: action.isFromSearchPharmacy,
            };
        case SET_ISFROM_CART:
            return {
                ...state,
                isFromCart: action.isFromCart,
                cartPrescriptions: action.cartPrescriptions,
            };
        case SET_ISSHOW_ALL_SAVED_PRESCRIPTIONS:
            return {
                ...state,
                isShowAllSavedPrescriptions: action.isShowAllSavedPrescriptions,
            };
        case SET_ISSHOW_ALL_WAITING_PRESCRIPTIONS:
            return {
                ...state,
                isShowAllWaitingPrescriptions: action.isShowAllWaitingPrescriptions,
            };
        case SET_ISSHOW_ALL_PENDING_PRESCRIPTIONS:
            return {
                ...state,
                isShowAllPendingPrescriptions: action.isShowAllPendingPrescriptions,
            };
        case SET_ISSHOW_ALL_FILLED_PRESCRIPTIONS:
            return {
                ...state,
                isShowAllFilledPrescriptions: action.isShowAllFilledPrescriptions,
            };
        case SET_ISSHOW_ALL_USER_RX_HISTORY:
            return {
                ...state,
                isShowAllUserRxHistory: action.isShowAllUserRxHistory,
            };
        case SET_PHARMACY_LIST:
            return {
                ...state,
                pharmacyList: action.pharmacyResult,
            };
        case SET_TEMPORARY_PRESCRIPTIONS:
            return {
                ...state,
                temporaryPrescriptions: action.temporaryPrescriptions,
            };
        case SET_PRESCRIPTIONS_EMPTY_STATE:
            return {
                ...emptyState,
            };
        case SET_USER_RX_HISTORY_PRESCRIPTIONS:
            return {
                ...state,
                userRxHistory: action.userRxHistory,
            };
        case SET_CURRENT_ACCOUNT_TAB:
            return {
                ...state,
                currentAccountTab: action.currentAccountTab,
            };
        case SET_RX_INTERACTIONS:
            return {
                ...state,
                rxInteractions: action.rxInteractions,
            };
        default:
            return state;
    }
}

export function setPharmacyList(pharmacyResult) {
    return {
        type: SET_PHARMACY_LIST,
        pharmacyResult,
    };
}

export function setPrescriptionsEmptyState() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PRESCRIPTIONS_EMPTY_STATE,
        });
    };
}

export function setPrescriptions(allPrescriptions) {
    return {
        type: SET_PRESCRIPTIONS,
        allPrescriptions,
    };
}

export function setCartScreenToDisplay(cartScreenToDisplay) {
    return {
        type: SET_CART_SCREEN_TO_DISPLAY,
        cartScreenToDisplay,
    };
}

export function changeScreenRenderedInConfigFlow(screenRenderedInConfigurationFlow) {
    return {
        type: SCREEN_RENDERED_IN_CONFIGURATION_FLOW,
        screenRenderedInConfigurationFlow,
    };
}

export function setPendingPrescriptions(pendingPrescriptions) {
    return {
        type: SET_PENDING_PRESCRIPTIONS,
        pendingPrescriptions,
    };
}

export function setFilledPrescriptions(filledPrescriptions) {
    return {
        type: SET_FILLED_PRESCRIPTIONS,
        filledPrescriptions,
    };
}

export function setTemporaryPrescriptions(temporaryPrescriptions) {
    return {
        type: SET_TEMPORARY_PRESCRIPTIONS,
        temporaryPrescriptions,
    };
}

export function setRxExpiryDays(rxExpiryDays) {
    return {
        type: SET_RX_EXPIRY_DAYS_PRESCRIPTIONS,
        rxExpiryDays,
    };
}

export function setRxInteractions(rxInteractions) {
    return {
        type: SET_RX_INTERACTIONS,
        rxInteractions,
    };
}

export function setIsFromSearchPharmacy(isFromSearchPharmacy) {
    return {
        type: SET_ISFROM_SEARCHPHARMACY,
        isFromSearchPharmacy,
    };
}

export function setIsFromCart(isFromCart, cartPrescriptions) {
    return {
        type: SET_ISFROM_CART,
        isFromCart,
        cartPrescriptions,
    };
}

export function setIsFromSearch(isFromSearch) {
    return {
        type: SET_ISFROM_SEARCH,
        isFromSearch,
    };
}

export function setConfigurationWorkflow(configurationWorkflow) {
    return {
        type: SET_CONFIGURATION_WORKFLOW,
        configurationWorkflow,
    };
}

export function setHomePageTab(homePageTab) {
    return {
        type: SET_HOME_PAGE_TAB,
        homePageTab,
    };
}

export function setIsAlt(isAlt) {
    return {
        type: SET_ISALT,
        isAlt,
    };
}

export function setGoToCart(goToCart) {
    return {
        type: SET_GO_TO_CART,
        goToCart,
    };
}

export function setGoToConfigurationFlow(goToConfigurationFlow) {
    return {
        type: SET_GO_TO_CONFIGURATION_FLOW,
        goToConfigurationFlow,
    };
}

export function setOriginallySearchedDrug(originallySearchedDrug) {
    return {
        type: SET_ORIGINALLY_SEARCHED_DRUG,
        originallySearchedDrug,
    };
}

export function setDrugDetails(drugDetails) {
    return {
        type: SET_DRUG_DETAILS,
        drugDetails,
    };
}

export function setDrugNameSelected(drugNameSelected) {
    return {
        type: SET_DRUG_NAME_SELECTED,
        drugNameSelected,
    };
}

export function setIsFromInPharmacy(isFromInPharmacy, selectedWaitingPrescription) {
    return {
        type: SET_ISFROM_IN_PHARMACY,
        isFromInPharmacy,
        selectedWaitingPrescription,
    };
}

export function setIsFromRefill(isFromRefill, selectedRefillPrescription) {
    return {
        type: SET_ISFROM_REFILL,
        isFromRefill,
        selectedRefillPrescription,
    };
}

export function setRefillFromHome(selectedHomeRefillPrescription) {
    return {
        type: SET_REFILL_FROM_HOME,
        selectedHomeRefillPrescription,
    };
}

export function setIsFromPrescription(isFromPrescription, displayErxPrescriptioId) {
    return {
        type: SET_ISFROM_PRESCRIPTION,
        isFromPrescription,
        displayErxPrescriptioId,
    };
}

export function setCurrentEditPrescription(cartEditClicked, currentEditPrescription) {
    return {
        type: SET_CURRENT_EDIT_PRESCRIPTION,
        cartEditClicked,
        currentEditPrescription,
    };
}

export function setIsShowAllSavedPrescriptions(isShowAllSavedPrescriptions) {
    return {
        type: SET_ISSHOW_ALL_SAVED_PRESCRIPTIONS,
        isShowAllSavedPrescriptions,
    };
}

export function setIsShowAllWaitingPrescriptions(isShowAllWaitingPrescriptions) {
    return {
        type: SET_ISSHOW_ALL_WAITING_PRESCRIPTIONS,
        isShowAllWaitingPrescriptions,
    };
}

export function setIsShowAllPendingPrescriptions(isShowAllPendingPrescriptions) {
    return {
        type: SET_ISSHOW_ALL_PENDING_PRESCRIPTIONS,
        isShowAllPendingPrescriptions,
    };
}

export function setIsShowAllFilledPrescriptions(isShowAllFilledPrescriptions) {
    return {
        type: SET_ISSHOW_ALL_FILLED_PRESCRIPTIONS,
        isShowAllFilledPrescriptions,
    };
}

export function setIsShowAllUserRxHistory(isShowAllUserRxHistory) {
    return {
        type: SET_ISSHOW_ALL_USER_RX_HISTORY,
        isShowAllUserRxHistory,
    };
}

export function setUserRxHistory(userRxHistory) {
    return {
        type: SET_USER_RX_HISTORY_PRESCRIPTIONS,
        userRxHistory,
    };
}

export function setCurrentAccountTab(currentAccountTab) {
    return {
        type: SET_CURRENT_ACCOUNT_TAB,
        currentAccountTab,
    };
}

const getOrderList = (prescriptionList, type) => {
    type = Array.isArray(type) ? type : [type];
    return prescriptionList
        .filter((prescription) => type.includes(prescription.rx_status))
        .sort((a, b) => new Date(b.create_date).getTime() - new Date(a.create_date).getTime());
};

const fetchRxInteractions = (prescriptions) => {
    const searchStr = JSON.stringify(prescriptions);

    const interactionsQuery = {
        query: getDrugInteractions,
        variables: {
            drugs: searchStr,
        },
        fetchPolicy: 'network-only',
    };
    return new Promise((resolve, reject) => {
        client
            .query(interactionsQuery)
            .then((result) => resolve(result.data.rxInteractions))
            .catch((err) => reject(err));
    });
};

const getRxInteractions = (dispatch, prescriptions) => {
    try {
        if (!prescriptions || !prescriptions.length) return;
        if (prescriptions.length < 2) return;
        const prescriptionQuery = prescriptions.map((prescription) => {
            return {
                drug_name: prescription.drug_name,
                gpi: prescription.gpi,
                flipt_person_id: prescription.rx_flipt_person_id || prescription.flipt_person_id,
            };
        });

        fetchRxInteractions(prescriptionQuery)
            .then((result) => dispatch(setRxInteractions(result)))
            .catch(() => console.log('Failed to load RxInteractions'));
    } catch (error) {
        console.log('Error Fetching RxInteractions', error);
        return;
    }
};

const setPrescriptionProps = (dispatch, user) => {
    if (user.prescriptions) {
        const { prescriptions } = user;
        dispatch(setPrescriptions(prescriptions));
        const pendingPrescriptions = getOrderList(prescriptions, ['Routed', 'PA']);
        getRxInteractions(dispatch, pendingPrescriptions);
        dispatch(setPendingPrescriptions(pendingPrescriptions));
        dispatch(setFilledPrescriptions(getOrderList(prescriptions, 'Filled')));
    }
    if (user.domain) {
        dispatch(setRxExpiryDays(user.domain.rx_expiry_days));
    }
};

export function getPrescriptions(updatedUser) {
    return async (dispatch, getState) => {
        const {
            userAuth: { userInfo: userFromState },
        } = getState();
        const userInfo = updatedUser || userFromState;
        const user = userInfo.dependent || userInfo.employee || userInfo;
        const isEmployee = userInfo.employee || userInfo.employee_ssn || userInfo?.dependents?.length > 0;
        try {
            const family = [user].concat(user?.dependents || []);
            if (isEmployee) {
                user.prescriptions?.map((rx) => {
                    if (
                        (rx.flipt_person_id || rx.rx_flipt_person_id) &&
                        family.some(
                            (x) =>
                                x.flipt_person_id === rx.rx_flipt_person_id ||
                                x.flipt_person_id === rx.flipt_person_id
                        )
                    ) {
                        const patient = family.find(
                            (x) =>
                                x.flipt_person_id === rx.rx_flipt_person_id ||
                                x.flipt_person_id === rx.flipt_person_id
                        );
                        rx.avatar = patient.avatar;
                    }
                    if (!rx.first_name) {
                        rx.first_name = '';
                    }
                    if (!rx.last_name) {
                        rx.last_name = '';
                    }
                    return rx;
                });
                setPrescriptionProps(dispatch, user);
            } else {
                user?.prescriptions?.map((rx) => {
                    if (
                        rx.rx_flipt_person_id &&
                        family.some((x) => x.flipt_person_id === rx.rx_flipt_person_id)
                    ) {
                        const patient = family.find(
                            (x) => x.flipt_person_id === rx.rx_flipt_person_id
                        );
                        rx.avatar = patient.avatar;
                    }
                    if (!rx.first_name) {
                        rx.first_name = '';
                    }
                    if (!rx.last_name) {
                        rx.last_name = '';
                    }
                    return rx;
                });
                setPrescriptionProps(dispatch, user);
            }
            return {
                data: {
                    user: userInfo
                }
            };
        } catch (e) {
            console.log(e);
            return e;
        }
    };
}

export function getUserRxHistory() {
    return async (dispatch, getState) => {
        const { prescriptions } = getState();
        return new Promise((resolve, reject) => {
            client
                .query({
                    query: getUserRxHistoryQuery,
                    fetchPolicy: 'network-only',
                })
                .then((obj) => {
                    let getUserHistory = obj.data.getUserHistory;
                    getUserHistory = getUserHistory.sort(
                        (a, b) =>
                            new Date(b.created_date).getTime() - new Date(a.created_date).getTime()
                    );
                    dispatch(setUserRxHistory(getUserHistory));
                    resolve(getUserHistory);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };
}

export function setPrescriptionsInStateFromLocalStorage() {
    return (dispatch, getState) => {
        // let userInfo = auth.etUserInfo(), user;
        const {
            userAuth: { userInfo },
        } = getState();
        if ('employee' in userInfo && userInfo.employee !== null) {
            user = userInfo.employee;
        } else {
            user = userInfo.dependent;
        }

        setPrescriptionProps(dispatch, user);
    };
}

function getDrugConfigurationFromRefill(refill, drugDetails, defaultLocationForRefill) {
    let {
        brand_generic,
        brandorgeneric,
        ddid,
        dosage_strength,
        drug_name,
        equivalent_brand,
        first_name,
        flipt_person_id,
        rx_flipt_person_id,
        gpi,
        last_name,
        location,
        package_qty,
        package_size,
        quantity,
    } = refill;
    let customQuantity = null;
    let isCustomQuantity = false;
    let formFromRefill =
        refill.form || refill.quantity_type || refill.package_qty.split(' ').slice(-1)[0];
    let form = drugDetails.drug_forms.find((formObj) => formFromRefill === formObj.form);
    if (!form) {
        form = drugDetails.drug_forms.find((formObj) => formFromRefill.includes(formObj.form));
    }
    //Deep Cloning as we don't want to modify the drugDetails data as modifying it directly is causing issue - FD-4567
    let cloneForm = _.cloneDeep(form);
    let formObj = cloneForm.drugs.find((obj) => obj.ddid === ddid || obj.gpi === gpi);
    let dosageQtyObj = formObj.quantity.find(
        (dosageObj) => dosageObj.dosage_strength === dosage_strength
    );
    let locationSplit = location
        ? location.split(', ')
        : defaultLocationForRefill
        ? defaultLocationForRefill.split(', ')
        : '';

    if (!dosageQtyObj) {
        dosageQtyObj = formObj.quantity[0];
        customQuantity = `${Number(package_size || 1) * Number(quantity || 1)} ${formFromRefill}`;
        isCustomQuantity = true;
    } else {
        let dPackage_qty = dosageQtyObj.package_qty.match(/[-+]?[0-9]*\.?[0-9]+/)[0];
        let usrPackage_qty = package_qty.match(/[-+]?[0-9]*\.?[0-9]+/)[0];
        if (parseFloat(dPackage_qty).toFixed(2) !== parseFloat(usrPackage_qty).toFixed(2)) {
            dosageQtyObj.package_qty = package_qty;
            customQuantity = `${package_qty.match(/[-+]?[0-9]*\.?[0-9]+/)[0]} ${formFromRefill}`;
            isCustomQuantity = true;
        }
    }
    /* else if (
        dosageQtyObj.package_qty.match(/[-+]?[0-9]*\.?[0-9]+/)[0] !==
        package_qty.match(/[-+]?[0-9]*\.?[0-9]+/)[0]
    ) {
        dosageQtyObj.package_qty = package_qty;
        customQuantity = `${package_qty.match(/[-+]?[0-9]*\.?[0-9]+/)[0]} ${formFromRefill}`;
        isCustomQuantity = true;
    } */
    dosageQtyObj.gpi = formObj.gpi;

    return {
        customQuantity,
        dosage: dosageQtyObj,
        form: cloneForm,
        isAlt: false,
        isCustomQuantity,
        location: {
            name: '',
            street_address: locationSplit[0],
            city: locationSplit[1],
            state: locationSplit[2],
            zip_code: locationSplit[3],
        },
        name: {
            drug_name: drug_name,
            brand_generic: brand_generic || brandorgeneric,
            equivalent: equivalent_brand || '',
        },
        patient: {
            first_name: first_name,
            last_name: last_name,
            rx_flipt_person_id: rx_flipt_person_id,
            value: flipt_person_id,
            private_account: false,
        },
        quantity: dosageQtyObj,
    };
}

export async function getDetailsForRefillPrescription(rx, defaultLocationForRefill) {
    const drugDetails = await commonFunctions.fullDrugDetails(rx.drug_name);
    let { drug_details } = drugDetails.data.fullDrugDetails;
    let refillDrugDetails = drug_details.find(
        (d) => d.drug_name.toUpperCase() === rx.drug_name.toUpperCase()
    );
    let drugConfigurationToGetPrices = getDrugConfigurationFromRefill(
        rx,
        refillDrugDetails,
        defaultLocationForRefill
    );
    let configuredDrug = await compilePrescriptionToSave(drugConfigurationToGetPrices);
    return {
        first_name: rx.first_name,
        last_name: rx.last_name,
        npi: rx.npi,
        pharmacy_name: rx.pharmacy_name || rx.pharmacy,
        ...configuredDrug,
    };
}
