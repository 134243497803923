export const FETCH_TIMEOUT = 10000;
export default function fetchRequest(url, method = 'GET') {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            reject(new Error('Request timed out'));
        }, FETCH_TIMEOUT);
        let request = new XMLHttpRequest();

        request.onreadystatechange = () => {
            if (request.readyState !== 4) {
                return;
            }
            clearTimeout(timeout);
            if (request.status === 200) {
                resolve(request.responseText);
            } else {
                reject();
            }
        };

        request.open(method, url);
        request.send();
    });
}

export function newFetchRequest(url, params = {}) {
    const parameters = params.body
        ? {
              body: params.body,
              method: params.method || 'GET',
              headers: {
                  'Content-Type': params.contentType || 'application/json; charset=utf-8',
              },
          }
        : {};

    return fetch(url, parameters)
        .then((response) => responseHTMLHandler(response))
        .catch((error) => errorHandler(error));
}

// UTILS
function responseHTMLHandler(resp) {
    return resp.ok;
}

function errorHandler(error) {
    console.error('FETCH ERROR: ', error);
}
