import gql from 'graphql-tag';
import prescription from '../templates/prescription';

export default gql`mutation (
    $admin_flipt_person_id: String
    $alternative_drug_rewards: String
    $application: String
    $brand_generic: String
    $custom_qty: String
    $custom_quantity: Boolean
    $daysofsupply: String
    $days_of_supply: String
    $ddid: String
    $ddn_form: String
    $ddn_name: String
    $ddn_strength: String
    $dosage: String!
    $dosage_image: String
    $dosage_strength: String
    $dpa: String
    $drug: String!
    $drug_class: String
    $drug_covered: String
    $drug_name: String
    $drug_notcovered_can_be_dispensed: String
    $drug_type: String
    $equivalent_brand: String
    $flipt_person_id: String
    $form: String!
    $gpi: String!
    $gppc: String
    $isAlt: Boolean
    $lm_form: String
    $lm_name: String
    $lm_ndc: String
    $lm_strength: String
    $locationSelected: String
    $otc_indicator: String
    $maintenance_drug_flag: String
    $package_desc: String
    $package_qty: String
    $package_quantity: String
    $package_size: String
    $pkg_desc_cd: String
    $pkg_uom: String
    $quantity: String!
    $quantity_type: String
    $rbp: String
    $rbp_penalty: String
    $rbp_reward: String
    $rx_flipt_person_id: String
    $searchedDrugValue: Float
    $searched_days_of_supply: String
    $searched_ddid: String        
    $searched_dosage: String
    $searched_dosage_strength: String
    $searched_drug_cost: String
    $searched_drug_name: String
    $searched_employee_opc: String
    $searched_employer_cost: String
    $searched_gpi: String
    $searched_gppc: String
    $searched_package_quantity: String
    $searched_package_qty: String
    $searched_package_size: String
    $search_location: String
    $specialty_flag: String
    $strengths: String
    $zipCode: String
  ) {
  savePrescription(
    admin_flipt_person_id: $admin_flipt_person_id
    alternative_drug_rewards: $alternative_drug_rewards
    application: $application
    brand_generic: $brand_generic
    custom_qty: $custom_qty
    custom_quantity: $custom_quantity
    daysofsupply: $daysofsupply
    days_of_supply: $days_of_supply
    ddid: $ddid
    ddn_form: $ddn_form
    ddn_name: $ddn_name
    ddn_strength: $ddn_strength
    dosage: $dosage
    dosage_image: $dosage_image
    dosage_strength: $dosage_strength
    dpa: $dpa
    drug: $drug
    drug_class: $drug_class
    drug_covered: $drug_covered
    drug_name: $drug_name
    drug_notcovered_can_be_dispensed: $drug_notcovered_can_be_dispensed
    drug_type: $drug_type
    equivalent_brand: $equivalent_brand
    flipt_person_id: $flipt_person_id
    form: $form
    gpi: $gpi
    gppc: $gppc
    isAlt: $isAlt
    lm_form: $lm_form
    lm_name: $lm_name
    lm_ndc: $lm_ndc
    lm_strength: $lm_strength
    locationSelected: $locationSelected
    maintenance_drug_flag: $maintenance_drug_flag
    otc_indicator: $otc_indicator
    package_desc: $package_desc
    package_qty: $package_qty
    package_quantity: $package_quantity
    package_size: $package_size
    pkg_desc_cd: $pkg_desc_cd
    pkg_uom: $pkg_uom
    quantity: $quantity
    quantity_type: $quantity_type
    rbp: $rbp
    rbp_penalty: $rbp_penalty
    rbp_reward: $rbp_reward
    rx_flipt_person_id: $rx_flipt_person_id
    searchedDrugValue: $searchedDrugValue
    searched_days_of_supply: $searched_days_of_supply
    searched_ddid: $searched_ddid
    searched_dosage: $searched_dosage
    searched_dosage_strength: $searched_dosage_strength
    searched_drug_cost: $searched_drug_cost
    searched_drug_name: $searched_drug_name
    searched_employee_opc: $searched_employee_opc
    searched_employer_cost: $searched_employer_cost
    searched_gpi: $searched_gpi
    searched_gppc: $searched_gppc
    searched_package_quantity: $searched_package_quantity
    searched_package_qty: $searched_package_qty
    searched_package_size: $searched_package_size
    search_location: $search_location
    specialty_flag: $specialty_flag
    strengths: $strengths
    zipCode: $zipCode
  ) {
    ${prescription}
  }
}
`;
