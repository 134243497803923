export default `
  admin_flipt_person_id
  app_web_message
  alternative_drug_rewards
  app_routed_date
  application
  available_fill_date
  baseline_cost
  bin
  brand_generic
  chaincode
  copay_amount
  copay_type
  create_date
  custom_qty
  custom_quantity
  days_of_supply
  daysofsupply
  ddid
  ddn_form
  ddn_name
  ddn_strength
  deductible_balance
  deductible_remaining
  deductible_accumulator_amount_before_savings_adjustment
  discount_card_source
  domain
  dosage
  dosage_image
  dosage_strength
  dpa
  drug
  drug_class
  drug_coinsurance
  drug_copay
  drug_cost
  drug_cost_before_rebate
  drug_covered
  drug_deductible_exempt
  drug_name
  drug_not_covered_and_manufacturer_only_coverage
  drug_notcovered_can_be_dispensed
  drug_penalty
  drug_type
  deductible_accumulator_amount
  deductible_accumulator_amount_before_savings_adjustment
  employee_opc
  employer_cost
  equivalent_brand
  filled_date
  fill_number
  first_name
  flipt_person_id
  form
  gpi
  gppc
  group_id
  grandfathering_member_choice_penalty_waived
  gf_override_used
  hra_transaction_id
  isRefilled
  incentive_credit_applied
  isAlt
  last_name
  lm_form
  lm_name
  lm_ndc
  lm_strength
  location
  locationSelected
  member_id
  member_choice_penalty_amount
  mo_contact_email
  mo_contact_phone
  mo_shipto_location
  mo_shipto_address_label
  npi
  number_of_refills_authorized
  opc_after_savings
  opc_before_savings
  orig_drug_cost
  otc_indicator
  other
  out_of_pocket_remaining
  oop_accumulator_amount
  pa_flag
  pa_form
  pa_override
  pa_reason
  pa_cp_recalculate
  package_desc
  package_qty
  package_quantity
  package_size
  payment_option
  pbm_price
  pcn
  prescriber_npi
  prescriber_first_name
  prescriber_last_name
  prescriber_phone
  penalty
  penalty_factor
  pharmacy
  pharmacy_discount
  pharmacy_dispensing_fee
  pharmacy_phone
  pharmacy_type
  pkg_desc_cd
  pkg_uom
  prescription_basket_id
  prescription_id
  preselected_npi
  preselected_pharmacy
  plan_name
  quantity
  quantity_image
  quantity_restriction_flag
  quantity_type
  rbp
  rbp_penalty
  rbp_reward
  rebate_amount
  rebate_factor
  reconciled
  refill_create_date
  refill_prescription_id
  refills
  retail_reward
  reward_percentage
  reward_share
  rewards
  rewards_after_cost
  routed_date
  app_routed_date
  rx_flipt_person_id
  rx_status
  rxcui
  saved_date
  search_location
  search_prescription_id
  searchedDrugValue
  searched_date
  shared_with_flipt_person_id
  specialty_flag
  strengths
  total_payment
  total_reward
  track_shipment_url
  tracking_status
  unit_price
  unit_price_before_rebate
  update_date
  zipCode
  zone
`;
