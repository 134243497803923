import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Header, Modal } from 'semantic-ui-react';
import * as appActions from '../../redux/modules/App/app.state';
const ErrorModal = (props) => {
  const {hideErrorModal,  isShown} = props;
  const { t } = useTranslation();
  return (
    <Modal basic onClose={() => hideErrorModal()} trigger={null} open={isShown}>
      <Header icon>
        
       {t('You caught us in a bad state')}
      </Header>
      <Modal.Content>
        <p>
          {t("We're experiencing an issue but all should be back to normal soon Try again in a few minutes")}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => hideErrorModal()}>
         {t('OK')}
        </Button>
      </Modal.Actions>
      </Modal>
  )


}

const mapStateToProps = (state) => {
    return {
       isShown: state.app.showErrorModal
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...appActions
        },
        dispatch
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);



