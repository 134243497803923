import moment from 'moment';
import _ from 'lodash';

import Cacher from './cacher';
import client from '../../app/services/apollo';
import drugDetailsQuery from '../gql/queries/drugDetails.gql';
import currentUserDetails from '../gql/queries/currentUserDetails.js';
import drugModalDetails from '../gql/queries/getDrugModalDetails.gql';
import getPbmPricesQuery from '../gql/queries/getPmbPrices.gql';
import getScriptclaimCouponQuery from '../gql/queries/getScriptclaimCoupon.gql';
import getDrugEquivalentsQuery from '../gql/queries/getDrugEquivalents.gql';
import getContractCouponQuery from '../gql/queries/getContractCoupon.gql';
import lastPrescriptionsQuery from '../gql/queries/lastPrescriptions.gql';
import termsConditionQuery from '../gql/queries/termsCondition.gql';
import acceptHIPAAMutation from '../gql/mutations/acceptHIPAA.gql';
import acceptComplianceMutation from '../gql/mutations/acceptCompliance.gql';
import updateUserMutation from '../gql/mutations/updateUser.js';
import changeEmailMutation from '../gql/mutations/changeEmail.gql';
import getRewardAmountsQuery from '../gql/queries/getRewardAmounts.gql';
import getRewardDetailsQuery from '../gql/queries/getRewardDetails.gql';
import getRewardHistoryQuery from '../gql/queries/getRewardHistory.gql';
import currentUserDetailsQuery from '../gql/queries/currentUserDetails.js';
import { auth } from '../services/auth';
import changePassword from '../gql/mutations/changePassword.js';
import redeemRewardsMutation from '../gql/mutations/redeemRewards.gql';
import updateRewardOptionMutation from '../gql/mutations/updateRewardOption.gql';
import updateDeductibleOPC from '../gql/mutations/updateDeductibleOPC.gql';
import * as locationActions from '../utilities/googleMatrix';
import defaultQuery from '../gql/queries/rxInteractionsDefault.gql';
import searchQuery from '../gql/queries/rxInteractions.gql';
import validateQuantityMutation from '../gql/mutations/validateQuantity.gql';
import getAlternativeDrugsQuery from '../gql/queries/getAlternativeDrugs.gql';
import getDaysOfSupplyQuery from '../gql/queries/getDaysOfSupply.gql';
import validatePhoneNumberMutation from '../gql/mutations/validatePhoneNumber.gql';
import createPayeeMutation from '../gql/mutations/createPayee.gql';
import getAccessTokenForRoutingNumber from '../gql/queries/getAccessTokenForRoutingNumber.gql';
import getPharmacyAccumulatorReportsQuery from '../gql/queries/getPharmacyAccumulatorReports.gql';
import getEquivalentsWithDrugDetailsQuery from '../gql/queries/getEquivalentsWithDrugDetails.gql';
import fullDrugDetailsQuery from '../gql/queries/fullDrugDetails.gql';
import * as ASSETS from '../style/images/index';
import { ZIPCODE_USA } from './regex';

export function setAddress(address1, address2 = '', city = '', zip = '') {
    return (
        address1 +
        (address2 != '' ? ', ' + address2 : '') +
        (city != '' ? ', ' + city : '') +
        (zip != '' ? ', ' + zip : '')
    );
}

//Removes the given element from array and returns the new array
export function removeElementFromArray(arr, valueToRemove) {
    let index = arr.indexOf(valueToRemove);
    if (index >= 0) {
        arr.splice(index, 1);
    }
    return arr;
}

//Adds given element to the array if the value does not exist in the array and returns the new array
export function addNewElementToArray(arr, valueToAdd) {
    let index = arr.indexOf(valueToAdd);
    //If element does not exist in the array then add it to the array
    if (index == -1) {
        arr.push(valueToAdd);
    }
    return arr;
}

export const confirmPassword = (password) => {
    const re = /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}))/;
    return re.test(password);
};
export const confirmComplexPassword = (password) => {
    const re = /(^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,}))/;
    return re.test(password);
};

export const validateSsn = (password, length) => {
    const re = /^[0-9]*$/;
    return re.test(password) && password.length === length;
};

export const isMailOrder = (presc) =>
    (presc.drug_distance || '').toLowerCase().includes('mail') ||
    (presc.pharmacy_type || '').toLowerCase().includes('mail');
// Todo: a single key to indentify mail order drug
export const isRetailPharmacy = (presc) => {
    // Retail or Preferred pharmacy
    const pharmacyType = (presc.pharmacy_type || '').toLowerCase();
    return pharmacyType == 'retail' || pharmacyType == 'preferred';
};

export const isValidZipcode = (zipcode) => {
    if (typeof zipcode !== 'string') return false;
    return ZIPCODE_USA.test(zipcode);
};

export const formatPhoneNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';
};
export const formatFaxNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return [match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return '';
};

//Validate the value for a valid email
export function validateEmail(email) {
    const re = new RegExp(
        [
            '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
            '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])',
            '|(([a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð:\\-0-9]+\\.)',
            '+[a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜŸäëïöüŸ¡¿çÇŒœßØøÅåÆæÞþÐð:]{2,}))$',
        ].join('')
    );

    return re.test(email);
}

// Check if the string is empty, return boolean (`true` if field is empty)
export function isEmpty(str) {
    return !str || !str.replace(/^\s+/g, '').length;
}

export function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function parseGraphQLErrorMessage(error, translate, fromPage = null) {
    let errorMessage;
    errorMessage = error.toString().replace('Error: GraphQL error: ', '');
    errorMessage = errorMessage.toString().replace('400 - ', '');
    errorMessage = errorMessage.toString().replace('401 - ', '');

    //If the error message is a valid JSON then, retrieve the error message from the object
    if (IsJsonString(errorMessage)) {
        let errorObj = JSON.parse(errorMessage);
        errorMessage = errorObj.error.message;
    }

    // If the message contains network error
    if (errorMessage.toLowerCase().indexOf('network error') !== -1) {
        //errorMessage = errorMessage.toString().replace('Error: Network error: ', '');
        //Error: Network error: Response not successful: Received status code 400
        errorMessage = translate(
            "We're experiencing an issue but all should be back to normal soon Try again in a few minutes"
        );
    }

    if (
        errorMessage == 'Authentication Failure.' ||
        errorMessage == 'Incorrect email or password.'
    ) {
        if (fromPage && fromPage == 'login') {
            errorMessage = translate('Incorrect email or password');
        } else if (fromPage && fromPage == 'signUp') {
            errorMessage = translate('Invalid e-mail or password or date of birth');
        } else if (fromPage && fromPage == 'userChangePassword') {
            errorMessage = translate('Your current password is not valid');
        } else if (fromPage && fromPage == 'privateRoute') {
            errorMessage = translate('Failed to authenticate');
        } else {
            errorMessage = translate('Failed to authenticate');
        }
    } else if (
        errorMessage == 'Failed to fetch' ||
        errorMessage.includes('Cannot read properties of undefined')
    ) {
        errorMessage = translate('formErrors.noNetwork');
    }

    return errorMessage;
}

// VALIDATES A PASSWORD WITH MIN LENGTH AND AT LEAST ONE UPPER AND LOWERCASE CHARACTER
export function isValidPassword(password, minLength = 8) {
    const re = new RegExp(['^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{' + minLength + ',})']);
    return re.test(password);
}
// VALIDATES A PASSWORD WITH MIN LENGTH AND AT LEAST ONE UPPER AND LOWERCASE CHARACTER AND SPECIAL
export function isValidComplexPassword(password, minLength = 10) {
    const re = new RegExp([
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{' + minLength + ',})',
    ]);
    return re.test(password);
}

export function isValidLength(str, minLength = 0, maxLength = null) {
    str = String(str);

    if (str.length < minLength || (maxLength && str.length > maxLength)) {
        return false;
    }

    return true;
}

export function showPageLoader() {
    let pageLoader, classesToAdd, arr;
    pageLoader = document.getElementById('pageLoader');
    classesToAdd = 'active visible transition';
    arr = pageLoader.className.split(' ');
    if (arr.indexOf(classesToAdd) == -1) {
        pageLoader.className += ' ' + classesToAdd;
    }
}

export function hidePageLoader() {
    let pageLoader = document.getElementById('pageLoader');
    pageLoader.className = pageLoader.className.replace(/\bactive\b/g, '');
    pageLoader.className = pageLoader.className.replace(/\bvisible\b/g, '');
    pageLoader.className = pageLoader.className.replace(/\btransition\b/g, '');
}

export function getDrugDetails(drugName, loader = false) {
    loader ? showPageLoader() : '';
    return new Promise((resolve, reject) => {
        client
            .query({
                query: drugDetailsQuery,
                variables: {
                    drugs: drugName,
                },
            })
            .then((response) => {
                hidePageLoader();
                resolve(response);
            })
            .catch((err) => {
                hidePageLoader();
                reject(err);
            });
    });
}

export function getEquivalentsWithDrugDetails(drugName) {
    return new Promise((resolve, reject) => {
        showPageLoader();
        client
            .query({
                query: getEquivalentsWithDrugDetailsQuery,
                variables: {
                    selected_drug: drugName,
                },
            })
            .then((response) => {
                hidePageLoader();
                resolve(response);
            })
            .catch((err) => {
                hidePageLoader();
                reject(err);
            });
    });
}

export function fullDrugDetails(drugName) {
    return new Promise((resolve, reject) => {
        showPageLoader();
        client
            .query({
                query: fullDrugDetailsQuery,
                variables: {
                    selected_drug: drugName,
                },
            })
            .then((response) => {
                hidePageLoader();
                resolve(response);
            })
            .catch((err) => {
                hidePageLoader();
                reject(err);
            });
    });
}

export function getDrugModalDetails(drugNamesArray) {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: drugModalDetails,
                variables: {
                    drugs: drugNamesArray,
                },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export function getScriptclaimCoupon(flipt_person_id) {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: getScriptclaimCouponQuery,
                variables: {
                    rx_flipt_person_id: flipt_person_id,
                },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

export async function getContractCoupon(price_source, flipt_person_id) {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: getContractCouponQuery,
                variables: {
                    price_source: price_source,
                    rx_flipt_person_id: flipt_person_id,
                },
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}
export function formatFillNumber(str){
    if (str) {
        const regex = new RegExp("^0+(?!$)", 'g');
        str = String(str).replaceAll(regex, "");
        return str;
    }
    return str||"";

}
export function getPmnPrices(drugDetails) {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: getPbmPricesQuery,
                variables: drugDetails,
            })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
    });
}

// COPIED THE BELOW TWO FUNCTIONS FROM MOBILE APP SOURCE CODE
export const addInformationToQuantity = (quantity, information) => {
    let newQuantity = quantity;
    newQuantity = _.uniqBy(newQuantity, (dr) => {
        return dr.dosage_strength + ' ' + dr.package_qty;
    });
    newQuantity = _.orderBy(newQuantity, 'display_rank');
    return newQuantity.map((item) => ({ ...item, ...information }));
};

export const normalizeMedicineDetails = (medicineInformation) => {
    let medicines = [];

    // this reverses the array when no equivalent exists for the generic to ensure we can get pricing when screen initially loads
    // its an ugly temporary solution to get the app working
    //REDO THIS ENTIRE FUNCTION
    if (!medicineInformation[0].equivalent || medicineInformation[0].equivalent.length < 1) {
        let new_first_half = medicineInformation.slice(
            medicineInformation.length / 2,
            medicineInformation.length
        );
        let new_second_half = medicineInformation.slice(0, medicineInformation.length / 2);
        medicineInformation = new_first_half.concat(new_second_half);
    }

    medicineInformation
        .filter((drug) => drug.otc_indicator !== 'O')
        .forEach((medicineFromGraphql) => {
            let normalizedListFromGraphql = {
                drug_class: medicineFromGraphql.drug_class,
                drug_name: medicineFromGraphql.drug_name,
                drug_name_to_show:
                    medicineFromGraphql.brand_generic === 'G'
                        ? `${medicineFromGraphql.drug_name} (Generic)`
                        : `${medicineFromGraphql.drug_name} (Brand)`,
                ddn_name: medicineFromGraphql.ddn_name,
                lm_name: medicineFromGraphql.lm_name,
                equivalent: medicineFromGraphql.equivalent,
                brand_generic: medicineFromGraphql.brand_generic,
                FORMS: [],
            };
            let medicineInArray = medicines.find(
                (med) => med.drug_name === normalizedListFromGraphql.drug_name
            );
            if (!medicineInArray) {
                const {
                    ddid,
                    gpi,
                    gpi_display_rank,
                    brand_generic,
                    drug_name,
                    dosage,
                    strengths,
                    lm_name,
                    lm_form,
                    lm_ndc,
                    lm_strength,
                    pda,
                    drug_type,
                    ddn_name,
                    ddn_form,
                    ddn_strength,
                    quantity,
                    rxcui,
                    specialty_flag,
                    equivalent_brand,
                    equivalent,
                    alt_unit_price,
                    cost_difference,
                    cost_difference_pct,
                    alt_savings_per_unit,
                    alt_quantity_per_day,
                    alternate_drug_reward_per_unit,
                    quantity_per_day,
                } = medicineFromGraphql;

                const formName = dosage === 'nan' ? ddn_form || lm_form : dosage;
                let form = normalizedListFromGraphql.FORMS.find(
                    (formInformation) =>
                        (formInformation.dosage == 'nan'
                            ? formInformation.ddn_form || formInformation.lm_form
                            : formInformation.dosage) === formName
                );
                const extraInfo = {
                    ddid,
                    gpi,
                    gpi_display_rank,
                    cost_difference,
                    cost_difference_pct,
                    brand_generic,
                    drug_name,
                    dosage,
                    strengths,
                    lm_name,
                    lm_form,
                    lm_ndc,
                    lm_strength,
                    pda,
                    drug_type,
                    ddn_name,
                    ddn_form,
                    ddn_strength,
                    rxcui,
                    specialty_flag,
                    equivalent_brand,
                    equivalent,
                    alt_quantity_per_day,
                    alt_savings_per_unit,
                    alt_unit_price,
                    quantity_per_day,
                    alternate_drug_reward_per_unit,
                };
                if (form) {
                    form.strengths.push({
                        ddid,
                        gpi,
                        gpi_display_rank,
                        cost_difference,
                        cost_difference_pct,
                        brand_generic,
                        drug_name,
                        dosage,
                        strengths,
                        lm_name,
                        lm_form,
                        lm_ndc,
                        lm_strength,
                        pda,
                        drug_type,
                        ddn_name,
                        ddn_form,
                        ddn_strength,
                        rxcui,
                        specialty_flag,
                        equivalent_brand,
                        equivalent,
                        quantity: addInformationToQuantity(quantity, extraInfo),
                        alt_quantity_per_day,
                        alt_savings_per_unit,
                        alt_unit_price,
                        quantity_per_day,
                        alternate_drug_reward_per_unit,
                    });
                } else {
                    normalizedListFromGraphql.FORMS.push({
                        lm_form,
                        lm_ndc,
                        ddn_form,
                        dosage,
                        gpi_display_rank,
                        strengths: [
                            {
                                ddid,
                                gpi,
                                cost_difference,
                                cost_difference_pct,
                                brand_generic,
                                drug_name,
                                dosage,
                                strengths,
                                lm_name,
                                lm_form,
                                lm_strength,
                                pda,
                                drug_type,
                                ddn_name,
                                ddn_form,
                                ddn_strength,
                                rxcui,
                                specialty_flag,
                                equivalent_brand,
                                alt_quantity_per_day,
                                alt_savings_per_unit,
                                alt_unit_price,
                                quantity_per_day,
                                equivalent,
                                quantity: addInformationToQuantity(quantity, extraInfo),
                                alternate_drug_reward_per_unit,
                            },
                        ],
                    });
                }
                medicines.push(normalizedListFromGraphql);
            } else {
                const {
                    ddid,
                    gpi,
                    gpi_display_rank,
                    cost_difference,
                    cost_difference_pct,
                    brand_generic,
                    drug_name,
                    dosage,
                    strengths,
                    lm_name,
                    lm_form,
                    lm_ndc,
                    lm_strength,
                    pda,
                    drug_type,
                    ddn_name,
                    ddn_form,
                    ddn_strength,
                    quantity,
                    rxcui,
                    specialty_flag,
                    equivalent_brand,
                    alt_quantity_per_day,
                    alt_savings_per_unit,
                    alt_unit_price,
                    quantity_per_day,
                    equivalent,
                    alternate_drug_reward_per_unit,
                } = medicineFromGraphql;

                const formName = dosage === 'nan' ? ddn_form || lm_form : dosage;
                let form = medicineInArray.FORMS.find(
                    (formInformation) =>
                        (formInformation.dosage == 'nan'
                            ? formInformation.ddn_form || formInformation.lm_form
                            : formInformation.dosage) === formName
                );
                const extraInfo = {
                    ddid,
                    gpi,
                    gpi_display_rank,
                    cost_difference,
                    cost_difference_pct,
                    brand_generic,
                    drug_name,
                    dosage,
                    strengths,
                    lm_name,
                    lm_form,
                    lm_ndc,
                    lm_strength,
                    pda,
                    drug_type,
                    ddn_name,
                    ddn_form,
                    ddn_strength,
                    rxcui,
                    specialty_flag,
                    equivalent_brand,
                    alt_quantity_per_day,
                    alt_savings_per_unit,
                    alt_unit_price,
                    quantity_per_day,
                    equivalent,
                    alternate_drug_reward_per_unit,
                };
                if (form) {
                    form.strengths.push({
                        ddid,
                        gpi,
                        gpi_display_rank,
                        cost_difference,
                        cost_difference_pct,
                        brand_generic,
                        drug_name,
                        dosage,
                        strengths,
                        lm_name,
                        lm_form,
                        lm_ndc,
                        lm_strength,
                        pda,
                        drug_type,
                        ddn_name,
                        ddn_form,
                        ddn_strength,
                        rxcui,
                        specialty_flag,
                        equivalent_brand,
                        alt_quantity_per_day,
                        alt_savings_per_unit,
                        alt_unit_price,
                        quantity_per_day,
                        equivalent,
                        quantity: addInformationToQuantity(quantity, extraInfo),
                        alternate_drug_reward_per_unit,
                    });
                } else {
                    medicineInArray.FORMS.push({
                        lm_form,
                        lm_ndc,
                        ddn_form,
                        dosage,
                        gpi_display_rank,
                        strengths: [
                            {
                                ddid,
                                gpi,
                                cost_difference,
                                cost_difference_pct,
                                brand_generic,
                                drug_name,
                                dosage,
                                strengths,
                                lm_name,
                                lm_form,
                                lm_strength,
                                pda,
                                drug_type,
                                ddn_name,
                                ddn_form,
                                ddn_strength,
                                rxcui,
                                specialty_flag,
                                equivalent_brand,
                                alt_quantity_per_day,
                                alt_savings_per_unit,
                                alt_unit_price,
                                quantity_per_day,
                                equivalent,
                                quantity: addInformationToQuantity(quantity, extraInfo),
                                alternate_drug_reward_per_unit,
                            },
                        ],
                    });
                }
            }
        });

    return medicines;
};

/////////////////////////////////////////////////////////////////////////////////////////////

export const getAlternativeDrugs = (gpi, ddid) => {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: getAlternativeDrugsQuery,
                variables: {
                    gpi: String(gpi),
                    ddid: String(ddid),
                },
                fetchPolicy: 'network-only',
            })
            .then((result) => {
                // const normalizedList = normalizeMedicineDetails(result.data.alternativeDrugs);
                const normalizedList = normalizeMedicineDetails(
                    result.data.alternativeDrugs.filter(
                        (f) =>
                            parseFloat(f.alternate_drug_reward_per_unit) > 0 &&
                            f.alternate_drug_reward_per_unit
                    )
                );
                let tempAlternativeDrugsProm = [];
                let sortedAlternatives = [];
                const alternativeDrugsProm = normalizedList.map((drug) =>
                    getSearchParams(normalizedList, {
                        medicineNameSelected: drug.drug_name,
                    })
                );
                Promise.all(alternativeDrugsProm).then((alternativeDrugs) => {
                    tempAlternativeDrugsProm = alternativeDrugs.map((p) => ({
                        medicineConfiguration: p.searchParams,
                        medicineComboList: p.medicineComboList,
                    }));

                    if (tempAlternativeDrugsProm && tempAlternativeDrugsProm.length > 0) {
                        tempAlternativeDrugsProm.map((alternativeDrugItems) => {
                            sortedAlternatives = _.orderBy(
                                tempAlternativeDrugsProm,
                                [
                                    ({ medicineConfiguration: { quantityObject } }) =>
                                        -1 * quantityObject.alternate_drug_reward_per_unit,
                                ],
                                ['asc']
                            );
                            // ).filter(({ medicineConfiguration: { quantityObject } }) => -1 * quantityObject.alternate_drug_reward_per_unit);
                        });
                        resolve(sortedAlternatives);
                    }
                });
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export function getDaysOfSupply(quantityObject) {
    return client
        .query({
            query: getDaysOfSupplyQuery,
            variables: {
                gpi: String(quantityObject.gpi),
                brand_generic: String(quantityObject.brand_generic),
                quantity: String(parseInt(quantityObject.package_size)),
            },
            fetchPolicy: 'network-only',
        })
        .then((obj) => {
            return obj.data.getDaysOfSupply;
        })
        .catch((err) => {
            console.log(err);
        });
}

export const getSearchParams = async (medicineInformation, searchParams, brand, wait = true) => {
    const {
        medicineNameSelected,
        formSelected,
        strengthSelected,
        quantityLabelSelected,
        quantitySelected,
        zipCode,
        other,
        daysOfSupplyLabelSelected,
    } = searchParams;
    let medicineNameDefault = '';
    let medicineNamesList = [];

    if (medicineInformation) {
        medicineNamesList = medicineInformation.map((med) => med.drug_name);
        medicineNameDefault = medicineNamesList[0];
    }

    let medicineNames = medicineNameSelected || medicineNameDefault;

    let fromListForMedicine = medicineInformation.find((med) => med.drug_name === medicineNames);

    if (!fromListForMedicine) {
        fromListForMedicine = medicineInformation[0];
        medicineNames = medicineNameDefault;
    }

    const fromList = fromListForMedicine.FORMS.map((med) => ({
        name: med.dosage === 'nan' ? med.ddn_form || med.lm_form : med.dosage,
        dosage_image: med.dosage_image,
        quantity_image: med.quantity_image,
    }));
    let forms = formSelected || fromList[0];
    let formSelectedObject = fromListForMedicine.FORMS.find(
        (form) =>
            (form.dosage === 'nan' ? form.ddn_form || form.lm_form : form.dosage) === forms.name
    );

    if (!formSelectedObject) {
        formSelectedObject = fromListForMedicine.FORMS.find(
            (form) =>
                (form.dosage === 'nan' ? form.ddn_form || form.lm_form : form.dosage) ===
                fromList[0].name
        );
        forms = fromList[0];
    }

    let strengthListObject = [];
    formSelectedObject.strengths.forEach((str) => {
        strengthListObject = strengthListObject.concat(str.quantity);
    });

    const strengthList = strengthListObject
        .map((quantity) => quantity.dosage_strength)
        .filter((item, pos, self) => self.indexOf(item) === pos);

    let selectedStrength = strengthSelected || strengthList[0];
    let quantitiesList = strengthListObject
        .filter((quantity) => quantity.dosage_strength === selectedStrength)
        .map((qua) => ({
            name: qua.package_qty,
            quantity_image: qua.quantity_image,
            ...qua,
        }));

    let equivalentList = [];
    if (quantitiesList.length === 0) {
        quantitiesList = strengthListObject
            .filter((quantity) => quantity.dosage_strength === strengthList[0])
            .map((item) => ({
                name: item.package_qty,
                quantity_image: item.quantity_image,
                ...item,
            }));
        selectedStrength = strengthList[0];
    }

    let selectedQuantityLabel = quantityLabelSelected || quantitiesList[0];
    let quantityObject = other
        ? _.cloneDeep(strengthListObject.find((pac) => pac.dosage_strength === selectedStrength))
        : strengthListObject.find(
              (pac) =>
                  pac.dosage_strength === selectedStrength &&
                  pac.package_qty === selectedQuantityLabel.name
          );

    if (!quantityObject) {
        quantityObject = other
            ? _.cloneDeep(
                  strengthListObject.find((pac) => pac.dosage_strength === selectedStrength)
              )
            : strengthListObject.find(
                  (pac) =>
                      pac.dosage_strength === selectedStrength &&
                      pac.package_qty === quantitiesList[0].name
              );
        selectedQuantityLabel = quantitiesList[0];
    }

    equivalentList = quantityObject.equivalent;

    if (other) {
        if (quantityObject.custom_qty === 'package_quantity') {
            quantityObject.package_quantity = parseInt(quantitySelected.name);
            quantityObject.quantity = parseInt(quantitySelected.name);
            quantityObject.package_qty = quantityLabelSelected.name;
        } else if (quantityObject.custom_qty === 'package_size') {
            quantityObject.package_size = parseInt(quantitySelected.name);
            quantityObject.quantity = parseInt(quantitySelected.name);
            quantityObject.package_qty = quantityLabelSelected.name;
        }
    }

    return {
        medicineComboList: {
            medicineNamesList,
            fromList,
            strengthList,
            quantitiesList,
        },
        searchParams: {
            medicineNameSelected: medicineNames,
            currentObject: fromListForMedicine,
            formSelected: forms,
            strengthSelected: selectedStrength,
            other,
            quantityObject,
            equivalentList,
            zipCode,
        },
    };
};

export function isValidPositiveInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
}

export const absoluteNumber = (number) => Math.abs(number);

export const roundFixedPrice = (price) => Math.ceil(price);

export const absoluteRoundPrice = (price) => absoluteNumber(roundFixedPrice(price));

export const roundAbsPrice = (price) => Math.round(absoluteNumber(price));

export const ceilAbsPrice = (price) => Math.ceil(absoluteNumber(price));

export const normalizePrices = (...nums) => {
    return nums.reduce((acc, val) => {
        if (val == null) {
            throw new Error('No value given for normalization');
        }
        let usd = val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        usd[0] === '-' && (usd = usd.slice(1));
        acc = [...acc, usd];
        return acc;
    }, []);
};

//Escape double quotes in the string by prepending a forward slash
export function escapeString(str) {
    return str.replace(/[\""]/g, '\\"');
}

export function getCurrentLocation(t) {
    return new Promise((resolve, reject) => {
        let didUserAllowedLocationAccess = false;
        let locationError = null;
        let currentLocationPosition = null;

        navigator.geolocation.getCurrentPosition(
            // SUCCESS HANDLER
            function (position) {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;

                // FETCH ADDRESS AND ZIPCODE FROM CURRENT LOCATION LATITUDE AND LONGITUDE USING GOOGLE API
                locationActions
                    .fetchPostalCode({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    })
                    .then((rawGeoCodeData) => {
                        const {
                            locality,
                            postalCode,
                            formatted_address,
                        } = locationActions.getGeoCodeInformation(rawGeoCodeData);

                        position.zip_code = postalCode;
                        position.locality = locality;
                        position.formatted_address = formatted_address;

                        didUserAllowedLocationAccess = true;

                        currentLocationPosition = position;
                        currentLocationPosition.latitude = latitude;
                        currentLocationPosition.longitude = longitude;

                        resolve({
                            didUserAllowedLocationAccess,
                            locationError,
                            currentLocationPosition,
                        });
                    })
                    .catch((err) => {
                        locationError = err;

                        reject({
                            didUserAllowedLocationAccess,
                            locationError,
                            currentLocationPosition,
                        });
                    });
            },
            // ERROR HANDLER
            function (errorObj) {
                // alert("No perm "+errorObj.code + ": " + errorObj.message);
                alert(t('currentLocationModal.noPermission'));
                locationError = errorObj;

                reject({
                    didUserAllowedLocationAccess,
                    locationError,
                    currentLocationPosition,
                });
            }
        );
    });
}

export function getLastPrescriptions(prescriptionStatus = 'New', limit = 5) {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: lastPrescriptionsQuery,
                variables: {
                    rx_status: prescriptionStatus,
                    limit: limit,
                },
                fetchPolicy: 'network-only',
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export async function getDrugEquivalentsFunction(drugName) {
    try {
        const response = await client.query({
            query: getDrugEquivalentsQuery,
            variables: { drug_name: drugName },
        });
        return response.data.getDrugEquivalents;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export function getTermsAndConditions() {
    try {
        let response = client.query({
            query: termsConditionQuery,
            fetchPolicy: 'network-only',
        });
        return response;
    } catch (e) {
        console.log(e);
        return e;
    }
}

export function acceptHIPAA(accept) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: acceptHIPAAMutation,
                variables: {
                    accept: accept,
                },
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function acceptCompliance(accept) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: acceptComplianceMutation,
                variables: {
                    accept: accept,
                },
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function resetPassword(variables) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: changePassword,
                variables: variables,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function updateUser(variables) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: updateUserMutation,
                variables: variables,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function setUserInfo() {
    client
        .query({
            query: currentUserDetailsQuery,
            fetchPolicy: 'network-only',
        })
        .then((response) => {
            let user = response.data.user;
            auth.setUserInfo(user);
        });
}

export function changeEmail(variables) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: changeEmailMutation,
                variables: variables,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function redeemRewards(variables) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: redeemRewardsMutation,
                variables: variables,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function updateRewardOption(variables) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: updateRewardOptionMutation,
                variables: variables,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function updateDuductibleOpcOption(variables) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: updateDeductibleOPC,
                variables: variables,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function validateQuantity(variables) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: validateQuantityMutation,
                variables: variables,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function validatePhoneNumber(variables) {
    showPageLoader();
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: validatePhoneNumberMutation,
                variables: variables,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                hidePageLoader();
                reject(err);
            });
    });
}

export async function validateRoutingNumber(payload) {
    return new Promise(async (resolve, reject) => {
        let accessToken = await client.query({
            query: getAccessTokenForRoutingNumber,
        });
        try {
            const hasErrors =
                accessToken.graphQLErrors || accessToken.networkError || accessToken.message;
            if (hasErrors) {
                reject(err);
            }
            const auth_token = accessToken.data.getAccessTokenForRoutingNumber.access_token;
            const options = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth_token}`,
                },
                body: JSON.stringify({
                    BankRoutingNumber: payload,
                }),
            };
            try {
                let response = await fetch(
                    'https://cors-anywhere.herokuapp.com/' +
                        'https://api.microbilt.com/ABAAcctVerification/',
                    options
                );
                let finalResponse = await response.json();
                resolve(finalResponse);
            } catch (error) {
                reject(err);
            }
        } catch (err) {
            reject(err);
        }
    });
}

export function submitAccountDetail(payload) {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: createPayeeMutation,
                variables: payload,
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRewardAmounts() {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: getRewardAmountsQuery,
                fetchPolicy: 'network-only',
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRewardDetails() {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: getRewardDetailsQuery,
                fetchPolicy: 'network-only',
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getRewardHistory() {
    return new Promise((resolve, reject) => {
        client
            .query({
                query: getRewardHistoryQuery,
                fetchPolicy: 'network-only',
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function getPharmacyAccumulatorReports(domain, flipt_person_id) {
    showPageLoader();
    return new Promise((resolve, reject) => {
        client
            .query({
                query: getPharmacyAccumulatorReportsQuery,
                variables: { domain_name: String(domain), emp_flipt_person_id: flipt_person_id },
                fetchPolicy: 'network-only',
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                hidePageLoader();
                reject(err);
            });
    });
}

export function getRxInteractionsQuery(rxSearchArray) {
    let searchStr = '';

    if (rxSearchArray) {
        searchStr = JSON.stringify(rxSearchArray);
    }

    const query = rxSearchArray
        ? {
              query: searchQuery,
              variables: {
                  drugs: searchStr,
              },
              fetchPolicy: 'network-only',
          }
        : {
              query: defaultQuery,
              fetchPolicy: 'network-only',
          };
    return new Promise((resolve, reject) => {
        client
            .query(query)
            .then((data) => {
                resolve(data.data.rxInteractions);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export async function getRxInteractions(searchParams) {
    return new Promise((resolve, reject) => {
        let rxSearch;
        let rxParams;
        if (searchParams) {
            rxSearch = !Array.isArray(searchParams) ? [searchParams] : searchParams;
        }
        if (rxSearch && rxSearch.length) {
            rxParams = rxSearch.map((rx) => {
                return {
                    drug_name: rx.drug_name,
                    gpi: rx.gpi,
                    flipt_person_id: rx.rx_flipt_person_id
                        ? rx.rx_flipt_person_id
                        : rx.flipt_person_id,
                };
            });
        }
        getRxInteractionsQuery(rxParams)
            .then((rxInteractionsData) => {
                resolve(rxInteractionsData);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

// VALIDATE PHONE NUMBER ACCORDING TO US FORMAT xxx-xxx-xxxx
export function validatePhone(phone) {
    var digits = phone?.replace(/\D/g, '');
    var phoneRe = /^\d{10}$/;
    return phoneRe.test(digits);
}

export function convertGPItoMedispanFormat(gpi) {
    if (!gpi) return;
    return gpi.match(/.{1,2}/g).join('-');
}

export function formatData(data) {
    const translations = {};

    if (data && data.length > 0) {
        data.forEach((item) => {
            if (!translations[item.language]) {
                translations[item.language] = {};
            }

            translations[item.language][item.message_type] = item.message;
        });
    }

    return translations;
}

export function initCap(str) {
    return str.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
        return m.toUpperCase();
    });
}

export function objectWithKeySortedInReverse(object) {
    var result = {};
    _.forEach(Object.keys(object).sort().reverse(), function (key) {
        result[key] = object[key];
    });
    return result;
}

function getFormat(d) {
    const dateFormats = {
        iso_int: 'YYYY-MM-DD',
        short_date: 'DD/MM/YYYY',
        date: 'MM-DD-YYYY',
        iso_date_time: 'YYY-MM-DDTHH:mm:SS',
        iso_date_time_utc: 'YYYY-MM-DDTHH:mm:SSZ',
        date_time_utc: 'YYYY-MM-DD HH:mm:SS',
    };
    let format = null;
    Object.keys(dateFormats).forEach((prop) => {
        if (moment(d, dateFormats[prop], true).isValid()) {
            format = dateFormats[prop];
        }
    });
    return format;
}

export function getCoverageNextActive(eligibilityArr) {
    let eligReturn = eligibilityArr.find((item) =>
        moment().isBetween(
            moment(item.coverage_effective_date, getFormat(item.coverage_effective_date)),
            moment(item.coverage_termination_date, getFormat(item.coverage_termination_date))
        )
    );

    if (!eligReturn) {
        eligReturn = eligibilityArr.find(
            (item) =>
                moment(item.coverage_effective_date, getFormat(item.coverage_effective_date)) >
                moment()
        );
    }

    return eligReturn
        ? eligReturn
        : eligibilityArr.sort((a, b) => Number(b.plan_year) - Number(a.plan_year))[0];
}

export const getCoverageDataFromDependents = (dependents) =>
    dependents &&
    dependents.filter((item) =>
        item.eligibility.find(
            (e) =>
                moment().isBetween(
                    moment(e.coverage_effective_date, getFormat(e.coverage_effective_date)),
                    moment(e.coverage_termination_date, getFormat(e.coverage_termination_date))
                ) ||
                moment().isBefore(
                    moment(e.coverage_effective_date, getFormat(e.coverage_effective_date))
                )
        )
    );

export async function sleep(secs) {
    secs = secs || 1000 //1 sec default
    return new Promise(function (res, rej) {
        setTimeout(function () { res() }, secs)
    })
}
export async function getUserInfo(ops) {
    let user = null
    let count = 0
    let options = ops || {}
    let maxRetry = options.noRetry ? 1 : (Number(options.maxRetries) || 4);
    let timeOut = Number(options.timeOut) || 1000
    //keep trying until user invalid (UPTO 4 TIMES)
    while (!user && count < maxRetry) {
        if (count >= 1) {
            await sleep(timeOut)
        }
        user = await getUserInfo_impl(options);
        count = count + 1;
        if (!user) {
            if (count > 1) {
                console.log(`Trying again to get the user data( ${count} )`)
            }
        } else {
            break;
        }
    }
    return user;
}
export async function getUserInfo_impl(options = {}) {
    const USER_GROUP_CACHE = 'user';
    const USER_INFO_KEY = 'full';
    const userData = Cacher.load(USER_GROUP_CACHE, USER_INFO_KEY);
    if (userData && !options.ignoreCache) {
        return userData;
    }
    const cacheOptions = {
        expiry: 2,
    };
    const obj = await client.query({
        query: currentUserDetails,
        fetchPolicy: 'network-only',
    });
    const user = obj.data.user;
    Cacher.save(USER_GROUP_CACHE, USER_INFO_KEY, user, cacheOptions);
    return user;
}

export const getFormImage = (form_image) => {
    let icon = '';
    if (form_image) {
        if (Object.keys(ASSETS).includes(`FORM_${form_image.split(' ').join('_')}`)) {
            icon = ASSETS[`FORM_${form_image.split(' ').join('_')}`];
        } else {
            icon = ASSETS.FORM_DEFAULT;
        }
    } else {
        icon = ASSETS.FORM_DEFAULT;
    }
    return icon;
};

export function stripChars(v, type = 'integer') {
    if (!v || typeof v !== 'string') return;
    const stripValue = v.replace(/\D*/gi, '');
    return type == 'integer' ? stripValue * 1 : stripValue;
}

export function sanitizeString(str) {
    return encodeURI(str);
}

export const diff_years = (date) => {
    date = date.replace(' ', 'T');
    let dt1 = new Date(date);
    let dt2 = new Date();
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
};

export const prescriptionFilter = (userRxHistory, prescriptions) => {
    const userRxHistoryArr = userRxHistory;
    const prescriptionsArr = _.uniqBy(prescriptions, 'prescription_id');
    userRxHistoryArr.map((rx) => {
        let id = rx.prescription_id;
        userRxHistoryArr.find((p) => p.prescription_id === id) ? null : prescriptionsArr.push(rx);
    });

    return prescriptionsArr;
};

//FD-6200
export function getAdminFliptPersonIdLS() {
    const admin_flipt_person_id = auth.getAdminID()?.flipt_person_id;
    return admin_flipt_person_id || null;
}

export function getMemberID(user) {
    const { member_id, flipt_person_id, person_code, parent_id } = user || {};
    if (member_id) {
        return member_id;
    }

    if (parent_id && person_code) {
        return `${parent_id}${person_code}`;
    }

    if (flipt_person_id && person_code) {
        return `${flipt_person_id}${person_code}`;
    }
    return null;
}

export function cleanAllergyAndHealthData(data,userInfo) {
    let user = userInfo?.employee || {};
    let existingData =  [{
        flipt_person_id: user.flipt_person_id,
        humana_allergies: user.humana_allergies || [],
        humana_health_conditions: user.humana_health_conditions || [],
    }];
    if(user.dependents && user.dependents.length > 0){
        user.dependents.forEach((item) => {
            existingData.push({
                flipt_person_id: item.flipt_person_id,
                humana_allergies: item.humana_allergies || [],
                humana_health_conditions: item.humana_health_conditions || [],
            });
        });
    }
    let newData = [];
    data.forEach((item)=>{
        let existingItem = existingData.find((i)=>i.flipt_person_id === item.flipt_person_id);
        if(existingItem){
            let oldAllergies = existingItem.humana_allergies;
            let oldHealthConditions = existingItem.humana_health_conditions;
            let newAllergies = item.humana_allergies;
            let newHealthConditions = item.humana_health_conditions;
            if (!_.isEqual(oldAllergies, newAllergies) || !_.isEqual(oldHealthConditions, newHealthConditions)) {
                newData.push(item);
            }
        }
    })

    return newData;
}

