import auth from "../../services/auth";

export function formatDate(value) {
    return (
        (value.getMonth() < 9
            ? '0' + (value.getMonth() + 1).toString()
            : (value.getMonth() + 1).toString()) +
        '/' +
        (value.getDate() < 9 ? '0' + value.getDate().toString() : value.getDate().toString()) +
        '/' +
        value.getFullYear().toString()
    );
}

export const reloadOtherTabsOnAdminLogin = (event) => {
    if (event.storageArea != localStorage) return;
    const nonUserInfoKey = event.key !== 'userInfo';
    if (nonUserInfoKey) return;
    const isAdmin = auth.isAdmin();
    if (isAdmin) {
        window.location.reload();
    }
};


export const setStorageListener = (callback) => {
    window.addEventListener('storage', callback);
}

export const removeStorageListener = (callback) => {
    window.removeEventListener('storage', callback);
}
