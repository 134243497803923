import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

const AddBankAccount = lazy(() => import('../components/user/addBankAccount'));
const AddLocation = lazy(() => import('../components/settings/addLocation/'));
const DigitalCard = lazy(() => import('../components/DigitalCard/'));
const Admin = lazy(() => import('../components/admin'));
const Help = lazy(() => import('../components/help'));
const Home = lazy(() => import('../components/home'));
const PrivateRoute = lazy(() => import('../components/privateRoute'));
const Rewards = lazy(() => import('../components/rewards'));
const UserAccount = lazy(() => import('../components/account'));
const UserContact = lazy(() => import('../components/contact'));
const UserDefaultHome = lazy(() => import('../components/defaultHome'));
const UserPrivacyPolicy = lazy(() => import('../components/privacyPolicy'));
const UserSettings = lazy(() => import('../components/settings'));
const Walkthrough = lazy(() => import('../components/walkthrough'));
const PageNotfound = lazy(() => import('../components/404ErrorPage'));

export const MainRoutes = (props) => {
    return (
        <div>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {/* non protected views */}
                    <Route exact path="/" component={Home} />
                    <Route exact path="/accept-terms-and-conditions" component={Home} />
                    <Route exact path="/create-password" component={Home} />
                    <Route exact path="/forgot-password" component={Home} />
                    <Route exact path="/forgot-login" component={Home} />
                    <Route exact path="/login" component={Home} />
                    <Route exact path="/signup" component={Home} />
                    <Route exact path="/update-personal-information" component={Home} />
                    <Route exact path="/verify-phone" component={Home} />
                    {/* protected views only accessible after user logs in */}
                    <PrivateRoute path="/home" component={UserDefaultHome} />
                    <PrivateRoute exact path="/walkthrough" component={Walkthrough} />
                    <PrivateRoute path="/account" component={UserAccount} />
                    <PrivateRoute exact path="/activate-dependent" component={UserDefaultHome} />
                    <PrivateRoute exact path="/contact" component={UserContact} {...props} />
                    <PrivateRoute exact path="/help" component={Help} {...props} />
                    <PrivateRoute exact path="/admin" component={Admin} {...props} />
                    <PrivateRoute
                        exact
                        path="/privacy-policy"
                        component={UserPrivacyPolicy}
                        {...props}
                    />
                    <PrivateRoute exact path="/reset-password" component={Home} />
                    <PrivateRoute exact path="/rewards" component={Rewards} {...props} />
                    <PrivateRoute
                        exact
                        path="/setup-account"
                        component={AddBankAccount}
                        {...props}
                    />
                    <PrivateRoute exact path="/insurance-card" component={DigitalCard} {...props} />
                    <PrivateRoute path="/home" component={UserDefaultHome} />
                    <PrivateRoute exact path="/settings" component={UserSettings} {...props} />
                    <PrivateRoute
                        exact
                        path="/settings/add-location"
                        component={AddLocation}
                        {...props}
                    />

                    {/* Catch all 404*/}
                    <Route component={PageNotfound} />
                </Switch>
            </Suspense>
        </div>
    );
};

export default MainRoutes;
