import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, ModalHeader } from 'semantic-ui-react';

const Header = styled(ModalHeader)`
  display: flex !important;
  background: red !important;
  color: white !important;
  gap: 2%;
  flex-direction: row;
  & > a: {
    font-size: 10;
    font-weight: bold;
  }
`;

const RoundedHeader = ({ headerText, onPress }) => {
  const { t } = useTranslation();
  return (
    <Header>
      <Icon onClick={onPress} link name="close" />
      <h6>{t(headerText)}</h6>
    </Header>
  );
};
export default RoundedHeader;
