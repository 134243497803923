import { css, jsx } from '@emotion/react';

const colors = {
    alabaster: '#F8F8F8',
    alto: '#D7D7D7',
    avatarBackground: '#D8D8D8',
    azureRadiance: '#0191FF',
    bgGray: '#e3e4e5',
    black: '#000000',
    borderColor: '#d3d3d3',
    carnation: '#EF536A',
    codGray: '#1E1E1E',
    cornFlowerBlue: '#FF3366',
    darkCyanBlue: '#314567',
    ebonyClay: '#202B34',
    fliptBlue: '#0093FF',
    fliptDodgerBlue: '#0092FF',
    fliptDarkBlue: '#0F2950',
    fliptDarkGrey: '#666A67',
    fliptGray: '#a9b4c0',
    fliptGray2: '#aaa',
    fliptLtBlue: '#9DD5FF',
    fliptRed: '#FB0505',
    fliptSoftRed: '#ef3231',
    gallery: '#EDEDED',
    gold: '#FFD700',
    gray: '#888',
    grayOutline: '#d3d4d5',
    greyBorder: '#E0E0E0',
    greyBorderTab: '#a8a7a7',
    greySubtext: '#A2A2A2',
    lightGray: '#CCC',
    lightText: '#9B9B9B',
    limeGreen: '#2fc958',
    linkBlue: '#127FFF',
    ltGray: '#333',
    ltrGray: '#F6F6F6',
    mercury: '#E8E8E8',
    midGreyBackground: '#E4E6E6',
    oceanGreen: '#43BD8B',
    outerSpace: '#33373B',
    persimmon: '#FF575A',
    pippin: '#ffe6e6',
    radicalRed: '#FF3253',
    screenBackground: '#F9F9F9',
    solidGrey: '#606060',
    shadowColor: '#DBDBDB',
    silverChalice: '#A2A2A2',
    textGrey: '#919191',
    white: '#FFF',
    wildSand: '#F4F4F4',
};

const colorsKeys = Object.keys(colors);
export const Colors = colorsKeys.reduce((collection, color) => {
    collection[color] = css`
        color: ${colors[color]};
    `;
    return collection;
}, {});

export default colors;
