/**
 * This module caches data in memory per group and key
 * It doest handle loading from DB or elsewhere
 *   then should be handled by comsumer
 */

//private datastore
const CACHE = {};
/**
 * private method to get grouped data
 * creates one if not exist
 */

function getGroup(group) {
	return CACHE[group] || (CACHE[group] = {});
}

/**
 * Saves the cache
 */

const api = {
	save: function save(group, key, data, opts = {}) {
		let grouped = getGroup(group);
		grouped[key] = { data: JSON.stringify(data), opts };
		if (opts && opts.expiry) {
			setTimeout(api.clear, opts.expiry * 1000, group, key);
		}
		return grouped[key];
	},
	/**
	 * Loades and returns cache if exists
	 * other wise returns null
	 */
	load: function load(group, key) {
		let grouped = getGroup(group);
		let data = grouped[key];
		let result = null;
		if (data) {
			result = JSON.parse(data.data);
		}
		return result;
	},

	/**
	 * deletes cached data
	 * for a group if key is not provided
	 */
	clear: function clear(group, key) {
		let result;
		if (key == null) {
			result = delete CACHE[group];
		} else {
			let grouped = CACHE[group];
			if (grouped) {
				result = delete grouped[key];
			}

			return result;
		}
	},
};

export default api;
