import { writeAnalytics } from '../../../utilities/truevault';

export function isAnalyticsMounted() {
    return true;
}

const CONNECTION_OPENED = 'analytics/CONNECTION_OPENED';
const CONNECTION_CLOSED = 'analytics/CONNECTION_CLOSED';
const INSTANCE_CLONED = 'analytics/INSTANCE_CLONED';
const INSTANCE_CLAIMED = 'analytics/INSTANCE_CLAIMED';
const REGISTER_LOCAL_PROP = 'analytics/REGISTER_LOCAL_PROP';
const REGISTER_CALLBACK_FUNCTION = 'analytics/REGISTER_CALLBACK_FUNCTION';


export function registerGlobalProp(key, value) {
    return { type: REGISTER_LOCAL_PROP, payload: { key, value } };
}

export function closeConnection(instanceId) {
    return { type: CONNECTION_CLOSED, payload: instanceId };
}

export function fireAnalyticsEvent(instanceId, key, value) {
    return (_, getState) => {
        writeAnalytics({ [key]: value }, getState().analytics.dbConnections[instanceId]);
    };
}

export function fireAnalyticsEvents(instanceId, props) {
    return (_, getState) => {
        writeAnalytics(props, getState().analytics.dbConnections[instanceId]);
    };
}

export function cloneInstance(name, instanceId) {
    return { type: INSTANCE_CLONED, payload: { name, instanceId } };
}

export function claimInstance(name, newInstanceId) {
    return { type: INSTANCE_CLAIMED, payload: { name, newInstanceId } };
}

export function registerCallbackFunction(callback) {
    return { type: REGISTER_CALLBACK_FUNCTION, payload: callback };
}

const initialState = {
    dbConnections: {},
    globalProps: {},
    analyticChanges: 0,
    callback: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CONNECTION_OPENED:
            return {
                ...state,
                analyticChanges: state.analyticChanges + 1,
                dbConnections: {
                    ...state.dbConnections,
                    [action.payload.instanceId]: action.payload.documentId,
                },
            };

        case CONNECTION_CLOSED: {
            const dbConnections = Object.assign({}, state.dbConnections);
            delete dbConnections[action.payload];

            return {
                ...state,
                dbConnections,
            };
        }

        case INSTANCE_CLONED:
            return {
                ...state,
                dbConnections: {
                    ...state.dbConnections,
                    [action.payload.name]: state.dbConnections[action.payload.instanceId],
                },
            };

        case REGISTER_LOCAL_PROP:
            return {
                ...state,
                globalProps: {
                    ...state.globalProps,
                    [action.payload.key]: action.payload.value,
                },
            };

        case INSTANCE_CLAIMED: {
            const dbConnections = Object.assign({}, state.dbConnections);
            const value = dbConnections[action.payload.name];
            delete dbConnections[action.payload.name];

            return {
                ...state,
                dbConnections: {
                    ...dbConnections,
                    [action.payload.newInstanceId]: value,
                },
            };
        }
        case REGISTER_CALLBACK_FUNCTION: {
            return {
                ...state,
                callback: action.payload,
            };
        }

        default:
            return state;
    }
};
