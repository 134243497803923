export default `
  aca_document
  annual_deductible_family
  annual_deductible_individual
  annual_out_of_pocket_family
  annual_out_of_pocket_individual
  app_deductible_pending_message
  deductible_exempted
  display_uid_flag
  display_uid_name
  display_member_id
  display_family_deductible
  individual_deductible_embedded
  individual_out_of_pocket_embedded
  app_max_incentive_credit
  incentive_credit_remaining
  mail_order_credit_card_flow {
    pharmacy_name
    npi
  }
  baseline_pricing {
    alternate_pricing_type
    display_coinsurance_penalty_together
    display_retail_drugsub_rewards
    rbp_cp_apply_rewards_no_intent
    rbp_fallback_zone_pricing
    rbp_penalty_oop_accumulation
    rbp_penalty_predeductible_accumulation
  }
  flipt_marketing_configuration {
    image_location
    link
    message_on_off
  }
  grandfathering {
      brand_generic
      doc_name
      effective_end_date
      effective_start_date
      function
      gpi
      gpi_match_level
      lookback_period
      maintenance_indicator
      multi_source
      otc_indicator
      penalty
      reject_code
      drug_type_exclusion
  }
  limitation {
    app_web_message
    additional_message
    days_of_supply {
      max
      min
    }
    drug_type {
      maintenance_drug_flag
    }
    specialty_flag
    days_to_search_back
    no_of_fills
    reject_message
    medicare_type
    medicare_eligible
    app_medicare_message_indicator
    occ
    action
    rank
  }
  no_alternatives
  plan_name
  otc_inclusion_flag
  spd_document
  switch_generic_when_brand_not_covered
  show_credit_banner
`;
