import btoa from 'btoa';
import atob from 'atob';
import { Buffer } from 'buffer';
import { _ } from 'lodash';
global.btoa = btoa;
global.atob = atob;
global.Buffer = Buffer;

const ACCOUNT_ID = '98a031fd-049e-4388-831a-982cf1838d1f';
const SENDGRID_API_KEY = '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';


function decodeDocs(docs) {
    return docs.map((doc) => {
        let decryptedJSON = JSON.parse(Buffer(doc.document, 'base64').toString('ascii'));

        decryptedJSON['owner_id'] = doc.owner_id;
        decryptedJSON['document_id'] = doc.document_id;

        return decryptedJSON;
    });
}


export function writeAnalytics() {}


