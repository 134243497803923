import _ from 'lodash';
import moment from 'moment';

export function daysOfSupplyHelper(drugConfiguration) {
    let brand_generic = drugConfiguration.name.brand_generic;
    let gpi = drugConfiguration.dosage.gpi;
    let quantity;

    if (!drugConfiguration.isCustomQuantity) {
        quantity = (
            drugConfiguration.quantity.package_quantity * drugConfiguration.quantity.package_size
        ).toString();
    } else {
        let { custom_qty, package_quantity, package_size } = drugConfiguration.quantity;
        let customValue = drugConfiguration.customQuantity.match(/[-+]?[0-9]*\.?[0-9]+/)[0];
        quantity =
            (custom_qty && custom_qty.includes('package_quantity')
                ? package_size
                : package_quantity) * customValue;
    }

    return {
        brand_generic,
        gpi,
        quantity,
    };
}
export function daysOfSupplyData(drugConfiguration,quantity){
    const comparision = Math.ceil(quantity / Number(drugConfiguration?.dosage?.qtytodaysupplyratio_new || 0));
	if (comparision <= 30) {
		return 'UP TO 30';
	}
	if (comparision > 30 && comparision <= 60) {
		return 'UP TO 60';
	}
	if (comparision > 60 && comparision <= 90) {
		return 'UP TO 90';
	}
    //  comparision > 90  - default
    return 'OVER 90';
}
export function checkIfCustomDaysOfSupply(daysofsupply,days_of_supply){
    if (daysofsupply) {
        const days_of_supply_value =  days_of_supply.replace(/\D/g, '');
        if (days_of_supply_value == daysofsupply) {
            return days_of_supply;
        } else {
            return daysofsupply;
        }
    }else {
        return days_of_supply;
    }
}

export function getDosageData(formData) {
    const sd = formData
        .map((d) =>
            d.quantity.map((q) => ({
                ...q,
                gpi: d.gpi,
                medicare_type_codes: d.medicare_type_codes,
                medicare_eligible: d.medicare_eligible,
                brand_generic: d.brand_generic,
                is_grandfathered_drugcoverage_drug: d.is_grandfathered_drugcoverage_drug,
                qtytodaysupplyratio_new : d.qtytodaysupplyratio_new
            }))
        )
        .reduce((acc, c) => {
            const dosageRank = c.sort((a, b) => a.dosage_rank * 1 > b.dosage_rank * 1);

            const dosageArray = dosageRank.reduce((dsArray, ds) => {
                const { dosage_strength } = ds;
                const notInArray =
                    dsArray.findIndex((s) => s.dosage_strength === dosage_strength) === -1;

                if (notInArray) {
                    dsArray.push(ds);
                }

                return dsArray;
            }, []);

            acc.push(...dosageArray);
            return acc;
        }, [])
        .sort((a, b) => a.dosage_rank * 1 > b.dosage_rank * 1);

    return sd.sort((a, b) => stripChars(a.dosage_strength) > stripChars(b.dosage_strength));
}

export function getQuantityData(formData, dosageStrength) {
    const quantityResults = formData.reduce((acc, curr) => {
        if (!curr.quantity) {
            return acc;
        }

        const qArray = curr.quantity.filter((q) => q.dosage_strength === dosageStrength);

        if (qArray.length) {
            acc.push(qArray);
        }

        return acc;
    }, []);

    return quantityResults.length
        ? quantityResults[0].sort((a, b) => a.display_rank - b.display_rank)
        : formData;
}

export const checkMediCareDrugConfig = (drug_details) => {
    const singleDrug = drug_details.filter(
        (item, index) =>
            !(
                item?.medicare_type_codes?.includes('1') || item?.medicare_type_codes?.includes('2')
            ) || item?.is_grandfathered_drugcoverage_drug === 'Y'
    );

    if (singleDrug.length == 0) {
        return true;
    } else {
        return false;
    }
};

export const checkMediCareDosage = (drug_details, gpi) => {
    const singleDrug = drug_details.filter((item, index) => item.gpi == gpi);
    if (
        singleDrug.length > 0 &&
        (singleDrug[0]?.medicare_type_codes?.includes('1') ||
            singleDrug[0]?.medicare_type_codes?.includes('2')) &&
        singleDrug[0]?.is_grandfathered_drugcoverage_drug !== 'Y'
    ) {
        return true;
    } else {
        return false;
    }
};

export function customQuantityHelper(
    drugConfiguration,
    isCustomQuantity,
    isCustomDosageQuantity,
    customQuantity,
    form,
    drugQuantity,
    valueForDosage
) {
    let newDrugConfiguration;

    if (
        (isCustomQuantity && !isCustomDosageQuantity) ||
        (!isCustomQuantity && !isCustomDosageQuantity)
    ) {
        const newQuantity = getNewQuantity({
            customQuantity,
            drugConfiguration,
            drugQuantity,
            form,
        });
        newDrugConfiguration = {
            customQuantity: `${customQuantity} ${form}`,
            dosage: drugConfiguration.dosage,
            form: drugConfiguration.form,
            isAlt: drugConfiguration.isAlt,
            isCustomQuantity: true,
            location: drugConfiguration.location,
            medispanInfo: drugConfiguration.medispanInfo,
            name: drugConfiguration.name,
            patient: drugConfiguration.patient,
            quantity: newQuantity,
        };
    } else if (isCustomQuantity && isCustomDosageQuantity) {
        let { dosage } = _.cloneDeep(drugConfiguration);
        let newStrength =
            `${customQuantity} ${dosage.pkg_uom}` +
            (dosage.dosage_strength.split(` ${dosage.pkg_uom}`).length < 1
                ? ''
                : dosage.dosage_strength.split(` ${dosage.pkg_uom}`)[1]);
        dosage.dosage_strength = newStrength;
        dosage.package_qty = `1 ${form}`;
        dosage.package_quantity = '1.0';
        dosage.package_size = `${customQuantity}.0`;

        newDrugConfiguration = {
            customQuantity: isCustomQuantity ? `${customQuantity} ${valueForDosage}` : null,
            dosage: dosage,
            form: drugConfiguration.form,
            isAlt: drugConfiguration.isAlt,
            isCustomQuantity: isCustomQuantity,
            isCustomDosageQuantity,
            location: drugConfiguration.location,
            medispanInfo: drugConfiguration.medispanInfo,
            name: drugConfiguration.name,
            patient: drugConfiguration.patient,
            quantity: isCustomQuantity
                ? dosage
                : drugQuantity.find(
                      (drugObj) => drugObj.package_qty.split(' ')[0] === customQuantity
                  )
                ? drugQuantity.find(
                      (drugObj) => drugObj.package_qty.split(' ')[0] === customQuantity
                  )
                : drugConfiguration.quantity,
        };
    }

    return newDrugConfiguration;
}

function getNewQuantity({ customQuantity, drugConfiguration, drugQuantity, form }) {
    const cQuantity = Number(customQuantity);
    const selectedQuantity = drugConfiguration.quantity;
    const setPackageQuantity = selectedQuantity.package_desc.toLowerCase() === form.toLowerCase();
    const packageQTYBasedOnCustomQuantity = drugQuantity.find(
        (drugObj) => drugObj.package_qty.split(' ')[0] === cQuantity
    );

    if (packageQTYBasedOnCustomQuantity) {
        return packageQTYBasedOnCustomQuantity;
    } else {
        return setPackageQuantity
            ? {
                  ...drugConfiguration.quantity,
                  package_quantity:
                      cQuantity >= Number(selectedQuantity.package_quantity) ? cQuantity : 1,
              }
            : {
                  ...drugConfiguration.quantity,
                  package_size: cQuantity,
                  package_quantity: 1,
              };
    }
}



function isCoverageActive(eligibility) {
    const { coverage_effective_date, coverage_termination_date } = eligibility;
    const cef = moment(coverage_effective_date);
    const ctd = moment(coverage_termination_date);

    return coverage_effective_date && coverage_termination_date
        ? moment().isBetween(cef, ctd, 'days', '[]')
        : false;
}
export function compilePatientsData(userInfo) {
    const { flipt_person_id, relationship, dependents } = userInfo;

    const filteredDependents = (dependents || []).filter((x) =>
        x.eligibility
            ? x.eligibility.some(
                  (p) => isCoverageActive(p) && moment(p.coverage_termination_date) > moment()
              )
            : false
    );

    const patientsList = [userInfo].concat(Object.values(filteredDependents)).map((user) => {
        return {
            first_name: user.first_name,
            last_name: user.last_name,
            value: user.flipt_person_id,
            private_account:
                user.flipt_person_id === flipt_person_id ? false : user.private_account,
            avatar: user.avatar,
        };
    });
    return patientsList;
}
export function compileLocationsData(userInfo) {
    const { locations: userLocations, home_address_1, home_address_2, city, state, zip } = userInfo;

    const hasHome = userLocations?.some((location) => location.name === 'Home');
    if (hasHome) {
        return userLocations;
    }
    const homeLocation = {
        name: 'Home',
        street_address: home_address_1,
        street_address_2: home_address_2 || '',
        city,
        state,
        zip_code: zip,
    };
    let locations = [homeLocation];
    if (userInfo.locations && Array.isArray(userInfo.locations) && userInfo.locations.length > 0) {
        locations = locations.concat(userInfo.locations);
    }
    return locations;
}

function compileDrugNameObj(drugDetails) {
    let drugNames = drugDetails.map((drug) => {

        let drugNameObj = {
            drug_name: drug.drug_name,
            brand_generic: drug.brand_generic,
            equivalent: drug.equ_drug_name,
            form: drug.forms[0].form,
        };
        return drugNameObj;
    });

    return drugNames;
}

export async function compileDrugConfiguration(
    drugDetails,
    drugNameSelected,
    isAlt,
    originallySearchedDrug,
    selectedUser,
    userInfo
) {
    const patientData = compilePatientsData(userInfo);
    const locations = compileLocationsData(userInfo);

    const { rxPlanMaster, medicare_eligible } = userInfo;
    const { limitation } = rxPlanMaster;
    let checkLimitation = limitation?.filter(
        (item, index) =>
            item?.action?.toLowerCase() === 'reject' &&
            item?.app_medicare_message_indicator === 'Y' &&
            item?.medicare_eligible === medicare_eligible
    );
    checkLimitation?.sort((a, b) => {
        return a.rank - b.rank;
    });
    const { medicare_eligible: medicare_eligible_limitation } =
        checkLimitation.length > 0 ? checkLimitation[0] : {};
    if (!originallySearchedDrug) {
        const drugNames = compileDrugNameObj(drugDetails);
        const genericDrug = drugNames.find((drug) => drug.brand_generic === 'G');

        let defaultDrug = genericDrug
            ? drugDetails.find((drug) => drug.drug_name === genericDrug.drug_name)
            : drugDetails.find((drug) => drug.drug_name === drugNameSelected);

        const mediCareBrandGeneric = checkMediCareBrandGeneric(drugDetails);
        defaultDrug = mediCareBrandGeneric?.forms ? mediCareBrandGeneric : defaultDrug;
        const drugForm = defaultDrug.forms;
        const gpis =
            _.uniq(
                _.flatten(
                    _.flatten(
                        drugDetails.map((x) => x.forms.map((y) => y.dosages.map((z) => z.gpi)))
                    )
                )
            ) || [];
        let defautMediCare = {
            dosages: [],
        };
        const mediCareDrugForm =
            medicare_eligible === medicare_eligible_limitation
                ? checkMediCareDrugForm(defaultDrug)
                : defautMediCare;
                
        const mediCareDrugs =
            mediCareDrugForm.dosages.length != 0 ? mediCareDrugForm.dosages : drugForm[0].dosages;

        const newDosageData = getDosageData(mediCareDrugs);

        let display_drug_Name = newDosageData.filter(
            (item, index) =>
                !(
                    item?.medicare_type_codes?.includes('1') ||
                    item?.medicare_type_codes?.includes('2')
                ) || item?.is_grandfathered_drugcoverage_drug === 'Y'
        );
        let dosage_strength_Medicare =
            display_drug_Name.length > 0
                ? display_drug_Name[0]?.dosage_strength
                : newDosageData[0]?.dosage_strength;

        const newQuantityData = getQuantityData(mediCareDrugs, dosage_strength_Medicare);
        const drugDosage = _.orderBy(
            newDosageData,
            function (dosage) {
                return dosage.dosage_rank * 1;
            },
            ['asc']
        );
        const drugQuantity = _.orderBy(
            newQuantityData,
            function (quantity) {
                return quantity.package_quantity * quantity.package_size;
            },
            ['asc']
        );
        const drugConfiguration = {
            customQuantity: null,
            dosage: display_drug_Name.length > 0 ? display_drug_Name[0] : newDosageData[0],
            form: mediCareDrugForm.dosages.length != 0 ? mediCareDrugForm : drugForm[0],
            isAlt,
            isCustomQuantity: false,
            location: locations[0],
            name: drugNames.find((drug) => drug.drug_name === defaultDrug.drug_name),
            patient: selectedUser,
            quantity: newQuantityData[0],
        };

        return {
            defaultDrug,
            drugConfiguration,
            drugDosage,
            drugForm,
            drugNames,
            drugQuantity,
            locations,
            patients: patientData,
        };
    } else {
        const newDrugConfiguration = {
            isAlt: drugDetails[0].drug_name !== originallySearchedDrug.drug_name,
            isCustomQuantity: originallySearchedDrug.custom_quantity
                ? originallySearchedDrug.custom_quantity
                : null,
            location:
                locations.find((location) =>
                    originallySearchedDrug.locationSelected.includes(location.name)
                ) || locations[0],
            patient: patientData.find(
                (patient) => patient.value === originallySearchedDrug.rx_flipt_person_id
            ),
        };

        return await configureAlternativeDrug(
            drugDetails,
            originallySearchedDrug,
            newDrugConfiguration,
            locations
        );
    }
}

export async function configureAlternativeDrug(
    selectedPrescriptionDetails,
    selectedPrescription,
    drugConfiguration,
    locations
) {
    let selectedPrescriptionDetailsObj = selectedPrescriptionDetails.find(
        (rx) => rx.drug_name === selectedPrescription.drug_name
    )
        ? selectedPrescriptionDetails.find((rx) => rx.drug_name === selectedPrescription.drug_name)
        : selectedPrescriptionDetails[0];

    let returnedState = {};
    let newDrugConfiguration = {};
    let drugNames = compileDrugNameObj(
        selectedPrescriptionDetails.filter(
            (drugObj) => drugObj.drug_name === selectedPrescription.drug_name
        )
    );
    let newDosageData = getDosageData(
        selectedPrescriptionDetailsObj.forms.find(
            (form) => form.form === selectedPrescription.form
        ).dosages
    );
    let newQuantityData = getQuantityData(
        selectedPrescriptionDetailsObj.forms.find(
            (form) => form.form === selectedPrescription.form
        ).dosages,
        newDosageData?.find(dosage => dosage.dosage_strength === selectedPrescription.dosage_strength).dosage_strength || newDosageData[0].dosage_strength
    );
    let drugDosage = _.orderBy(newDosageData, 'dosage_rank');
    let drugQuantity = _.orderBy(
        newQuantityData,
        function (quantity) {
            return quantity.package_quantity * quantity.package_size;
        },
        ['asc']
    );
    let isCustomDosage =
    newDosageData.find((dosage) => dosage.dosage_strength === selectedPrescription.dosage_strength) ? false : true;
    newDrugConfiguration.name = drugNames.find(
        (drugName) => drugName.drug_name === selectedPrescription.drug_name
    );
    newDrugConfiguration.form = selectedPrescriptionDetailsObj.forms.find(
        (form) => form.form === selectedPrescription.form
    );
    newDrugConfiguration.dosage = quantityHelper(
        drugConfiguration,
        newQuantityData,
        selectedPrescription,
        isCustomDosage
    );
    newDrugConfiguration.dosage.gpi = selectedPrescription.gpi;
    newDrugConfiguration.dosage.qtytodaysupplyratio_new = newDosageData[0]?.qtytodaysupplyratio_new;
    newDrugConfiguration.quantity = quantityHelper(
        drugConfiguration,
        newQuantityData,
        selectedPrescription,
        isCustomDosage
    );
    newDrugConfiguration.isCustomQuantity = drugConfiguration.isCustomQuantity
        ? drugConfiguration.isCustomQuantity
        : !drugQuantity.find(
              (qty) =>
                  qty.package_qty.split(' ')[0] === selectedPrescription.package_qty.split(' ')[0]
          )
        ? true
        : false;
    newDrugConfiguration.customQuantity = drugConfiguration.customQuantity
        ? drugConfiguration.customQuantity
        : !drugQuantity.find(
              (qty) =>
                  qty.package_qty.split(' ')[0] === selectedPrescription.package_qty.split(' ')[0]
          )
        ? `${selectedPrescription.package_qty.split(' ')[0]} ${drugQuantity[0].quantity_type}`
        : null;
    newDrugConfiguration.location = drugConfiguration.location;
    newDrugConfiguration.patient = drugConfiguration.patient;
    returnedState.drugConfiguration = newDrugConfiguration;
    returnedState.alternativeDrugs = null;
    returnedState.defaultDrug = selectedPrescriptionDetails.find(
        (drug) => drug.drug_name === selectedPrescription.drug_name
    );
    returnedState.drugForm = returnedState.defaultDrug.forms;
    returnedState.drugDosage = _.orderBy(
        drugDosage,
        function (dosage) {
            return dosage.dosage_rank * 1;
        },
        ['asc']
    );
    returnedState.drugQuantity = drugQuantity;
    returnedState.drugNames = compileDrugNameObj(selectedPrescriptionDetails);
    returnedState.locations = locations;
    return returnedState;
}
function quantityHelper(drugConfiguration, newQuantityData, selectedPrescription, isCustomDosage) {
    if (isCustomDosage) {
        let newQuantity = _.cloneDeep(newQuantityData[0]);
        newQuantity.dosage_strength = selectedPrescription.dosage_strength;
        newQuantity.gpi = selectedPrescription.gpi;
        newQuantity.gppc = selectedPrescription.gppc;
        newQuantity.package_desc = selectedPrescription.package_desc;
        newQuantity.package_qty = selectedPrescription.package_qty;
        newQuantity.package_quantity = selectedPrescription.package_quantity;
        newQuantity.package_size = selectedPrescription.package_size;
        drugConfiguration.customQuantity = selectedPrescription.package_qty;
        return newQuantity;
    }

    if (drugConfiguration.isCustomQuantity) {
        return newQuantityData[0];
    }
    const matchingPackageQuantity = newQuantityData.find(
        (qty) =>
            qty.package_qty.split(' ')[0] === selectedPrescription.package_qty.split(' ')[0]
    )
    if (matchingPackageQuantity) {
        return matchingPackageQuantity;
    }
    let packageSize = selectedPrescription.package_qty.split(' ')[0];
    const quantityData = newQuantityData.find((qty) => qty.gppc === selectedPrescription.gppc) || newQuantityData[0];
    let packageType = quantityData.quantity_type;
    quantityData.package_size = packageSize;
    quantityData.package_qty = `${packageSize} ${packageType}`;
    return quantityData;
}

const checkMediCareBrandGeneric = (drug_details) => {
    let flag = false;
    let singleDrug = [];
    for (var singleDrugdeatil of drug_details) {
        for (var form of singleDrugdeatil.forms) {
            singleDrug = form.dosages.filter(
                (item, index) =>
                    !(
                        item?.medicare_type_codes?.includes('1') ||
                        item?.medicare_type_codes?.includes('2')
                    )
            );
            if (singleDrug.length > 0) {
                flag = true;
                break;
            }
        }
        if (flag) {
            return singleDrugdeatil;
        }
    }
    return singleDrug;
};

const checkMediCareDrugForm = (drugForm) => {
    let obj = {
        form: '',
        dosages: [],
    };
    for (var drug of drugForm.forms) {
        const singleDrug = drug.dosages.filter(
            (item, index) =>
                !(
                    item?.medicare_type_codes?.includes('1') ||
                    item?.medicare_type_codes?.includes('2')
                ) || item?.is_grandfathered_drugcoverage_drug === 'Y'
        );
        if (singleDrug.length > 0) {
            obj = {
                form: drug.form,
                dosages: drug.dosages,
            };
            break;
        }
    }
    return obj;
};


export function stripChars(v, type = 'integer') {
    if (!v || typeof v !== 'string') return;
    const stripValue = v.replace(/\D*/gi, '');
    return type == 'integer' ? stripValue * 1 : stripValue;
}