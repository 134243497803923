import React from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import * as userActions from '../../../redux/modules/user';
import * as viewsActions from '../../../redux/modules/views';
import * as prescriptionsActions from '../../../redux/modules/Prescription/prescriptions';
import Avatar from '../../Avatar';
import { getCoverageNextActive } from '../../../utilities/commonFunctions';
import { trackEvent } from '../../../utilities/mixPanel';
import { Icon, Progress } from 'semantic-ui-react';
import { appConfig } from '../../../config';
const homeSVG = require('../../../style/images/home.svg');
const homeHSVG = require('../../../style/images/home-h.svg');
const prescriptionSVG = require('../../../style/images/prescription.svg');
const prescriptionHSVG = require('../../../style/images/prescription-hover.svg');
const rewardsSVG = require('../../../style/images/reward-n.svg');
const rewardsHSVG = require('../../../style/images/reward-hover.svg');
const headerNextSVG = require('../../../style/images/header-next.svg');
const headerNextHSVG = require('../../../style/images/header-next-h.svg');
const closeSVG = require('../../../style/images/delete-contact.svg');
const downArrowSVG = require('../../../style/images/down-arrow.svg');
const familySVG = require('../../../style/images/family.svg');
const idCardSVG = require('../../../style/images/id-card.svg');
const idCardHSVG = require('../../../style/images/id-card-hover.svg');
const logoutSVG = require('../../../style/images/logout.svg');
const logoutHSVG = require('../../../style/images/logout-hover.svg');
const paperClip = require('../../../style/images/paperClip.svg');
const paperClipHSVG = require('../../../style/images/paperClip-H.svg');

class LeftNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rightLinks: [],
        };
    }

    async componentDidMount() {
        let { setUserNotifications } = this.props;
        this.setRightLinks();
        await setUserNotifications();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.displayRewards !== this.props.displayRewards) {
            this.setRightLinks();
        }
    }

    setRightLinks() {
        const { displayRewards } = this.props;
        let rightLinks = [
            {
                label: 'leftMenuLinks.home',
                link: '/home',
                image: homeSVG,
                himage: homeHSVG,
                clsName: ['userDefaultHome'],
                refresh: false,
            },
            {
                label: 'leftMenuLinks.insuranceCard',
                link: '/insurance-card',
                image: idCardSVG,
                himage: idCardHSVG,
                clsName: ['insuranceCard'],
                refresh: false,
            },
            {
                label: 'Notifications',
                link: '/account',
                image: prescriptionSVG,
                himage: prescriptionHSVG,
                clsName: ['prescription'],
                badges: '0',
                refresh: false,
            },
            {
                label: 'Claim Reimbursement',
                link: '/home',
                image: paperClip,
                himage: paperClipHSVG,
                clsName: ['userDefaultHome'],
                refresh: false,
            }
        ];
        if (displayRewards === 'Y') {
            rightLinks.push({
                label: 'userHome.rewardsTab',
                link: '/rewards',
                image: rewardsSVG,
                himage: rewardsHSVG,
                clsName: ['rewards'],
                refresh: false,
            });
        }
        rightLinks.push(
            {
                label: 'accountPopup.myAccountLink',
                link: '/account',
                clsName: ['userAccount', 'userPrivacyPolicy'],
                refresh: false,
            },
            {
                label: 'accountPopup.logOutLink',
                link: '/',
                image: logoutSVG,
                himage: logoutHSVG,
                refresh: false,
                clsName: ['logout'],
            }
        );
        this.setState({ rightLinks });
    }

    // MENU TOGGLE CLICK
    toggleMenu(currentPage) {
        let {
            cartPrescriptions,
            setIsFromCart,
            setIsFromRefill,
            setIsFromSearch,
            setIsFromSearchPharmacy,
            setIsShowAllUserRxHistory,
            userInfo,
        } = this.props;
        let { flipt_person_id } = userInfo.employee ? userInfo.employee : userInfo.dependent;

        setIsFromSearchPharmacy(false);
        setIsFromRefill(false, []);
        setIsFromCart(false, cartPrescriptions);
        setIsFromSearch(false);
        setIsShowAllUserRxHistory(false);
        let data = {
            event: 'Page',
            eventProps: {
                routeName: currentPage,
            },
            flipt_person_id,
        };
        trackEvent(data);
        if (document.getElementById('view-child')) {
            if (this.props.location.pathname != '/prescriptions') {
                document.getElementById('view-child').style.display = 'none';
            }
        }
        if (window.innerWidth < 768) {
            if (document.body.classList.contains('collapsed-menu')) {
                document.body.classList.remove('collapsed-menu');
                $('body').removeClass('collapsed-menu');
            } else {
                document.body.classList.add('collapsed-menu');
            }
        }
    }

    closeMenu() {
        if (document.body.classList.contains('collapsed-menu')) {
            document.body.classList.remove('collapsed-menu');
            $('body').removeClass('collapsed-menu');
        } else {
            document.body.classList.add('collapsed-menu');
        }
    }

    render() {
        let { rightLinks } = this.state;
        let { notifications } = this.props;
        if (document.getElementById('headerCartPopup')) {
            document.getElementById('headerCartPopup').style.display = 'none';
        }
        if (document.getElementById('account-popup-data')) {
            document.getElementById('account-popup-data').style.display = 'none';
        }
        if (document.getElementById('wheretoclick')) {
            document.getElementById('wheretoclick').click();
        }
        let badges = notifications.length;
        const {
            activClass,
            setConfigurationWorkflow,
            setCurrentAccountTab,
            setGoToCart,
            setGoToConfigurationFlow,
            setIsFromRefill,
            setIsFromSearch,
            signOut,
            t,
        } = this.props;
        let userInfo = this.props.userInfo;
        let accountDetails =
            userInfo && userInfo.employee
                ? userInfo.employee
                : userInfo && userInfo.dependent && userInfo.dependent;
        accountDetails.dependents = accountDetails.dependents.filter((d) =>
            getCoverageNextActive(d.eligibility)
        );
        let userName = userInfo.employee
            ? userInfo.employee.first_name + ' ' + userInfo.employee.last_name
            : userInfo.dependent.first_name + ' ' + userInfo.dependent.last_name;

        let firstName = userInfo.employee
            ? userInfo.employee.first_name
            : userInfo.dependent.first_name;
        let lastName = userInfo.employee
            ? userInfo.employee.last_name
            : userInfo.dependent.last_name;
        let family_deductible_remaining = userInfo.employee
            ? userInfo.employee.family_deductible_remaining
            : userInfo.dependent.family_deductible_remaining;
        let annual_family_deductible = userInfo.employee
            ? userInfo.employee.rxPlanMaster.annual_deductible_family
            : userInfo.dependent.rxPlanMaster.annual_deductible_family;
        let deductiblePercent =
            annual_family_deductible == 0
                ? 0
                : (Math.round(annual_family_deductible - family_deductible_remaining) * 100) /
                  Math.round(annual_family_deductible);
        let coverage_tier_name = userInfo.employee
            ? userInfo.employee.coverage_tier_name
            : userInfo.dependent.coverage_tier_name;
        deductiblePercent = Math.round(deductiblePercent);

        return (
            <div className="left-bar">
                <div className="left-inner-bar">
                    <div className="menu-mobile-header">
                        <img
                            src={closeSVG}
                            alt="close"
                            className="ui fluid image normal mCS_img_loaded hamburger_close"
                            onClick={() => {
                                this.closeMenu();
                            }}
                        />
                        <div className="user-img-div">
                            <Avatar user={userInfo.employee || userInfo.dependent} />
                            &nbsp;
                        </div>
                        <div className="user-info-div">
                            <h2>
                                {t('Hi')} {firstName ? firstName.charAt(0) : ''}
                                {lastName ? lastName.charAt(0) : ''},
                            </h2>
                            <span>{t('Welcome back')}</span>
                            <a title="">
                                <img
                                    src={headerNextSVG}
                                    alt="next"
                                    className="ui fluid image normal mCS_img_loaded"
                                />
                                <img
                                    src={headerNextHSVG}
                                    alt="next"
                                    className="ui fluid image hover mCS_img_loaded"
                                />
                            </a>
                        </div>
                    </div>
                    <ul>
                        {rightLinks.map((aLinkBtn, index) => {
                            return (
                                <li key={index} className={activClass(aLinkBtn.clsName,aLinkBtn.label)}>
                                    <Link
                                        to={{
                                            pathname: aLinkBtn.link,
                                            state: aLinkBtn.refresh,
                                        }}
                                        title={t(aLinkBtn.label)}
                                        className={
                                            aLinkBtn.childs
                                                ? 'menu-item sub-menu-link'
                                                : 'menu-item'
                                        }
                                        onClick={
                                            aLinkBtn.label==='Claim Reimbursement'? ()=>{
                                                window.open('https://fliptrx.com/members/claimforms', '_blank')
                                            }:aLinkBtn.link == '/'
                                                ? signOut
                                                : () => {
                                                      setIsFromSearch(
                                                          t(aLinkBtn.label).toLowerCase() ==
                                                              'search'
                                                              ? true
                                                              : false
                                                      );
                                                      setIsFromRefill(false, []);
                                                      setGoToCart(false);
                                                      setGoToConfigurationFlow(false);
                                                      setConfigurationWorkflow('traditional');
                                                      this.toggleMenu(t(aLinkBtn.label));
                                                      if (aLinkBtn.badges)
                                                          setCurrentAccountTab({
                                                              index: 4,
                                                              name: 'Messages',
                                                          });
                                                      //Added below if condition reload code for only search route to switch from claims page to initial screen.
                                                      if (
                                                          window.location.hash.indexOf('home') >
                                                              0 &&
                                                          aLinkBtn.link == '/home'
                                                      ) {
                                                          history.go(0);
                                                      }
                                                  }
                                        }
                                    >
                                        {aLinkBtn.badges ? (
                                            <i>
                                                <Icon name="bell outline" />
                                            </i>
                                        ) : aLinkBtn.image ? (
                                            <i>
                                                <img src={aLinkBtn.image} alt={t(aLinkBtn.label)} />
                                                <img
                                                    src={aLinkBtn.himage}
                                                    alt={t(aLinkBtn.label)}
                                                    className="rollhover"
                                                />
                                            </i>
                                        ) : (
                                            <i>
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                                <span className="icon-bar"></span>
                                            </i>
                                        )}
                                        &nbsp;
                                        <span>
                                            {t(aLinkBtn.label)}
                                            {aLinkBtn.childs && (
                                                <em>
                                                    <img src={downArrowSVG} />
                                                </em>
                                            )}
                                        </span>
                                        {aLinkBtn.badges && <em className="badge">{badges}</em>}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                {/* SIDEBAR LEFT BOTTOM SECTION */}
                <div className="left-bottom-block">
                    {/* FAMILY DEDUCTIBLE SECTION */}
                    {coverage_tier_name && coverage_tier_name.toLowerCase() != 'individual' && (
                        <div className="family-details">
                            <span className="family-icon">
                                <i>
                                    <img src={familySVG} alt="family-deductible" />
                                </i>
                                {t('My Family')}
                            </span>
                            <em>
                                {deductiblePercent}% {t('of Family deductible met')}
                            </em>
                            <span className="progress-bar">
                                <Progress
                                    percent={deductiblePercent}
                                    size="small"
                                    color="blue"
                                ></Progress>
                            </span>
                        </div>
                    )}
                    {/* FAMILY LIST SECTION */}
                    <div className="bottom-content-block">
                        <div className="name-block-wrapper">
                            <Avatar user={userInfo.employee || userInfo.dependent} />
                            <div className="name-block">
                                <h5>{userName}</h5>
                            </div>
                        </div>
                        {accountDetails.dependents.map((dependent, key) => {
                            return (
                                <div className="name-block-wrapper" key={key}>
                                    <Avatar user={dependent} />
                                    <div className="name-block">
                                        <h5>
                                            {dependent.first_name} {dependent.last_name}
                                        </h5>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {/* BUILD VERSION SECTION */}
                    <div
                        style={{
                            fontSize: 10,
                            textAlign: 'center',
                            padding: '10px 20px 0px 0px',
                        }}
                    >
                        <span>
                            {t('navigation.buildVersion')}: {appConfig.appVersion}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        basket: state.user.basket,
        cartPrescriptions: state.prescriptions.cartPrescriptions,
        currentView: state.views.currentView,
        displayRewards: state.userAuth.userInfo.employee
            ? state.userAuth.userInfo.employee.domain.display_rewards
            : state.userAuth.userInfo.dependent.domain.display_rewards,
        isFromCart: state.prescriptions.isFromCart,
        isImpersonateUser: state.user.isImpersonateUser,
        notifications: state.user.notifications,
        pendingPrescriptions: state.prescriptions.pendingPrescriptions,
        userInfo: state.userAuth.userInfo,
        userMessages: state.user.userMessages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            ...viewsActions,
            ...prescriptionsActions,
            ...userActions,
        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translations')(LeftNav));
